import { Component, OnInit } from '@angular/core';
import { ReadFactoriesQuery as Factory } from '../../API.service';
import { LoadingService } from 'src/app/services/loading.service';
import { API } from 'aws-amplify';

@Component({
  selector: 'app-factories-conditions',
  templateUrl: './factories-conditions.component.html',
  styleUrls: ['./factories-conditions.component.less']
})
export class FactoriesConditionsComponent implements OnInit {

  factories: Factory[];

  constructor(private loadingService: LoadingService) { }

  async ngOnInit() {
    this.loadingService.startLoading();
    this.factories = await API.get('restApi', '/factories-conditions', {
      headers: { 'Content-Type': 'application/json' }
    });
    this.loadingService.stopLoading();
  }

}