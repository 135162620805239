import { Injectable } from '@angular/core';
import { APIService, ReadDevicesQuery as Device, ReadDeviceSaleTypesQuery as DeviceSaleType, OnUpdateDeviceManagerSubscription } from '../API.service';
import API, { graphqlOperation } from '@aws-amplify/api';
import { Observable } from 'rxjs';

export type DeviceWithErrors = Device & {
  errors?: {
    error_inhibition: boolean,
    coin_box_error_box: boolean,
    error_exact_change: boolean,
    coin_box_error_supply: boolean,
    coin_box_generic_error: boolean,
    coin_box_error_acceptor: boolean,
    coin_box_error_separator: boolean,
    error_machine_communication: boolean,
    coin_box_error_discriminator: boolean
  };
}

export function addErrorOnDevices(devices: Array<DeviceWithErrors>) {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i].last_polling_details != null && devices[i].last_polling_details.errors_status != null) {
      devices[i].errors = JSON.parse(devices[i].last_polling_details.errors_status);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  device_sale_types: DeviceSaleType[];
  devices: DeviceWithErrors[];

  constructor(private apiService: APIService) {
  }

  async readDevices() {
    if (this.device_sale_types == null || this.device_sale_types.length === 0) {
      this.device_sale_types = await this.apiService.ReadDeviceSaleTypes();
    }
    this.devices = await this.apiService.ReadDevices();
    /*for(let i=0; i<this.devices.length; i++){
      if(this.devices[i].last_polling_details != null && this.devices[i].last_polling_details.errors_status != null){
        this.devices[i].errors = JSON.parse(this.devices[i].last_polling_details.errors_status);
      }
    }*/
    addErrorOnDevices(this.devices);
  }

  async saveDevice(device: Device | DeviceWithErrors): Promise<Device> {
    delete device.__typename;
    delete device.qrcode.__typename;
    if (device.last_polling_details != null) {
      delete device.last_polling_details.__typename;
    }
    delete (device as DeviceWithErrors).errors;
    for (const factory of device.factories) {
      delete factory.__typename;
      delete factory.fields;
      delete factory.url_logo;
      delete factory.subfactories;
    }
    return await this.apiService.UpdateDevice(device);
  }

  getDevicesaleType(device: Device) {
    if (this.device_sale_types != null) {
      return this.device_sale_types.find(device_sale_type => {
        return device_sale_type.id_device_sale_type === device.id_device_sale_type;
      });
    } else {
      return null;
    }
  }

  OnUpdateDeviceManagerListener(id_manager: number): Observable<OnUpdateDeviceManagerSubscription> {
    return API.graphql(
      graphqlOperation(
        `subscription OnUpdateDeviceManager($id_manager: Int) {
          onUpdateDeviceManager(id_manager: $id_manager) {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              subfactories {
                __typename
                id_factory
                name
                url_logo
                url_logo_brand
                is_activated
                is_demo
                id_flow
                fields
                is_editable_after_activation
                url_website
                id_type
              }
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
            last_polling_details {
              __typename
              id_device
              response_date
              rssi
              telephone_operator
              response_delay
              fw_version
              errors_status
              connection_type
            }
            last_restart
            last_disconnection
          }
        }`, {
        id_manager
      }
      )
    ) as any as Observable<OnUpdateDeviceManagerSubscription>;
  }

}
