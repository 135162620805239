import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { TestDialogComponent } from '../test-dialog/test-dialog.component';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  displayLogin: boolean = true;

  emailAddress = new FormControl('', [Validators.required, Validators.email]);
  passwordSignup = new FormControl('', [Validators.required, Validators.minLength(8)]);
  confirmationCode: string;

  loginError = false;
  loadingLogin = false;
  signupLoading = false;
  confirmLoading = false;
  forgotPasswordLoading = false;

  forgotPasswordMode = false;

  privacyAcceptance = false;
  conditionsAcceptance = false;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    public router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService) { }

  async ngOnInit() {
    this.loadingService.startLoading();
    await this.auth.inizializzato;
    this.loadingService.stopLoading();
    if (this.auth.isLoggedIn) {
      if (this.auth.hasGroup('amministratori')) {
        this.router.navigate(['admin_devices']);
      } else if (this.auth.hasGroup('gestori')) {
        this.router.navigate(['devices']);
      } else if (this.auth.hasGroup('utenti')) {
        this.router.navigate(['manager_activation']);
      }
    }
  }

  async login($event: MouseEvent) {
    this.loginError = false;
    this.loadingLogin = true;
    ($event.target as HTMLButtonElement).disabled = true;
    try {
      await this.auth.login(this.username, this.password);
    } catch (e) {
      this.password = null;
      this.loginError = true;
    }
    this.loadingLogin = false;
    if (this.auth.hasGroup('amministratori')) {
      this.router.navigate(['admin_devices']);
    } else if (this.auth.hasGroup('gestori')) {
      this.router.navigate(['devices']);
    } else if (this.auth.hasGroup('utenti')) {
      this.router.navigate(['manager_activation']);
    }
    ($event.target as HTMLButtonElement).disabled = false;
  }

  openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      username: this.username,
      password: this.password
    };
    const dialogRef = this.dialog.open(TestDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  loginGoogle() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }

  
  loginFacebook() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
  }

  loginApple(){
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
  }

  openRegistration() {
    if(this.privacyAcceptance && this.conditionsAcceptance){
      this.displayLogin = false;
    }
  }

  openSignin() {
    this.displayLogin = true;
  }

  async startSignUp() {
    this.signupLoading = true;
    try {
      await Auth.signUp({
        username: this.emailAddress.value,
        password: this.passwordSignup.value,
        attributes: {
          email: this.emailAddress.value
        }
      });
      this.snackBar.open(this.translate.instant('control_email'), null, { duration: 6000, panelClass: 'success-panel' });
    } catch (e) {
      console.error(e);
      this.snackBar.open(this.translate.instant('signup_error'), null, { duration: 6000, panelClass: 'warning-panel' });
    }
    this.signupLoading = false;
  }

  async forgotPassword() {
    this.forgotPasswordLoading = true;
    this.emailAddress.setValue(this.username);
    this.forgotPasswordMode = true;
    await Auth.forgotPassword(this.username);
    this.forgotPasswordLoading = false;
    this.openRegistration();
    this.snackBar.open(this.translate.instant('CONTROL_EMAIL_FORGOT'), null, { duration: 6000, panelClass: 'success-panel' });
  }

  async confirmSignUp() {
    this.confirmLoading = true;
    if (!this.forgotPasswordMode) {
      try {
        await Auth.confirmSignUp(this.emailAddress.value, this.confirmationCode.trim());
        this.snackBar.open(this.translate.instant('signup_success'), null, { duration: 6000, panelClass: 'success-panel' });
      } catch (error) {
        console.error(error);
        this.snackBar.open(this.translate.instant('signup_error'), null, { duration: 6000, panelClass: 'warning-panel' });
      }
    } else {
      try {
        await Auth.forgotPasswordSubmit(this.emailAddress.value, this.confirmationCode.trim(), this.passwordSignup.value);
        this.snackBar.open(this.translate.instant('signup_success'), null, { duration: 6000, panelClass: 'success-panel' });
        this.openSignin();
        this.forgotPasswordMode = false;
      } catch (e) {
        console.error(e);
      }
    }
    this.confirmLoading = false;
  }

  getEmailAddressErrorMessage() {
    return this.emailAddress.hasError('required') ? "EMAIL_ADDRESS_REQUIRED" : 'NOT_VALID_ADDRESS';
  }

}
