import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { APIService, ListManagersQuery } from 'src/app/API.service';

@Component({
  selector: 'app-new-logo-modal',
  templateUrl: './new-logo-modal.component.html',
  styleUrls: ['./new-logo-modal.component.less']
})
export class NewLogoModalComponent implements OnInit {

  manager: ListManagersQuery;
  loadingConfirmation = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {manager: ListManagersQuery},
    public dialogRef: MatDialogRef<NewLogoModalComponent>,
    private apiService: APIService
  ) {
    this.manager = data.manager;
  }

  ngOnInit() {
  }

  async sendConfirmation(confirm: boolean){
    this.loadingConfirmation = true;
    await this.apiService.ConfirmManagerLogo(this.manager.id_manager, confirm);
    this.loadingConfirmation = false;
    this.dialogRef.close();
  }

}
