export class PageDescription {
    path: string;
    component: any;
    name: string;
    visibleAfterLogin: boolean;
    expectedRole?: string;

    constructor(path: string, component: any, name: string, visibleAfterLogin: boolean, expectedRole: string) {
        this.path = path;
        this.component = component;
        this.name = name;
        this.visibleAfterLogin = visibleAfterLogin;
        this.expectedRole = expectedRole;
    }

    getRouterLink() {
        return '/' + this.path;
    }

    getPageName() {
        return this.name;
    }

    public getExpectedRole() {
        return this.expectedRole;
    }
}

