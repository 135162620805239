import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { APIService, ReadDevicesQuery as Device, ReadSimStatusQuery as SIMInfo } from '../../API.service';

@Component({
  selector: 'app-sim-dialog',
  templateUrl: './sim-dialog.component.html',
  styleUrls: ['./sim-dialog.component.less']
})
export class SimDialogComponent implements OnInit {

  device: Device;
  simInfo: SIMInfo;
  loadingRatePlan = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { device: Device, SIMInfo: SIMInfo },
    private dialog: MatDialogRef<SimDialogComponent>,
    private apiService: APIService
  ) {
    this.device = this.data.device;
    this.simInfo = this.data.SIMInfo;
  }

  async upgrade() {
    this.loadingRatePlan = true;
    const res = await this.apiService.UpgradeSimRatePlan(this.device.id_device);
    this.simInfo.ratePlan = res.ratePlan;
    this.loadingRatePlan = false;
  }

  async downgrade() {
    this.loadingRatePlan = true;
    const res = await this.apiService.DowngradeSimRatePlan(this.device.id_device);
    this.simInfo.ratePlan = res.ratePlan;
    this.loadingRatePlan = false;
  }

  getConsumo() {
    if (this.simInfo.ctdDataUsage < 1024) {
      return this.simInfo.ctdDataUsage + ' byte';
    } else if (this.simInfo.ctdDataUsage >= 1024 && this.simInfo.ctdDataUsage < 1024 * 1024) {
      return (this.simInfo.ctdDataUsage / 1024).toFixed(2) + ' KB';
    }
    return (this.simInfo.ctdDataUsage / 1024 / 1024).toFixed(2) + ' MB';
  }

  ngOnInit() {
  }

}
