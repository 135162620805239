import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { API } from 'aws-amplify';

export interface FirmwareDeviceInterface{
  id_device: number;
  serial_number: string;
  actual_version: string;
  version:string;
  id_firmware:number;
  start_validity: string;
  completed: boolean;
}

@Component({
  selector: 'app-get-firmware-update-dialog',
  templateUrl: './get-firmware-update-dialog.component.html',
  styleUrls: ['./get-firmware-update-dialog.component.less']
})
export class GetFirmwareUpdateDialogComponent implements OnInit {

  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnsToDisplay = ['serial_number', 'actual_version', 'version', 'start_validity', 'completed'];

  loadingDate: boolean = false;
  loadingCompleted: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.dataSource = new MatTableDataSource<FirmwareDeviceInterface>(this.data);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async changeDate(dev: FirmwareDeviceInterface){
    (dev as any).loadingDate = true;
    const res = await API.del('restApi', '/upload-firmware', {
      queryStringParameters: {
        id_firmware: dev.id_firmware, 
        id_device: dev.id_device,
        completed: dev.completed,
        start_validity: dev.start_validity
      },
      headers: { 'Content-Type': 'text/plain' }
    });
    dev.completed = res[0].completed;
    dev.start_validity = res[0].start_validity;
    (dev as any).loadingDate = false;
  }

  async changeCompleted(dev: FirmwareDeviceInterface){
    (dev as any).loadingCompleted = true;
    const res = await API.del('restApi', '/upload-firmware', {
      queryStringParameters: {
        id_firmware: dev.id_firmware, 
        id_device: dev.id_device,
        completed: dev.completed
      },
      headers: { 'Content-Type': 'text/plain' }
    });
    dev.completed = res[0].completed;
    dev.start_validity = res[0].start_validity;
    (dev as any).loadingCompleted = false;
  }

}
