/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type FactoryInput = {
  id_factory: number;
  name?: string | null;
  url_logo?: string | null;
  url_logo_brand?: string | null;
  is_activated?: boolean | null;
  is_demo?: boolean | null;
  id_flow?: number | null;
  fields?: string | null;
  is_editable_after_activation?: boolean | null;
  url_website?: string | null;
  id_type?: number | null;
  is_used?: boolean | null;
  subfactories?: Array<FactoryInput | null> | null;
  is_visible?: boolean | null;
  url_privacy_policy?: string | null;
};

export type Factory = {
  __typename: "Factory";
  id_factory?: number;
  name?: string;
  url_logo?: string | null;
  url_logo_brand?: string | null;
  is_activated?: boolean | null;
  is_demo?: boolean | null;
  id_flow?: number | null;
  fields?: string | null;
  is_editable_after_activation?: boolean | null;
  url_website?: string | null;
  id_type?: number | null;
  is_used?: boolean | null;
  subfactories?: Array<Factory | null> | null;
  is_visible?: boolean | null;
  url_privacy_policy?: string | null;
};

export type DeviceInput = {
  id_device: number;
  id_manager?: number | null;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: QrcodeInput | null;
  factories?: Array<FactoryInput | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: PollingInput | null;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type QrcodeInput = {
  id_qrcode: number;
  identifier: string;
  qrcode?: string | null;
  id_version?: number | null;
};

export type PollingInput = {
  id_device: number;
  response_date?: string | null;
  rssi?: number | null;
  telephone_operator?: string | null;
  response_delay?: number | null;
  fw_version?: string | null;
  errors_status?: string | null;
  connection_type?: string | null;
};

export type Device = {
  __typename: "Device";
  id_device?: number;
  id_manager?: number;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: Qrcode;
  factories?: Array<Factory | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: Polling;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type Qrcode = {
  __typename: "Qrcode";
  id_qrcode?: number;
  identifier?: string;
  qrcode?: string | null;
  id_version?: number | null;
};

export type Polling = {
  __typename: "Polling";
  id_device?: number;
  response_date?: string | null;
  rssi?: number | null;
  telephone_operator?: string | null;
  response_delay?: number | null;
  fw_version?: string | null;
  errors_status?: string | null;
  connection_type?: string | null;
};

export type ManagerPaymentInput = {
  unique_payment_id: string;
  factory: FactoryInput;
  device?: DeviceInput | null;
  id_manager: number;
  user_id?: string | null;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type ManagerPayment = {
  __typename: "ManagerPayment";
  unique_payment_id?: string;
  factory?: Factory;
  device?: Device;
  id_manager?: number;
  user_id?: string;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type SimInfo = {
  __typename: "SimInfo";
  id_device?: number;
  iccid?: string | null;
  imei?: string | null;
  status?: string | null;
  ratePlan?: string | null;
  communicationPlan?: string | null;
  ctdDataUsage?: number | null;
  ctdSessionCount?: number | null;
};

export type SniffingInput = {
  id_device?: number | null;
  id_manager?: number | null;
  id_sniffing_session: number;
  body: string;
  arrival_date?: string | null;
};

export type Sniffing = {
  __typename: "Sniffing";
  id_device?: number;
  id_manager?: number | null;
  id_sniffing_session?: number;
  body?: string | null;
  arrival_date?: string | null;
};

export type MachineErrorInput = {
  id_device?: number | null;
  evadts_error?: string | null;
};

export type MachineError = {
  __typename: "MachineError";
  id_device?: number | null;
  evadts_error?: string | null;
};

export type LogInput = {
  group?: string | null;
  type?: string | null;
  event?: string | null;
  request_id?: string | null;
  timestamp?: string | null;
};

export type Log = {
  __typename: "Log";
  group?: string | null;
  type?: string | null;
  event?: string | null;
  request_id?: string | null;
  timestamp?: string | null;
};

export type Manager = {
  __typename: "Manager";
  id_manager?: number;
  business_name?: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  is_logo_enabled?: boolean | null;
  currency?: Currency;
  email_contact?: string | null;
};

export type Currency = {
  __typename: "Currency";
  iso_code?: string;
  decimal?: number;
  symbol?: string;
};

export type AdminManagerInput = {
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  currency?: CurrencyInput | null;
  sap_code?: string | null;
  number_of_machine?: number | null;
  vat_number?: string | null;
  sale_ok?: number | null;
  sale_failed?: number | null;
  email_contact?: string | null;
  is_logo_enabled?: boolean | null;
  factories?: Array<FactoryInput | null> | null;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  is_demo?: boolean | null;
};

export type CurrencyInput = {
  iso_code: string;
  decimal: number;
  symbol: string;
};

export type AdminManager = {
  __typename: "AdminManager";
  id_manager?: number;
  business_name?: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  currency?: Currency;
  sap_code?: string | null;
  number_of_machine?: number | null;
  vat_number?: string | null;
  sale_ok?: number | null;
  sale_failed?: number | null;
  email_contact?: string | null;
  is_logo_enabled?: boolean | null;
  factories?: Array<Factory | null> | null;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  is_demo?: boolean | null;
};

export type ChangeEnvironmentInput = {
  id_device: number;
  env_id: string;
};

export type SmartphoneMessageInput = {
  cognito_identity_id: string;
  id_smartphone: number;
  functionName: string;
  payload?: string | null;
};

export type SmartphoneMessage = {
  __typename: "SmartphoneMessage";
  cognito_identity_id?: string;
  id_smartphone?: number;
  functionName?: string;
  payload?: string | null;
};

export type DeviceSaleType = {
  __typename: "DeviceSaleType";
  id_device_sale_type?: number;
  description?: string;
  url_image?: string;
};

export type ManagerPaymentFilterInput = {
  start_date?: string | null;
  end_date?: string | null;
};

export type ManagerPaymentStatus = {
  __typename: "ManagerPaymentStatus";
  id_status?: number;
  css?: string;
  localized_descriptions?: string;
};

export type PaymentSelectionDescription = {
  __typename: "PaymentSelectionDescription";
  selection?: number;
  localized_description?: string;
};

export type DevicePollingFilterInput = {
  id_device: number;
  start_date?: string | null;
  end_date?: string | null;
};

export type PaymentTimes = {
  __typename: "PaymentTimes";
  id_device?: number | null;
  id_factory?: number | null;
  factory_name?: string | null;
  factory_id_flow?: number | null;
  creation_date?: string | null;
  ms_for_creation?: number | null;
  ms_for_direct_payment?: number | null;
  s_for_verify?: number | null;
  payment_total_time?: number | null;
};

export type SniffingFile = {
  __typename: "SniffingFile";
  id_device?: number;
  creation_date?: string | null;
  url?: string;
  end_date?: string | null;
};

export type ActiveError = {
  __typename: "ActiveError";
  id_device?: number;
  error?: string;
  last_start_date?: string | null;
};

export type QueryLogResult = {
  __typename: "QueryLogResult";
  logs?: Array<Log | null> | null;
  status?: string | null;
};

export type QuerySummaryResult = {
  __typename: "QuerySummaryResult";
  sale_value?: number;
  start_date?: string | null;
  end_date?: string | null;
};

export type PresignedUrl = {
  __typename: "PresignedUrl";
  url?: string;
  fields?: string;
  key?: string;
};

export type LicenseInfo = {
  __typename: "LicenseInfo";
  id_manager?: number;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  notify_expiration?: boolean | null;
};

export type Environment = {
  __typename: "Environment";
  env_id?: string;
};

export type UpdateFactoryMutation = {
  __typename: "Factory";
  id_factory: number;
  name: string;
  url_logo?: string | null;
  url_logo_brand?: string | null;
  is_activated?: boolean | null;
  is_demo?: boolean | null;
  id_flow?: number | null;
  fields?: string | null;
  is_editable_after_activation?: boolean | null;
  url_website?: string | null;
  id_type?: number | null;
  is_used?: boolean | null;
  subfactories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_visible?: boolean | null;
  url_privacy_policy?: string | null;
};

export type UpdateDeviceMutation = {
  __typename: "Device";
  id_device: number;
  id_manager: number;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: {
    __typename: "Qrcode";
    id_qrcode: number;
    identifier: string;
    qrcode?: string | null;
    id_version?: number | null;
  } | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: {
    __typename: "Polling";
    id_device: number;
    response_date?: string | null;
    rssi?: number | null;
    telephone_operator?: string | null;
    response_delay?: number | null;
    fw_version?: string | null;
    errors_status?: string | null;
    connection_type?: string | null;
  } | null;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type UpdateManagerPaymentMutation = {
  __typename: "ManagerPayment";
  unique_payment_id: string;
  factory: {
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  };
  device?: {
    __typename: "Device";
    id_device: number;
    id_manager: number;
    serial_number?: string | null;
    id_type?: number | null;
    id_device_sale_type?: number | null;
    alias?: string | null;
    address?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    timezone?: string | null;
    qrcode?: {
      __typename: "Qrcode";
      id_qrcode: number;
      identifier: string;
      qrcode?: string | null;
      id_version?: number | null;
    } | null;
    factories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_remote_selection_enabled?: boolean | null;
    is_device_remote_selection?: boolean | null;
    last_polling?: string | null;
    id_status?: number | null;
    now_server?: string | null;
    machine_model?: string | null;
    last_polling_details?: {
      __typename: "Polling";
      id_device: number;
      response_date?: string | null;
      rssi?: number | null;
      telephone_operator?: string | null;
      response_delay?: number | null;
      fw_version?: string | null;
      errors_status?: string | null;
      connection_type?: string | null;
    } | null;
    last_restart?: string | null;
    last_disconnection?: string | null;
    env_id?: string | null;
  } | null;
  id_manager: number;
  user_id: string;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type UpgradeSimRatePlanMutation = {
  __typename: "SimInfo";
  id_device: number;
  iccid?: string | null;
  imei?: string | null;
  status?: string | null;
  ratePlan?: string | null;
  communicationPlan?: string | null;
  ctdDataUsage?: number | null;
  ctdSessionCount?: number | null;
};

export type DowngradeSimRatePlanMutation = {
  __typename: "SimInfo";
  id_device: number;
  iccid?: string | null;
  imei?: string | null;
  status?: string | null;
  ratePlan?: string | null;
  communicationPlan?: string | null;
  ctdDataUsage?: number | null;
  ctdSessionCount?: number | null;
};

export type InsertPollingPieceMutation = {
  __typename: "Sniffing";
  id_device: number;
  id_manager?: number | null;
  id_sniffing_session: number;
  body?: string | null;
  arrival_date?: string | null;
};

export type SendMachineErrorEvadtsMutation = {
  __typename: "MachineError";
  id_device?: number | null;
  evadts_error?: string | null;
};

export type CreateLogMutation = {
  __typename: "Log";
  group?: string | null;
  type?: string | null;
  event?: string | null;
  request_id?: string | null;
  timestamp?: string | null;
};

export type UpdateManagerNameMutation = {
  __typename: "Manager";
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  is_logo_enabled?: boolean | null;
  currency?: {
    __typename: "Currency";
    iso_code: string;
    decimal: number;
    symbol: string;
  } | null;
  email_contact?: string | null;
};

export type ConfirmManagerLogoMutation = {
  __typename: "Manager";
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  is_logo_enabled?: boolean | null;
  currency?: {
    __typename: "Currency";
    iso_code: string;
    decimal: number;
    symbol: string;
  } | null;
  email_contact?: string | null;
};

export type UpdateManagerAdminMutation = {
  __typename: "AdminManager";
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  currency?: {
    __typename: "Currency";
    iso_code: string;
    decimal: number;
    symbol: string;
  } | null;
  sap_code?: string | null;
  number_of_machine?: number | null;
  vat_number?: string | null;
  sale_ok?: number | null;
  sale_failed?: number | null;
  email_contact?: string | null;
  is_logo_enabled?: boolean | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  is_demo?: boolean | null;
};

export type SendSmartphoneMessageMutation = {
  __typename: "SmartphoneMessage";
  cognito_identity_id: string;
  id_smartphone: number;
  functionName: string;
  payload?: string | null;
};

export type UpgradeManagerFromDemoToProdMutation = {
  __typename: "AdminManager";
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  currency?: {
    __typename: "Currency";
    iso_code: string;
    decimal: number;
    symbol: string;
  } | null;
  sap_code?: string | null;
  number_of_machine?: number | null;
  vat_number?: string | null;
  sale_ok?: number | null;
  sale_failed?: number | null;
  email_contact?: string | null;
  is_logo_enabled?: boolean | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  is_demo?: boolean | null;
};

export type ReadDeviceSaleTypesQuery = {
  __typename: "DeviceSaleType";
  id_device_sale_type: number;
  description: string;
  url_image: string;
};

export type ReadFactoriesQuery = {
  __typename: "Factory";
  id_factory: number;
  name: string;
  url_logo?: string | null;
  url_logo_brand?: string | null;
  is_activated?: boolean | null;
  is_demo?: boolean | null;
  id_flow?: number | null;
  fields?: string | null;
  is_editable_after_activation?: boolean | null;
  url_website?: string | null;
  id_type?: number | null;
  is_used?: boolean | null;
  subfactories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_visible?: boolean | null;
  url_privacy_policy?: string | null;
};

export type ReadDevicesQuery = {
  __typename: "Device";
  id_device: number;
  id_manager: number;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: {
    __typename: "Qrcode";
    id_qrcode: number;
    identifier: string;
    qrcode?: string | null;
    id_version?: number | null;
  } | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: {
    __typename: "Polling";
    id_device: number;
    response_date?: string | null;
    rssi?: number | null;
    telephone_operator?: string | null;
    response_delay?: number | null;
    fw_version?: string | null;
    errors_status?: string | null;
    connection_type?: string | null;
  } | null;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type ReadAdminDevicesQuery = {
  __typename: "Device";
  id_device: number;
  id_manager: number;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: {
    __typename: "Qrcode";
    id_qrcode: number;
    identifier: string;
    qrcode?: string | null;
    id_version?: number | null;
  } | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: {
    __typename: "Polling";
    id_device: number;
    response_date?: string | null;
    rssi?: number | null;
    telephone_operator?: string | null;
    response_delay?: number | null;
    fw_version?: string | null;
    errors_status?: string | null;
    connection_type?: string | null;
  } | null;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type ReadManagerQuery = {
  __typename: "Manager";
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  is_logo_enabled?: boolean | null;
  currency?: {
    __typename: "Currency";
    iso_code: string;
    decimal: number;
    symbol: string;
  } | null;
  email_contact?: string | null;
};

export type ReadManagerPaymentsQuery = {
  __typename: "ManagerPayment";
  unique_payment_id: string;
  factory: {
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  };
  device?: {
    __typename: "Device";
    id_device: number;
    id_manager: number;
    serial_number?: string | null;
    id_type?: number | null;
    id_device_sale_type?: number | null;
    alias?: string | null;
    address?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    timezone?: string | null;
    qrcode?: {
      __typename: "Qrcode";
      id_qrcode: number;
      identifier: string;
      qrcode?: string | null;
      id_version?: number | null;
    } | null;
    factories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_remote_selection_enabled?: boolean | null;
    is_device_remote_selection?: boolean | null;
    last_polling?: string | null;
    id_status?: number | null;
    now_server?: string | null;
    machine_model?: string | null;
    last_polling_details?: {
      __typename: "Polling";
      id_device: number;
      response_date?: string | null;
      rssi?: number | null;
      telephone_operator?: string | null;
      response_delay?: number | null;
      fw_version?: string | null;
      errors_status?: string | null;
      connection_type?: string | null;
    } | null;
    last_restart?: string | null;
    last_disconnection?: string | null;
    env_id?: string | null;
  } | null;
  id_manager: number;
  user_id: string;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type ReadManagerPaymentStatusQuery = {
  __typename: "ManagerPaymentStatus";
  id_status: number;
  css: string;
  localized_descriptions: string;
};

export type ReadPaymentSelectionsDescriptionsQuery = {
  __typename: "PaymentSelectionDescription";
  selection: number;
  localized_description: string;
};

export type ReadSimStatusQuery = {
  __typename: "SimInfo";
  id_device: number;
  iccid?: string | null;
  imei?: string | null;
  status?: string | null;
  ratePlan?: string | null;
  communicationPlan?: string | null;
  ctdDataUsage?: number | null;
  ctdSessionCount?: number | null;
};

export type ReadDevicePollingQuery = {
  __typename: "Polling";
  id_device: number;
  response_date?: string | null;
  rssi?: number | null;
  telephone_operator?: string | null;
  response_delay?: number | null;
  fw_version?: string | null;
  errors_status?: string | null;
  connection_type?: string | null;
};

export type ReadPaymentTimesQuery = {
  __typename: "PaymentTimes";
  id_device?: number | null;
  id_factory?: number | null;
  factory_name?: string | null;
  factory_id_flow?: number | null;
  creation_date?: string | null;
  ms_for_creation?: number | null;
  ms_for_direct_payment?: number | null;
  s_for_verify?: number | null;
  payment_total_time?: number | null;
};

export type ListManagersQuery = {
  __typename: "AdminManager";
  id_manager: number;
  business_name: string;
  url_logo?: string | null;
  is_enabled?: boolean | null;
  currency?: {
    __typename: "Currency";
    iso_code: string;
    decimal: number;
    symbol: string;
  } | null;
  sap_code?: string | null;
  number_of_machine?: number | null;
  vat_number?: string | null;
  sale_ok?: number | null;
  sale_failed?: number | null;
  email_contact?: string | null;
  is_logo_enabled?: boolean | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  is_demo?: boolean | null;
};

export type ReadSniffingFilesQuery = {
  __typename: "SniffingFile";
  id_device: number;
  creation_date?: string | null;
  url: string;
  end_date?: string | null;
};

export type ReadActiveErrorsQuery = {
  __typename: "ActiveError";
  id_device: number;
  error: string;
  last_start_date?: string | null;
};

export type GetQueryLogResultQuery = {
  __typename: "QueryLogResult";
  logs?: Array<{
    __typename: "Log";
    group?: string | null;
    type?: string | null;
    event?: string | null;
    request_id?: string | null;
    timestamp?: string | null;
  } | null> | null;
  status?: string | null;
};

export type GetManagerSaleSummaryQuery = {
  __typename: "QuerySummaryResult";
  sale_value: number;
  start_date?: string | null;
  end_date?: string | null;
};

export type GetPresignedUrlLogoQuery = {
  __typename: "PresignedUrl";
  url: string;
  fields: string;
  key: string;
};

export type GetPresignedUrlApplePayCsrQuery = {
  __typename: "PresignedUrl";
  url: string;
  fields: string;
  key: string;
};

export type GetLicenseInfoQuery = {
  __typename: "LicenseInfo";
  id_manager: number;
  service_expiration_date?: string | null;
  ko_payments_expiration_date?: string | null;
  notify_expiration?: boolean | null;
};

export type ReadAdminManagerPaymentsQuery = {
  __typename: "ManagerPayment";
  unique_payment_id: string;
  factory: {
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  };
  device?: {
    __typename: "Device";
    id_device: number;
    id_manager: number;
    serial_number?: string | null;
    id_type?: number | null;
    id_device_sale_type?: number | null;
    alias?: string | null;
    address?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    timezone?: string | null;
    qrcode?: {
      __typename: "Qrcode";
      id_qrcode: number;
      identifier: string;
      qrcode?: string | null;
      id_version?: number | null;
    } | null;
    factories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_remote_selection_enabled?: boolean | null;
    is_device_remote_selection?: boolean | null;
    last_polling?: string | null;
    id_status?: number | null;
    now_server?: string | null;
    machine_model?: string | null;
    last_polling_details?: {
      __typename: "Polling";
      id_device: number;
      response_date?: string | null;
      rssi?: number | null;
      telephone_operator?: string | null;
      response_delay?: number | null;
      fw_version?: string | null;
      errors_status?: string | null;
      connection_type?: string | null;
    } | null;
    last_restart?: string | null;
    last_disconnection?: string | null;
    env_id?: string | null;
  } | null;
  id_manager: number;
  user_id: string;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type ReadEnvironmentsQuery = {
  __typename: "Environment";
  env_id: string;
};

export type OnUpdateDeviceManagerSubscription = {
  __typename: "Device";
  id_device: number;
  id_manager: number;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: {
    __typename: "Qrcode";
    id_qrcode: number;
    identifier: string;
    qrcode?: string | null;
    id_version?: number | null;
  } | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: {
    __typename: "Polling";
    id_device: number;
    response_date?: string | null;
    rssi?: number | null;
    telephone_operator?: string | null;
    response_delay?: number | null;
    fw_version?: string | null;
    errors_status?: string | null;
    connection_type?: string | null;
  } | null;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type OnUpdateDeviceAdminSubscription = {
  __typename: "Device";
  id_device: number;
  id_manager: number;
  serial_number?: string | null;
  id_type?: number | null;
  id_device_sale_type?: number | null;
  alias?: string | null;
  address?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  timezone?: string | null;
  qrcode?: {
    __typename: "Qrcode";
    id_qrcode: number;
    identifier: string;
    qrcode?: string | null;
    id_version?: number | null;
  } | null;
  factories?: Array<{
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  } | null> | null;
  is_remote_selection_enabled?: boolean | null;
  is_device_remote_selection?: boolean | null;
  last_polling?: string | null;
  id_status?: number | null;
  now_server?: string | null;
  machine_model?: string | null;
  last_polling_details?: {
    __typename: "Polling";
    id_device: number;
    response_date?: string | null;
    rssi?: number | null;
    telephone_operator?: string | null;
    response_delay?: number | null;
    fw_version?: string | null;
    errors_status?: string | null;
    connection_type?: string | null;
  } | null;
  last_restart?: string | null;
  last_disconnection?: string | null;
  env_id?: string | null;
};

export type OnUpdateManagerPaymentSubscription = {
  __typename: "ManagerPayment";
  unique_payment_id: string;
  factory: {
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  };
  device?: {
    __typename: "Device";
    id_device: number;
    id_manager: number;
    serial_number?: string | null;
    id_type?: number | null;
    id_device_sale_type?: number | null;
    alias?: string | null;
    address?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    timezone?: string | null;
    qrcode?: {
      __typename: "Qrcode";
      id_qrcode: number;
      identifier: string;
      qrcode?: string | null;
      id_version?: number | null;
    } | null;
    factories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_remote_selection_enabled?: boolean | null;
    is_device_remote_selection?: boolean | null;
    last_polling?: string | null;
    id_status?: number | null;
    now_server?: string | null;
    machine_model?: string | null;
    last_polling_details?: {
      __typename: "Polling";
      id_device: number;
      response_date?: string | null;
      rssi?: number | null;
      telephone_operator?: string | null;
      response_delay?: number | null;
      fw_version?: string | null;
      errors_status?: string | null;
      connection_type?: string | null;
    } | null;
    last_restart?: string | null;
    last_disconnection?: string | null;
    env_id?: string | null;
  } | null;
  id_manager: number;
  user_id: string;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type OnInsertPollingPieceSubscription = {
  __typename: "Sniffing";
  id_device: number;
  id_manager?: number | null;
  id_sniffing_session: number;
  body?: string | null;
  arrival_date?: string | null;
};

export type OnCreateLogSubscription = {
  __typename: "Log";
  group?: string | null;
  type?: string | null;
  event?: string | null;
  request_id?: string | null;
  timestamp?: string | null;
};

export type OnUpdateUserPaymentSubscription = {
  __typename: "ManagerPayment";
  unique_payment_id: string;
  factory: {
    __typename: "Factory";
    id_factory: number;
    name: string;
    url_logo?: string | null;
    url_logo_brand?: string | null;
    is_activated?: boolean | null;
    is_demo?: boolean | null;
    id_flow?: number | null;
    fields?: string | null;
    is_editable_after_activation?: boolean | null;
    url_website?: string | null;
    id_type?: number | null;
    is_used?: boolean | null;
    subfactories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_visible?: boolean | null;
    url_privacy_policy?: string | null;
  };
  device?: {
    __typename: "Device";
    id_device: number;
    id_manager: number;
    serial_number?: string | null;
    id_type?: number | null;
    id_device_sale_type?: number | null;
    alias?: string | null;
    address?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    timezone?: string | null;
    qrcode?: {
      __typename: "Qrcode";
      id_qrcode: number;
      identifier: string;
      qrcode?: string | null;
      id_version?: number | null;
    } | null;
    factories?: Array<{
      __typename: "Factory";
      id_factory: number;
      name: string;
      url_logo?: string | null;
      url_logo_brand?: string | null;
      is_activated?: boolean | null;
      is_demo?: boolean | null;
      id_flow?: number | null;
      fields?: string | null;
      is_editable_after_activation?: boolean | null;
      url_website?: string | null;
      id_type?: number | null;
      is_used?: boolean | null;
      is_visible?: boolean | null;
      url_privacy_policy?: string | null;
    } | null> | null;
    is_remote_selection_enabled?: boolean | null;
    is_device_remote_selection?: boolean | null;
    last_polling?: string | null;
    id_status?: number | null;
    now_server?: string | null;
    machine_model?: string | null;
    last_polling_details?: {
      __typename: "Polling";
      id_device: number;
      response_date?: string | null;
      rssi?: number | null;
      telephone_operator?: string | null;
      response_delay?: number | null;
      fw_version?: string | null;
      errors_status?: string | null;
      connection_type?: string | null;
    } | null;
    last_restart?: string | null;
    last_disconnection?: string | null;
    env_id?: string | null;
  } | null;
  id_manager: number;
  user_id: string;
  payment_id?: string | null;
  date?: string | null;
  amount?: number | null;
  status?: number | null;
  user_view_id?: string | null;
  selection?: number | null;
};

export type OnNewSmartphoneMessageSubscription = {
  __typename: "SmartphoneMessage";
  cognito_identity_id: string;
  id_smartphone: number;
  functionName: string;
  payload?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async UpdateFactory(input: FactoryInput): Promise<UpdateFactoryMutation> {
    const statement = `mutation UpdateFactory($input: FactoryInput!) {
        updateFactory(input: $input) {
          __typename
          id_factory
          name
          url_logo
          url_logo_brand
          is_activated
          is_demo
          id_flow
          fields
          is_editable_after_activation
          url_website
          id_type
          is_used
          subfactories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_visible
          url_privacy_policy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFactoryMutation>response.data.updateFactory;
  }
  async UpdateDevice(input: DeviceInput): Promise<UpdateDeviceMutation> {
    const statement = `mutation UpdateDevice($input: DeviceInput!) {
        updateDevice(input: $input) {
          __typename
          id_device
          id_manager
          serial_number
          id_type
          id_device_sale_type
          alias
          address
          latitude
          longitude
          timezone
          qrcode {
            __typename
            id_qrcode
            identifier
            qrcode
            id_version
          }
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_remote_selection_enabled
          is_device_remote_selection
          last_polling
          id_status
          now_server
          machine_model
          last_polling_details {
            __typename
            id_device
            response_date
            rssi
            telephone_operator
            response_delay
            fw_version
            errors_status
            connection_type
          }
          last_restart
          last_disconnection
          env_id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDeviceMutation>response.data.updateDevice;
  }
  async ActivateFactoryForAllDevices(
    input?: FactoryInput
  ): Promise<boolean | null> {
    const statement = `mutation ActivateFactoryForAllDevices($input: FactoryInput) {
        activateFactoryForAllDevices(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.activateFactoryForAllDevices;
  }
  async UpdateManagerPayment(
    input?: ManagerPaymentInput
  ): Promise<UpdateManagerPaymentMutation> {
    const statement = `mutation UpdateManagerPayment($input: ManagerPaymentInput) {
        updateManagerPayment(input: $input) {
          __typename
          unique_payment_id
          factory {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          device {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
            last_polling_details {
              __typename
              id_device
              response_date
              rssi
              telephone_operator
              response_delay
              fw_version
              errors_status
              connection_type
            }
            last_restart
            last_disconnection
            env_id
          }
          id_manager
          user_id
          payment_id
          date
          amount
          status
          user_view_id
          selection
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateManagerPaymentMutation>response.data.updateManagerPayment;
  }
  async UpgradeSimRatePlan(
    id_device: number
  ): Promise<UpgradeSimRatePlanMutation> {
    const statement = `mutation UpgradeSimRatePlan($id_device: Int!) {
        upgradeSimRatePlan(id_device: $id_device) {
          __typename
          id_device
          iccid
          imei
          status
          ratePlan
          communicationPlan
          ctdDataUsage
          ctdSessionCount
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_device
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpgradeSimRatePlanMutation>response.data.upgradeSimRatePlan;
  }
  async DowngradeSimRatePlan(
    id_device: number
  ): Promise<DowngradeSimRatePlanMutation> {
    const statement = `mutation DowngradeSimRatePlan($id_device: Int!) {
        downgradeSimRatePlan(id_device: $id_device) {
          __typename
          id_device
          iccid
          imei
          status
          ratePlan
          communicationPlan
          ctdDataUsage
          ctdSessionCount
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_device
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DowngradeSimRatePlanMutation>response.data.downgradeSimRatePlan;
  }
  async InsertPollingPiece(
    input: SniffingInput
  ): Promise<InsertPollingPieceMutation> {
    const statement = `mutation InsertPollingPiece($input: SniffingInput!) {
        insertPollingPiece(input: $input) {
          __typename
          id_device
          id_manager
          id_sniffing_session
          body
          arrival_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InsertPollingPieceMutation>response.data.insertPollingPiece;
  }
  async ActivateManager(input: string): Promise<boolean | null> {
    const statement = `mutation ActivateManager($input: String!) {
        activateManager(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.activateManager;
  }
  async SendMachineErrorEvadts(
    input?: MachineErrorInput
  ): Promise<SendMachineErrorEvadtsMutation> {
    const statement = `mutation SendMachineErrorEvadts($input: MachineErrorInput) {
        sendMachineErrorEvadts(input: $input) {
          __typename
          id_device
          evadts_error
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SendMachineErrorEvadtsMutation>response.data.sendMachineErrorEvadts;
  }
  async CreateLog(input: LogInput): Promise<CreateLogMutation> {
    const statement = `mutation CreateLog($input: LogInput!) {
        createLog(input: $input) {
          __typename
          group
          type
          event
          request_id
          timestamp
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLogMutation>response.data.createLog;
  }
  async UpdateManagerName(
    new_name: string,
    email_contact?: string
  ): Promise<UpdateManagerNameMutation> {
    const statement = `mutation UpdateManagerName($new_name: String!, $email_contact: String) {
        updateManagerName(new_name: $new_name, email_contact: $email_contact) {
          __typename
          id_manager
          business_name
          url_logo
          is_enabled
          is_logo_enabled
          currency {
            __typename
            iso_code
            decimal
            symbol
          }
          email_contact
        }
      }`;
    const gqlAPIServiceArguments: any = {
      new_name
    };
    if (email_contact) {
      gqlAPIServiceArguments.email_contact = email_contact;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateManagerNameMutation>response.data.updateManagerName;
  }
  async ConfirmManagerLogo(
    id_manager: number,
    confirm: boolean
  ): Promise<ConfirmManagerLogoMutation> {
    const statement = `mutation ConfirmManagerLogo($id_manager: Int!, $confirm: Boolean!) {
        confirmManagerLogo(id_manager: $id_manager, confirm: $confirm) {
          __typename
          id_manager
          business_name
          url_logo
          is_enabled
          is_logo_enabled
          currency {
            __typename
            iso_code
            decimal
            symbol
          }
          email_contact
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_manager,
      confirm
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfirmManagerLogoMutation>response.data.confirmManagerLogo;
  }
  async UpdateManagerAdmin(
    manager?: AdminManagerInput
  ): Promise<UpdateManagerAdminMutation> {
    const statement = `mutation UpdateManagerAdmin($manager: AdminManagerInput) {
        updateManagerAdmin(manager: $manager) {
          __typename
          id_manager
          business_name
          url_logo
          is_enabled
          currency {
            __typename
            iso_code
            decimal
            symbol
          }
          sap_code
          number_of_machine
          vat_number
          sale_ok
          sale_failed
          email_contact
          is_logo_enabled
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          service_expiration_date
          ko_payments_expiration_date
          is_demo
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (manager) {
      gqlAPIServiceArguments.manager = manager;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateManagerAdminMutation>response.data.updateManagerAdmin;
  }
  async ChangeEnvironment(
    input: ChangeEnvironmentInput
  ): Promise<boolean | null> {
    const statement = `mutation ChangeEnvironment($input: ChangeEnvironmentInput!) {
        ChangeEnvironment(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.ChangeEnvironment;
  }
  async SendSmartphoneMessage(
    input: SmartphoneMessageInput
  ): Promise<SendSmartphoneMessageMutation> {
    const statement = `mutation SendSmartphoneMessage($input: SmartphoneMessageInput!) {
        sendSmartphoneMessage(input: $input) {
          __typename
          cognito_identity_id
          id_smartphone
          functionName
          payload
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SendSmartphoneMessageMutation>response.data.sendSmartphoneMessage;
  }
  async UpgradeManagerFromDemoToProd(
    manager: AdminManagerInput
  ): Promise<UpgradeManagerFromDemoToProdMutation> {
    const statement = `mutation UpgradeManagerFromDemoToProd($manager: AdminManagerInput!) {
        upgradeManagerFromDemoToProd(manager: $manager) {
          __typename
          id_manager
          business_name
          url_logo
          is_enabled
          currency {
            __typename
            iso_code
            decimal
            symbol
          }
          sap_code
          number_of_machine
          vat_number
          sale_ok
          sale_failed
          email_contact
          is_logo_enabled
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          service_expiration_date
          ko_payments_expiration_date
          is_demo
        }
      }`;
    const gqlAPIServiceArguments: any = {
      manager
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpgradeManagerFromDemoToProdMutation>(
      response.data.upgradeManagerFromDemoToProd
    );
  }
  async GenerateNewCensusTokensForManager(
    manager: AdminManagerInput,
    number_of_tokens: number
  ): Promise<Array<string>> {
    const statement = `mutation GenerateNewCensusTokensForManager($manager: AdminManagerInput!, $number_of_tokens: Int!) {
        generateNewCensusTokensForManager(manager: $manager, number_of_tokens: $number_of_tokens)
      }`;
    const gqlAPIServiceArguments: any = {
      manager,
      number_of_tokens
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<string>>(
      response.data.generateNewCensusTokensForManager
    );
  }
  async ReadDeviceSaleTypes(): Promise<Array<ReadDeviceSaleTypesQuery>> {
    const statement = `query ReadDeviceSaleTypes {
        readDeviceSaleTypes {
          __typename
          id_device_sale_type
          description
          url_image
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadDeviceSaleTypesQuery>>response.data.readDeviceSaleTypes;
  }
  async ReadFactories(): Promise<Array<ReadFactoriesQuery>> {
    const statement = `query ReadFactories {
        readFactories {
          __typename
          id_factory
          name
          url_logo
          url_logo_brand
          is_activated
          is_demo
          id_flow
          fields
          is_editable_after_activation
          url_website
          id_type
          is_used
          subfactories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_visible
          url_privacy_policy
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadFactoriesQuery>>response.data.readFactories;
  }
  async ReadDevices(): Promise<Array<ReadDevicesQuery>> {
    const statement = `query ReadDevices {
        readDevices {
          __typename
          id_device
          id_manager
          serial_number
          id_type
          id_device_sale_type
          alias
          address
          latitude
          longitude
          timezone
          qrcode {
            __typename
            id_qrcode
            identifier
            qrcode
            id_version
          }
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_remote_selection_enabled
          is_device_remote_selection
          last_polling
          id_status
          now_server
          machine_model
          last_polling_details {
            __typename
            id_device
            response_date
            rssi
            telephone_operator
            response_delay
            fw_version
            errors_status
            connection_type
          }
          last_restart
          last_disconnection
          env_id
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadDevicesQuery>>response.data.readDevices;
  }
  async ReadAdminDevices(): Promise<Array<ReadAdminDevicesQuery>> {
    const statement = `query ReadAdminDevices {
        readAdminDevices {
          __typename
          id_device
          id_manager
          serial_number
          id_type
          id_device_sale_type
          alias
          address
          latitude
          longitude
          timezone
          qrcode {
            __typename
            id_qrcode
            identifier
            qrcode
            id_version
          }
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_remote_selection_enabled
          is_device_remote_selection
          last_polling
          id_status
          now_server
          machine_model
          last_polling_details {
            __typename
            id_device
            response_date
            rssi
            telephone_operator
            response_delay
            fw_version
            errors_status
            connection_type
          }
          last_restart
          last_disconnection
          env_id
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadAdminDevicesQuery>>response.data.readAdminDevices;
  }
  async ReadManager(): Promise<ReadManagerQuery> {
    const statement = `query ReadManager {
        readManager {
          __typename
          id_manager
          business_name
          url_logo
          is_enabled
          is_logo_enabled
          currency {
            __typename
            iso_code
            decimal
            symbol
          }
          email_contact
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <ReadManagerQuery>response.data.readManager;
  }
  async ReadManagerPayments(
    input?: ManagerPaymentFilterInput
  ): Promise<Array<ReadManagerPaymentsQuery>> {
    const statement = `query ReadManagerPayments($input: ManagerPaymentFilterInput) {
        readManagerPayments(input: $input) {
          __typename
          unique_payment_id
          factory {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          device {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
            last_polling_details {
              __typename
              id_device
              response_date
              rssi
              telephone_operator
              response_delay
              fw_version
              errors_status
              connection_type
            }
            last_restart
            last_disconnection
            env_id
          }
          id_manager
          user_id
          payment_id
          date
          amount
          status
          user_view_id
          selection
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ReadManagerPaymentsQuery>>response.data.readManagerPayments;
  }
  async ReadManagerPaymentStatus(): Promise<
    Array<ReadManagerPaymentStatusQuery>
  > {
    const statement = `query ReadManagerPaymentStatus {
        readManagerPaymentStatus {
          __typename
          id_status
          css
          localized_descriptions
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadManagerPaymentStatusQuery>>(
      response.data.readManagerPaymentStatus
    );
  }
  async ReadPaymentSelectionsDescriptions(): Promise<
    Array<ReadPaymentSelectionsDescriptionsQuery>
  > {
    const statement = `query ReadPaymentSelectionsDescriptions {
        readPaymentSelectionsDescriptions {
          __typename
          selection
          localized_description
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadPaymentSelectionsDescriptionsQuery>>(
      response.data.readPaymentSelectionsDescriptions
    );
  }
  async ReadSimStatus(id_device: number): Promise<ReadSimStatusQuery> {
    const statement = `query ReadSimStatus($id_device: Int!) {
        readSimStatus(id_device: $id_device) {
          __typename
          id_device
          iccid
          imei
          status
          ratePlan
          communicationPlan
          ctdDataUsage
          ctdSessionCount
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_device
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReadSimStatusQuery>response.data.readSimStatus;
  }
  async ReadDevicePolling(
    input: DevicePollingFilterInput
  ): Promise<Array<ReadDevicePollingQuery>> {
    const statement = `query ReadDevicePolling($input: DevicePollingFilterInput!) {
        readDevicePolling(input: $input) {
          __typename
          id_device
          response_date
          rssi
          telephone_operator
          response_delay
          fw_version
          errors_status
          connection_type
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ReadDevicePollingQuery>>response.data.readDevicePolling;
  }
  async ReadPaymentTimes(
    input: DevicePollingFilterInput
  ): Promise<Array<ReadPaymentTimesQuery>> {
    const statement = `query ReadPaymentTimes($input: DevicePollingFilterInput!) {
        readPaymentTimes(input: $input) {
          __typename
          id_device
          id_factory
          factory_name
          factory_id_flow
          creation_date
          ms_for_creation
          ms_for_direct_payment
          s_for_verify
          payment_total_time
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ReadPaymentTimesQuery>>response.data.readPaymentTimes;
  }
  async ListManagers(): Promise<Array<ListManagersQuery>> {
    const statement = `query ListManagers {
        listManagers {
          __typename
          id_manager
          business_name
          url_logo
          is_enabled
          currency {
            __typename
            iso_code
            decimal
            symbol
          }
          sap_code
          number_of_machine
          vat_number
          sale_ok
          sale_failed
          email_contact
          is_logo_enabled
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          service_expiration_date
          ko_payments_expiration_date
          is_demo
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListManagersQuery>>response.data.listManagers;
  }
  async ReadSniffingFiles(
    id_device: number,
    start_date: string,
    end_date: string
  ): Promise<Array<ReadSniffingFilesQuery>> {
    const statement = `query ReadSniffingFiles($id_device: Int!, $start_date: AWSDateTime!, $end_date: AWSDateTime!) {
        readSniffingFiles(id_device: $id_device, start_date: $start_date, end_date: $end_date) {
          __typename
          id_device
          creation_date
          url
          end_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_device,
      start_date,
      end_date
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ReadSniffingFilesQuery>>response.data.readSniffingFiles;
  }
  async ReadActiveErrors(
    id_device: number
  ): Promise<Array<ReadActiveErrorsQuery>> {
    const statement = `query ReadActiveErrors($id_device: Int!) {
        readActiveErrors(id_device: $id_device) {
          __typename
          id_device
          error
          last_start_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_device
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ReadActiveErrorsQuery>>response.data.readActiveErrors;
  }
  async StartQueryLog(
    start_date: string,
    end_date: string
  ): Promise<string> {
    const statement = `query StartQueryLog($start_date: AWSDateTime!, $end_date: AWSDateTime!) {
        startQueryLog(start_date: $start_date, end_date: $end_date)
      }`;
    const gqlAPIServiceArguments: any = {
      start_date,
      end_date
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string>response.data.startQueryLog;
  }
  async GetQueryLogResult(id_query: string): Promise<GetQueryLogResultQuery> {
    const statement = `query GetQueryLogResult($id_query: String!) {
        getQueryLogResult(id_query: $id_query) {
          __typename
          logs {
            __typename
            group
            type
            event
            request_id
            timestamp
          }
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_query
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQueryLogResultQuery>response.data.getQueryLogResult;
  }
  async GetManagerSaleSummary(
    id_factory: number,
    start_date: string,
    end_date: string
  ): Promise<GetManagerSaleSummaryQuery> {
    const statement = `query GetManagerSaleSummary($id_factory: Int!, $start_date: AWSDateTime!, $end_date: AWSDateTime!) {
        getManagerSaleSummary(id_factory: $id_factory, start_date: $start_date, end_date: $end_date) {
          __typename
          sale_value
          start_date
          end_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id_factory,
      start_date,
      end_date
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetManagerSaleSummaryQuery>response.data.getManagerSaleSummary;
  }
  async GetPresignedUrlLogo(): Promise<GetPresignedUrlLogoQuery> {
    const statement = `query GetPresignedUrlLogo {
        getPresignedUrlLogo {
          __typename
          url
          fields
          key
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <GetPresignedUrlLogoQuery>response.data.getPresignedUrlLogo;
  }
  async GetPresignedUrlApplePayCertificate(): Promise<string | null> {
    const statement = `query GetPresignedUrlApplePayCertificate {
        getPresignedUrlApplePayCertificate
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <string | null>response.data.getPresignedUrlApplePayCertificate;
  }
  async GetPresignedUrlApplePayCsr(): Promise<GetPresignedUrlApplePayCsrQuery> {
    const statement = `query GetPresignedUrlApplePayCsr {
        getPresignedUrlApplePayCsr {
          __typename
          url
          fields
          key
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <GetPresignedUrlApplePayCsrQuery>(
      response.data.getPresignedUrlApplePayCsr
    );
  }
  async GetLicenseInfo(): Promise<GetLicenseInfoQuery> {
    const statement = `query GetLicenseInfo {
        getLicenseInfo {
          __typename
          id_manager
          service_expiration_date
          ko_payments_expiration_date
          notify_expiration
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <GetLicenseInfoQuery>response.data.getLicenseInfo;
  }
  async ReadAdminManagerPayments(
    input?: ManagerPaymentFilterInput
  ): Promise<Array<ReadAdminManagerPaymentsQuery>> {
    const statement = `query ReadAdminManagerPayments($input: ManagerPaymentFilterInput) {
        readAdminManagerPayments(input: $input) {
          __typename
          unique_payment_id
          factory {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          device {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
            last_polling_details {
              __typename
              id_device
              response_date
              rssi
              telephone_operator
              response_delay
              fw_version
              errors_status
              connection_type
            }
            last_restart
            last_disconnection
            env_id
          }
          id_manager
          user_id
          payment_id
          date
          amount
          status
          user_view_id
          selection
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ReadAdminManagerPaymentsQuery>>(
      response.data.readAdminManagerPayments
    );
  }
  async ReadEnvironments(): Promise<Array<ReadEnvironmentsQuery>> {
    const statement = `query ReadEnvironments {
        readEnvironments {
          __typename
          env_id
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ReadEnvironmentsQuery>>response.data.readEnvironments;
  }
  OnUpdateDeviceManagerListener: Observable<
    SubscriptionResponse<OnUpdateDeviceManagerSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDeviceManager($id_manager: Int) {
        onUpdateDeviceManager(id_manager: $id_manager) {
          __typename
          id_device
          id_manager
          serial_number
          id_type
          id_device_sale_type
          alias
          address
          latitude
          longitude
          timezone
          qrcode {
            __typename
            id_qrcode
            identifier
            qrcode
            id_version
          }
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_remote_selection_enabled
          is_device_remote_selection
          last_polling
          id_status
          now_server
          machine_model
          last_polling_details {
            __typename
            id_device
            response_date
            rssi
            telephone_operator
            response_delay
            fw_version
            errors_status
            connection_type
          }
          last_restart
          last_disconnection
          env_id
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateDeviceManagerSubscription>>;

  OnUpdateDeviceAdminListener: Observable<
    SubscriptionResponse<OnUpdateDeviceAdminSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDeviceAdmin {
        onUpdateDeviceAdmin {
          __typename
          id_device
          id_manager
          serial_number
          id_type
          id_device_sale_type
          alias
          address
          latitude
          longitude
          timezone
          qrcode {
            __typename
            id_qrcode
            identifier
            qrcode
            id_version
          }
          factories {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          is_remote_selection_enabled
          is_device_remote_selection
          last_polling
          id_status
          now_server
          machine_model
          last_polling_details {
            __typename
            id_device
            response_date
            rssi
            telephone_operator
            response_delay
            fw_version
            errors_status
            connection_type
          }
          last_restart
          last_disconnection
          env_id
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateDeviceAdminSubscription>>;

  OnUpdateManagerPaymentListener: Observable<
    SubscriptionResponse<OnUpdateManagerPaymentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateManagerPayment($id_manager: Int!) {
        onUpdateManagerPayment(id_manager: $id_manager) {
          __typename
          unique_payment_id
          factory {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          device {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
            last_polling_details {
              __typename
              id_device
              response_date
              rssi
              telephone_operator
              response_delay
              fw_version
              errors_status
              connection_type
            }
            last_restart
            last_disconnection
            env_id
          }
          id_manager
          user_id
          payment_id
          date
          amount
          status
          user_view_id
          selection
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateManagerPaymentSubscription>>;

  OnInsertPollingPieceListener: Observable<
    SubscriptionResponse<OnInsertPollingPieceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnInsertPollingPiece($id_device: Int) {
        onInsertPollingPiece(id_device: $id_device) {
          __typename
          id_device
          id_manager
          id_sniffing_session
          body
          arrival_date
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnInsertPollingPieceSubscription>>;

  OnCreateLogListener: Observable<
    SubscriptionResponse<OnCreateLogSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateLog {
        onCreateLog {
          __typename
          group
          type
          event
          request_id
          timestamp
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateLogSubscription>>;

  OnUpdateUserPaymentListener: Observable<
    SubscriptionResponse<OnUpdateUserPaymentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserPayment($user_id: String!) {
        onUpdateUserPayment(user_id: $user_id) {
          __typename
          unique_payment_id
          factory {
            __typename
            id_factory
            name
            url_logo
            url_logo_brand
            is_activated
            is_demo
            id_flow
            fields
            is_editable_after_activation
            url_website
            id_type
            is_used
            subfactories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_visible
            url_privacy_policy
          }
          device {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              url_logo_brand
              is_activated
              is_demo
              id_flow
              fields
              is_editable_after_activation
              url_website
              id_type
              is_used
              is_visible
              url_privacy_policy
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
            last_polling_details {
              __typename
              id_device
              response_date
              rssi
              telephone_operator
              response_delay
              fw_version
              errors_status
              connection_type
            }
            last_restart
            last_disconnection
            env_id
          }
          id_manager
          user_id
          payment_id
          date
          amount
          status
          user_view_id
          selection
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateUserPaymentSubscription>>;

  OnNewSmartphoneMessageListener: Observable<
    SubscriptionResponse<OnNewSmartphoneMessageSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnNewSmartphoneMessage($cognito_identity_id: String!, $id_smartphone: Int!) {
        onNewSmartphoneMessage(cognito_identity_id: $cognito_identity_id, id_smartphone: $id_smartphone) {
          __typename
          cognito_identity_id
          id_smartphone
          functionName
          payload
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnNewSmartphoneMessageSubscription>>;
}
