import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n extends MatPaginatorIntl {

    constructor(private readonly translate: TranslateService) {
        super();
        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
        });
        this.translateLabels();
    }

    getRangeLabel = function (page: number, pageSize: number, length: number): string {
        var label;
        length = Math.max(length, 0);
        if (length === 0 || pageSize === 0) {
            label = this.translate.instant('range_page_1', { length });
        } else {
            const startIndex = page * pageSize;
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            label = this.translate.instant('range_page_2', { startIndex: startIndex + 1, endIndex, length });
        }
        return label;
    }

    translateLabels() {
        this.itemsPerPageLabel = this.translate.instant('items_per_page');
        this.nextPageLabel = this.translate.instant('next_page');
        this.previousPageLabel = this.translate.instant('previous_page');
        this.firstPageLabel = this.translate.instant('first_page');
        this.lastPageLabel = this.translate.instant('last_page');
        this.changes.next();
    }

}

