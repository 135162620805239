import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatTable, MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
const moment = require('moment');
import { APIService, ListManagersQuery, Log } from '../API.service';
import { DialogConfirm } from '../dialog-confirm/DialogConfirm';
import { LoadingService } from '../services/loading.service';
import { FactoryModalComponent } from './factory-modal/factory-modal.component';
import { NewCensusTokenModalComponent } from './new-census-token-modal/new-census-token-modal.component';
import { NewLogoModalComponent } from './new-logo-modal/new-logo-modal.component';

export type ManagerLoading = ListManagersQuery & { loading?: boolean };

@Component({
  selector: 'app-managers',
  templateUrl: './managers.component.html',
  styleUrls: ['./managers.component.less']
})
export class ManagersComponent implements OnInit {

  displayedColumns = ['sap_code', 'business_name', 'vat_number', 'is_enabled', 'number_of_machine', 'sale_ok', 'sale_failed', 'logo', /*'factories',*/ 'service_end', 'ko_payments', 'operations', 'save'];
  pageSizeOptions = [10, 20, 30];
  dataSource: MatTableDataSource<ManagerLoading>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<Log>;

  managers: ManagerLoading[];
  originalObject: { [key: number]: string } = {};

  constructor(
    private loadingService: LoadingService,
    private apiService: APIService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private confirmDialog: MatDialog) {
    this.dataSource = new MatTableDataSource<ManagerLoading>([]);
  }

  async ngOnInit() {
    this.loadingService.startLoading();
    this.managers = await this.apiService.ListManagers();
    for (const m of this.managers) {
      m.service_expiration_date = m.service_expiration_date.substring(0, 16);
      m.ko_payments_expiration_date = m.ko_payments_expiration_date.substring(0, 16);
      m.loading = false;
      delete m.__typename;
      delete m.currency.__typename;
      for (const f of m.factories) {
        delete f.__typename;
      }
      this.originalObject[m.id_manager] = JSON.stringify(m);
    }
    this.dataSource.data = this.managers;
    this.loadingService.stopLoading();
  }

  openNewLogoModal(manager: ManagerLoading) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    //dialogConfig.width = window.innerWidth + 'px';
    dialogConfig.data = { manager };
    const dialogRef = this.dialog.open(NewLogoModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(_result => {
      this.ngOnInit();
    })
  }

  openFactoryModal(manager: ManagerLoading) {
    const factoriesBackup = JSON.stringify(manager.factories);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { manager };
    const dialogRef = this.dialog.open(FactoryModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.save) {
        //this.saveModifications(manager);
      } else {
        //this.ngOnInit();
        manager.factories = JSON.parse(factoriesBackup);
      }
    })
  }

  async saveModifications(manager: ManagerLoading) {
    manager.loading = true;
    let managerAppSync = JSON.parse(JSON.stringify(manager));
    managerAppSync.service_expiration_date = manager.service_expiration_date + ':00.000Z';
    managerAppSync.ko_payments_expiration_date = manager.ko_payments_expiration_date + ':00.000Z';
    delete managerAppSync.loading;
    try {
      await this.apiService.UpdateManagerAdmin(managerAppSync);
    } catch (e) {
      console.error(e);
      this._snackBar.open('Errore! Sicuro che i dati siano corretti e che la data di ko pagamenti sia maggiore di quella di fine servizio?', null, {
        duration: 6000,
        panelClass: ['warning-panel']
      });
    }
    manager.loading = false;
    await this.ngOnInit();
  }

  isModified(manager: ManagerLoading) {
    return JSON.stringify(manager) != this.originalObject[manager.id_manager];
  }

  async upgradeManagerFromDemoToProd(manager: ManagerLoading) {

    const confirm = new DialogConfirm(this.confirmDialog, "Sei sicuro di voler eseguire l'UPGRADE", "Sei sicuro di voler eseguire un UPGRADE del profilo gestore passando dalla modalità DEMO alla modalità in PRODUZIONE? Questa operazione non è reversibile!");
    confirm.confirm(async () => {
      try {
        let managerAppSync = JSON.parse(JSON.stringify(manager));
        managerAppSync.service_expiration_date = manager.service_expiration_date + ':00.000Z';
        managerAppSync.ko_payments_expiration_date = manager.ko_payments_expiration_date + ':00.000Z';
        delete managerAppSync.loading;
        await this.apiService.UpgradeManagerFromDemoToProd(managerAppSync);
      } catch (e) {
        console.error(e);
        this._snackBar.open('Si è verificato un errore durante il cambio da DEMO a PRODUZIONE, riprovare!', null, {
          duration: 6000,
          panelClass: ['warning-panel']
        });
      }
      await this.ngOnInit();
    });

  }

  openNewCensusTokenModal = function (manager: ManagerLoading) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = manager;
    dialogConfig.width = '600px';
    this.dialog.open(NewCensusTokenModalComponent, dialogConfig);
  };

}
