import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DialogConfirm } from 'src/app/dialog-confirm/DialogConfirm';
import { FactoriesService } from 'src/app/services/factories.service';
import { LoadingService } from 'src/app/services/loading.service';
import { APIService, ReadFactoriesQuery as Factory } from '../../API.service';
import { ManagerService } from 'src/app/services/manager.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dialog-modify-factory',
  templateUrl: './dialog-modify-factory.component.html',
  styleUrls: ['./dialog-modify-factory.component.less']
})
export class DialogModifyFactoryComponent implements OnInit {

  loadingDownloadApplePaySakurtraCertificate: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public factory: Factory,
    public factoriesService: FactoriesService,
    private dialog: MatDialogRef<DialogModifyFactoryComponent>,
    private loadingService: LoadingService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private apiService: APIService,
    public managerService: ManagerService) {

  }

  async ngOnInit() {
    this.loadingDownloadApplePaySakurtraCertificate = false;
    await this.managerService.readManager();
  }

  allFieldIsConfigured(): boolean {
    let allFieldConfigured = true;
    for (const key of Object.keys(this.factory.fields)) {
      if (this.factory.fields[key].is_editable) {
        allFieldConfigured = allFieldConfigured &&
          this.factory.fields[key].value !== undefined &&
          this.factory.fields[key].value !== null &&
          this.factory.fields[key].value.length > 0;
      }
    }
    return allFieldConfigured;
  }

  getDisableToggleButton() {
    return this.factory.is_activated ? 'warn' : 'accent';
  }

  async toggleDisableFactory() {
    let is_activable = true;
    if (this.factory.id_factory == 4) { // SUMUP
      //se bisogna eseguire l'autorizzaione aouth avviso che non è possibile abilitare prima di aver eseguito il processo
      is_activable = this.factory.fields["_oauth_url"] == undefined;
    }
    if (is_activable) {
      this.factory.is_activated = !this.factory.is_activated;
      await this.saveFactory();
    } else {
      this.snackBar.open(this.translate.instant('error_aouth_required_first'), null, {
        duration: 5000
      });
    }
  }

  copied() {
    this.snackBar.open(this.translate.instant('data_copied'), null, {
      duration: 2000
    });
  }

  async saveFactory() {
    this.loadingService.startLoading();
    const result = await this.factoriesService.saveFactory(this.factory);
    this.loadingService.stopLoading();

    if (result != null) {
      this.factory.fields = JSON.parse(result.fields);
      this.factory.is_activated = result.is_activated;
      for (var i = 0; i < this.factory.subfactories.length; i++) {
        this.factory.subfactories[i].is_activated = result.subfactories[i].is_activated;
      }
      this.dialog.close(this.factory);

      this.snackBar.open(this.translate.instant('data_saved'), null, {
        duration: 2000
      });
    } else {
      this.snackBar.open(this.translate.instant('error_data_save'), null, {
        duration: 3000
      });
    }
  }

  async downloadApplePaySakurtraCertificate() {
    this.loadingDownloadApplePaySakurtraCertificate = true;

    const url = await this.apiService.GetPresignedUrlApplePayCertificate();
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'SAKURTRA-APPLEPAY.p12');
    element.click();

    this.loadingDownloadApplePaySakurtraCertificate = false;
  }

  private getFileData(fileInput) {
    return new Promise((resolve, reject) => {
      fileInput.onchange = res => {
        resolve(res.target.files[0]);
      };
      fileInput.onerror = err => {
        reject(err)
      };
    });
  }

  private async readFile(file) {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = res => {
        resolve(new Uint8Array((res.target as any).result));
      };
      reader.onerror = err => reject(err);
      reader.readAsArrayBuffer(file);
    });
  }

  async uploadApplePayCsr() {

    const inp = document.getElementById('applepay-csr');
    inp.click();

    const fileData = await this.getFileData(inp);

    if (fileData != undefined) {
      this.loadingService.startLoading();

      const file = await this.readFile(fileData);
      const data = await this.apiService.GetPresignedUrlApplePayCsr();

      if (data != null) {
        const fields = JSON.parse(data.fields);

        var form = new FormData();
        Object.keys(fields).forEach(key => {
          form.append(key, fields[key]);
        });
        form.append('file', new Blob([file], {}));
        await fetch(data.url, { method: 'POST', body: form });

        this.loadingService.stopLoading();
        this.snackBar.open(this.translate.instant('applepay_upload_ok'), null, {
          duration: 6000
        });
        this.dialog.close();
      } else {

        this.loadingService.stopLoading();
        this.snackBar.open(this.translate.instant('applepay_upload_error'), null, {
          duration: 5000
        });
      }

    }
  }

  orderFields(fields) {
    var f = [];
    for (var i = 0; i < fields.length; i++) {
      if (fields[i].value.order != undefined) {
        f[fields[i].value.order - 1] = fields[i];
      } else {
        f[f.length] = fields[i];
      }
    }
    return f;
  }

  isFactoryEnabled(factories: [Factory], factory: Factory) {
    return factories.some(f => f.id_factory === factory.id_factory);
  }

  hasFactoryOfType(factories: [Factory], factory: Factory) {
    var hasType = false;
    if (factory.id_type != 2) {
      hasType = factories.some(f => f.id_type === factory.id_type);
    }
    return hasType;
  }

  // for factories
  addFactory(factories: [Factory], factory: Factory) {
    if (this.hasFactoryOfType(factories, factory)) {
      this.snackBar.open(this.translate.instant('error_device_factories_types'), null, {
        duration: 5000
      });
    } else if (factory.is_activated && !this.isFactoryEnabled(factories, factory)) {
      factories.push(factory);
    }
  }

  removeFactory(factories: [Factory], factory: Factory) {
    const index = factories.findIndex(f => f.id_factory === factory.id_factory);
    if (index >= 0) {
      factories.splice(index, 1);
    }
  }

}
