import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
const moment = require('moment');
import { DatePipe } from '@angular/common';
import { GetLicenseInfoQuery } from '../API.service';

@Component({
    selector: 'expiration-dialog',
    templateUrl: './expiration-dialog.html'
  })
  export class ExpirationDialogComponent implements OnInit {
  
    param = {service: 'sessione', ko: 'termine tecnico'};
  
    constructor(
      public dialogRef: MatDialogRef<ExpirationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public licenseInfo: GetLicenseInfoQuery,
      private datePipe: DatePipe
    ) {
      this.param.service = this.datePipe.transform(licenseInfo.service_expiration_date.replace('Z', ''), 'dd/MM/yyyy');
      this.param.ko = this.datePipe.transform(licenseInfo.ko_payments_expiration_date.replace('Z', ''), 'dd/MM/yyyy');
    }
  
    ngOnInit() {
    }
  
    close(): void {
      this.dialogRef.close();
    }
  
  }