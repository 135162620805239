import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { APIService, ReadManagerQuery } from '../API.service';
import { LoadingService } from '../services/loading.service';
import { LogoRulesComponent } from './logo-rules-modals.component';

@Component({
  selector: 'app-manager-details',
  templateUrl: './manager-details.component.html',
  styleUrls: ['./manager-details.component.less']
})
export class ManagerDetailsComponent implements OnInit {

  manager: ReadManagerQuery = { business_name: '', __typename: 'Manager', id_manager: -1 };

  loadingName = false;

  constructor(private apiService: APIService,
    private loadingService: LoadingService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    this.loadingService.startLoading();
    this.manager = await this.apiService.ReadManager();
    this.loadingService.stopLoading();
  }

  async modifyName() {
    this.loadingName = true;
    const newManager = await this.apiService.UpdateManagerName(this.manager.business_name, this.manager.email_contact);
    this.loadingName = false;
  }

  readFile(file) {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = res => {
        resolve(new Uint8Array((res.target as any).result));
      };
      reader.onerror = err => reject(err);

      reader.readAsArrayBuffer(file);
    });
  }

  modifiesOnFileInput(fileInput) {
    return new Promise((resolve, reject) => {

      fileInput.onchange = res => {
        resolve(res.target.files[0]);
      };
      fileInput.onerror = err => reject(err);

    });
  }

  async startInputLogo() {
    
    const inp = document.getElementById('input-logo');
    inp.click();
    const fileName = await this.modifiesOnFileInput(inp);
    this.loadingService.startLoading();
    const type = (fileName as any).type;
    const file = await this.readFile(fileName);
    const name = (fileName as any).name.split('.');

    let data = await this.apiService.GetPresignedUrlLogo();

    let form = new FormData();
    /*let data = { url: 'https://s3.eu-west-1.amazonaws.com/public.mcf88.cloud',
    fields:
     { bucket: 'public.mcf88.cloud',
       'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
       'X-Amz-Credential': 'AKIAYAYWVHXWDWAH23XI/20210520/eu-west-1/s3/aws4_request',
       'X-Amz-Date': '20210520T075454Z',
       Policy:
        'eyJleHBpcmF0aW9uIjoiMjAyMS0wNS0yMFQwNzo1OTo1NFoiLCJjb25kaXRpb25zIjpbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsMCw1MjQyODgwXSx7ImJ1Y2tldCI6InB1YmxpYy5tY2Y4OC5jbG91ZCJ9LFsic3RhcnRzLXdpdGgiLCIkQ29udGVudC1UeXBlIiwiaW1hZ2UvIl0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJzYWt1cnRyYS9tYW5hZ2Vycy90ZXN0XzIiXSx7ImJ1Y2tldCI6InB1YmxpYy5tY2Y4OC5jbG91ZCJ9LHsiWC1BbXotQWxnb3JpdGhtIjoiQVdTNC1ITUFDLVNIQTI1NiJ9LHsiWC1BbXotQ3JlZGVudGlhbCI6IkFLSUFZQVlXVkhYV0RXQUgyM1hJLzIwMjEwNTIwL2V1LXdlc3QtMS9zMy9hd3M0X3JlcXVlc3QifSx7IlgtQW16LURhdGUiOiIyMDIxMDUyMFQwNzU0NTRaIn1dfQ==',
       'X-Amz-Signature':
        'a511124b8c6028a67493256e3324e6b16bfb2cefe743cff19fc590779726af83' } };*/
    Object.keys(JSON.parse(data.fields)).forEach(key => form.append(key, JSON.parse(data.fields)[key]));
    form.append('Content-Type', type);
    form.append('file', new Blob([file], { type }));

    console.log(form);

    await fetch(data.url, { method: 'POST', body: form, });

    this.manager = await this.apiService.ReadManager();
    
    this.loadingService.stopLoading();

  }

  openLogoRulesModal(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    //dialogConfig.width = window.innerWidth + 'px';
    //dialogConfig.data = { device };
    const dialogRef = this.dialog.open(LogoRulesComponent, dialogConfig);
  }

}

