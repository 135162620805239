import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SimulationComponent } from './simulation/simulation.component';
import { RoleGuard } from './services/role.guard';
import { HomeComponent } from './home/home.component';
import { DashboardDevicesComponent } from './dashboard-devices/dashboard-devices.component';
import { FactoriesComponent } from './factories/factories.component';
import { ManagerPaymentsComponent } from './manager-payments/manager-payments.component';
import { PageDescription } from './page-description';
import { AdminDashboardDeviceComponent } from './admin-dashboard-device/admin-dashboard-device.component';
import { ManagerActivationComponent } from './manager-activation/manager-activation.component';
import { LogsComponent } from './logs/logs.component';
import { ManagersComponent } from './managers/managers.component';
import { ManagerSummaryComponent } from './manager-summary/manager-summary.component';
import { ManagerDetailsComponent } from './manager-details/manager-details.component';
import { FactoriesConditionsComponent } from './privacy/factories-conditions/factories-conditions.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ManagerQrcodeComponent } from './manager-qrcode/manager-qrcode.component';

export const pages = [
  new PageDescription('login', LoginComponent, 'login', false, null ),
  new PageDescription('simulation', SimulationComponent, 'simulation', true, 'programmatori'),
  new PageDescription('devices', DashboardDevicesComponent, 'devices', true, 'gestori'),
  new PageDescription('factories', FactoriesComponent, 'factories', true, 'gestori'),
  new PageDescription('payments', ManagerPaymentsComponent, 'payments_hystory', true, 'gestori'),
  new PageDescription('manager_summary', ManagerSummaryComponent, 'MANAGER_SUMMARY', true, 'gestori'),
  new PageDescription('manager_details', ManagerDetailsComponent, 'MANAGER_DETAILS', true, 'gestori'),
  new PageDescription('admin_devices', AdminDashboardDeviceComponent, 'admin-devices', true, 'amministratori'),
  new PageDescription('logs', LogsComponent, 'LOGS', true, 'amministratori'),
  new PageDescription('managers', ManagersComponent, 'MANAGERS', true, 'programmatori'),
  new PageDescription('manager_activation', ManagerActivationComponent, 'activation', true, 'utenti'),
  new PageDescription('faqs', FaqsComponent, 'FAQS', false, null )]; 

const routes: Routes = [
  { path: '', component: HomeComponent }
];

for (let i = 0; i < pages.length; i++) {
  let route: Route = {};
  route.path = pages[i].path;
  route.component = pages[i].component;
  if (pages[i].expectedRole != null) {
    route.canActivate = [RoleGuard];
    route.data = { expectedRole: pages[i].expectedRole };
  }
  routes.push(route);
}

//pagine pubbliche
//cookies policy
const cookies: Route = {
  path: "cookies",
  component: CookiesComponent
};
routes.push(cookies);

//condizioni PSP
const factories_conditions: Route = {
  path: "factories_conditions",
  component: FactoriesConditionsComponent
};
routes.push(factories_conditions);

//qrcode gestore
const manager_qrcode: Route = {
  path: "manager_qrcode",
  component: ManagerQrcodeComponent
};
routes.push(manager_qrcode);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
