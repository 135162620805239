import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import CognitoUser, { Auth } from 'aws-amplify';
import { APIService, GetLicenseInfoQuery } from '../API.service';
import { ExpirationDialogComponent } from './ExpirationDialogComponent';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = null;
  inizializzato: Promise<boolean>;
  licenseInfo: GetLicenseInfoQuery = null;

  constructor(
    public router: Router,
    private apiService: APIService,
    private dialog: MatDialog
  ) {
    this.inizializzato = this.inizializza();
  }

  async inizializza(): Promise<boolean> {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      if (this.getUserGroups().includes('gestori')) {
        try {
          this.licenseInfo = await this.apiService.GetLicenseInfo();
          if(this.licenseInfo.notify_expiration){
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = this.licenseInfo;
            dialogConfig.maxWidth = '60vw';
            const dialogRef = this.dialog.open(ExpirationDialogComponent, dialogConfig);
          }
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      this.user = null;
    }
    return true;
  }

  getUserGroups() {
    if (this.user != null) {
      return (this.user as any).signInUserSession.accessToken.payload["cognito:groups"];
    }
    return [];
  }

  hasGroup(group: string) {
    return this.getUserGroups().includes(group);
  }

  async logout() {
    await Auth.signOut();
    this.user = null;
    this.router.navigate(['login']);
  }

  isLoggedIn() {
    return this.user != null;
  }

  async login(username: string, password: string) {
    this.user = await Auth.signIn(username, password);
  }

  public getUsername() {
    let username = "";
    if (this.user != null) {
      username = this.user.attributes.email.split("@")[0];
    }
    return username;
  }

}


