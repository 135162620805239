import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { pages } from './app-routing.module';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { Language, SupportedLanguagesService } from './services/supported-languages.service';

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'EnginkoPaymentsTestFrontend';

  pages = pages;

  public lingueCaricate = false;

  constructor(public authService: AuthService,
    public translate: TranslateService,
    public supportedLanguagesService: SupportedLanguagesService,
    public loadingService: LoadingService) {
  }

  async ngOnInit() {
    this.supportedLanguagesService.languageLoading = new Promise(async (resolve, _reject) => {
      setTimeout(() => {
        document.getElementById('logo-splash').classList.add('pulse');
      }, 3500);
      /*console.log('component mounted');
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            this.setState({ user: data });
            break;
          case 'signOut':
            this.setState({ user: null });
            break;
          case 'customOAuthState':
            this.setState({ customState: data });
        }
      });
  
      Auth.currentAuthenticatedUser()
        .then(user => this.setState({ user }))
        .catch(() => console.log('Not signed in'));*/
  
      const minimo = sleep(3800);
  
      await this.supportedLanguagesService.readSupportedLanguages();
      this.translate.setDefaultLang('en');
  
      const lang = navigator.language.split('-')[0];
      this.translate.use(lang);
  
      // aggiunta di lingue per FAQs
      //console.log(this.translate.store.translations['it']);
      //this.translate.store.translations.it.INVENTATO = 'Inventato';
      //this.translate.set('INVENTATO', 'Inventato', 'it');
  
      //this.translate.setTranslation('it', {"INVENTATO": 'Inventato'}, false);
  
      await minimo;
      document.getElementById('splashDiv').classList.add('fade-out');
      await sleep((1 / 3 * 2) * 1000);
      this.lingueCaricate = true;
      resolve();
  
      //this.translate.store.translations.it.INVENTATO = 'Inventato';
      /*this.translate.setTranslation('it', {"INVENTATO": 'Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato Inventato'}, true);
      this.translate.setTranslation('en', {"INVENTATO": 'Invented'}, true);*/
    })
  }

  setLanguage(language: Language) {
    this.translate.use(language.iso_code);
  }

  async setState(obj: any) {
    /*console.log(obj);
    if (obj.user != null) {
      const credentials = await Auth.currentCredentials();
      Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: 'eu-west-1',
        aws_pubsub_endpoint: `wss://a1kuyoz9z9t14o-ats.iot.eu-west-1.amazonaws.com/mqtt`,
        clientId: 'client-connesso'  // 'TESTENGINKOWEB-client'
        // clientId: 'MARTE_TESTENGINKOWEB'
      }));
      Amplify.PubSub.subscribe('enginko_payments/mcf88/1_TESTENGINKOWEB/down/credit').subscribe({
        next: data => {
          console.log(data);
        }
      });
    }*/
  }

  getPageIconVisibility(page) {
    var visible = true;
    if (page.getExpectedRole() == 'gestori' || page.getExpectedRole() == 'utenti' ||
      page.path == 'login' || page.path == 'factories_conditions' || page.path == 'faqs') {
      visible = false;
    }
    return visible;
  };

}


