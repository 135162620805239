import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { APIService, GetQueryLogResultQuery } from '../API.service';
import { LoadingService } from '../services/loading.service';
import { OnCreateLogSubscription as Log } from '../API.service';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { trigger, state, style, transition, animate } from '@angular/animations';
const moment = require('moment');

async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', paddingBottom: '16px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LogsComponent implements OnInit, OnDestroy {

  displayedColumns = ['timestamp', 'group', 'event'];
  pageSizeOptions = [10, 20, 30];
  dataSource: MatTableDataSource<Log>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<Log>;

  logs = [];
  selectedLog = null;
  loadingLog = false;

  start: string = new moment().subtract(10, 'minutes').format(`YYYY-MM-DDTHH:mm`);
  end: string = new moment().format(`YYYY-MM-DDTHH:mm`);

  listener = null;
  newLogListening = true;

  ricercaLog = '';

  constructor(private apiService: APIService,
    private loadingService: LoadingService,
    private changeDetectorRefs: ChangeDetectorRef) { }
  ngOnDestroy(): void {
    this.stopListening();
  }

  startListening() {
    this.logs = [];
    this.dataSource.data = [];
    this.changeDetectorRefs.detectChanges();
    this.table.renderRows();
    this.newLogListening = true;
    this.listener = this.apiService.OnCreateLogListener.subscribe(next => {
      const log: Log = (next.value.data as any).onCreateLog;
      log.group = log.group.split('/')[3];
      this.logs.push(log);

      this.dataSource.data = this.logs;
      this.changeDetectorRefs.detectChanges();
      this.table.renderRows();
    });
  }

  stopListening() {
    this.newLogListening = false;
    if (this.listener != null) {
      this.listener.unsubscribe();
    }
  }

  async getLog() {
    this.loadingLog = true;
    this.logs = [];
    this.dataSource.data = [];
    this.changeDetectorRefs.detectChanges();
    this.table.renderRows();
    const id = await this.apiService.StartQueryLog(new Date(this.start).toJSON(), new Date(this.end).toJSON());
    let responseLogs: GetQueryLogResultQuery;
    do {
      await sleep(2000);
      responseLogs = await this.apiService.GetQueryLogResult(id);
      //console.log(responseLogs);
    } while (responseLogs.status == 'Running');
    if (responseLogs.status == 'Complete') {
      for (let i = 0; i < responseLogs.logs.length; i++) {
        responseLogs.logs[i].group = responseLogs.logs[i].group.split('/')[3]
      }
      this.logs = responseLogs.logs;
      this.dataSource.data = this.logs;
      this.changeDetectorRefs.detectChanges();
      this.table.renderRows();
    }
    this.loadingLog = false;
  }

  async ngOnInit() {
    this.loadingService.startLoading();

    this.dataSource = new MatTableDataSource<Log>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: Log, filter: string): boolean => {
      filter = filter.toLowerCase();
      return data.event.toLowerCase().includes(filter)
        || data.group.toLowerCase().includes(filter)
        || data.type.toLowerCase().includes(filter)
        || this.getAnteprima(data).toLowerCase().includes(filter);
    }

    this.startListening();

    /*const id = await this.apiService.StartQueryLog('2021-04-21T08:00:00.000Z', '2021-04-21T08:05:00.000Z');
    console.log(id);
    await sleep(2000);*/
    /*const id='c6c97b81-78d9-4aad-9228-69733a3673ab';
    const res = await this.apiService.GetQueryLogResult(id);
    console.log(res);
    this.logs = res.logs;
    this.dataSource.data = this.logs;
    this.changeDetectorRefs.detectChanges();
    this.table.renderRows();*/
    this.loadingService.stopLoading();
  }

  resetSelected() {
    console.log('reset selectedlog');
    this.selectedLog = null;
  }

  cambioRicerca() {
    this.ricercaLog = this.ricercaLog.trim();
    this.dataSource.filter = this.ricercaLog;
  }

  getAnteprima(log: Log): string {
    let anteprima = log.event;

    let oggetto = this.getJson(log.event);
    if (oggetto.valid && oggetto.value != null) {
      if (oggetto.value.httpMethod != null
        && oggetto.value.path != null) {
        anteprima = oggetto.value.httpMethod + ' ' + oggetto.value.path;
        if (oggetto.value.path == '/credit') {
          anteprima = 'Richiesta invio di ' + ((oggetto.value.queryStringParameters.credit) / 100).toFixed(2) + ' € a device ' + oggetto.value.queryStringParameters.id_device
        }
      }
      if (oggetto.value.responseCensored != null) {
        anteprima = 'Risposta ' +
          oggetto.value.responseCensored.status +
          (oggetto.value.url != null ? ' ' + oggetto.value.url : '') +
          ' ' +
          JSON.stringify(oggetto.value.responseCensored.body);
      }
      if (oggetto.value.messagetopic != null) {
        const topic = oggetto.value.messagetopic.split('/');
        if ((oggetto.value.messagetopic as string).endsWith('polling_response')) {
          anteprima = 'Risposta polling da ' + topic[topic.length - 3];
        }
        if ((oggetto.value.messagetopic as string).endsWith('down/polling')) {
          anteprima = 'Richiesta polling a ' + topic[topic.length - 3];
        }
        if ((oggetto.value.messagetopic as string).endsWith('down/credit')) {
          anteprima = 'Invio di ' + ((oggetto.value.credit) / 100).toFixed(2) + ' € a ' + topic[topic.length - 3];
        }
      }
    }

    /*if (anteprima.length > 100) {
      anteprima = anteprima.substring(0, 100) + '...';
    }*/
    return anteprima;
  }

  getOggetto(json: string) {
    return JSON.parse(json);
  }

  getSelezione(event: string, index: number): number {
    if (event.length <= 100) {
      return this.selectedLog;
    }
    return this.selectedLog == index ? null : index;
  }

  getJson(item): { valid: boolean, value: any } {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return { valid: false, value: null };
    }

    if (typeof item === "object" && item !== null) {
      return { valid: true, value: item };
    }

    return { valid: false, value: null };
  }

}
