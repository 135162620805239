import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ReadFactoriesQuery as Factory, ReadDevicesQuery as Device } from '../../API.service';
import { DevicesService } from 'src/app/services/devices.service';
import { FactoriesService } from 'src/app/services/factories.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-dialog-modify-device',
  templateUrl: './dialog-modify-device.component.html',
  styleUrls: ['./dialog-modify-device.component.less']
})
export class DialogModifyDeviceComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public device: Device,
    public factoriesService: FactoriesService,
    public devicesService: DevicesService,
    private dialog: MatDialogRef<DialogModifyDeviceComponent>,
    private loadingService: LoadingService,
    private snackBar: MatSnackBar,
    private translate: TranslateService) {
  }

  async ngOnInit() {

    if (this.factoriesService.factories === undefined
      || this.factoriesService.factories.length === 0) {

      await this.factoriesService.readFactories();
    }
  }

  async saveDevice() {
    this.loadingService.startLoading();
    const device = await this.devicesService.saveDevice(this.device);
    this.loadingService.stopLoading();

    if (device != null) {
      this.device.address = device.address;
      this.dialog.close(this.device);

      this.snackBar.open(this.translate.instant('data_saved'), null, {
        duration: 2000
      });
    } else {
      this.snackBar.open(this.translate.instant('error_data_save'), null, {
        duration: 3000
      });
    }
  }

  isFactoryEnabled(factory: Factory) {
    return this.device.factories.some(f => f.id_factory === factory.id_factory);
  }

  hasFactoryOfType(factory: Factory) {
    var hasType = false;
    if (factory.id_type != 2) {
      hasType = this.device.factories.some(f => f.id_type === factory.id_type);
    }
    return hasType;
  }

  // for factories
  addFactory(factory: Factory) {
    if (this.hasFactoryOfType(factory)) {
      this.snackBar.open(this.translate.instant('error_device_factories_types'), null, {
        duration: 5000
      });
    } else if (factory.is_activated && !this.isFactoryEnabled(factory)) {
      this.device.factories.push(factory);
    }
  }

  removeFactory(factory: Factory) {
    const index = this.device.factories.findIndex(f => f.id_factory === factory.id_factory);
    if (index >= 0) {
      this.device.factories.splice(index, 1);
    }
  }

}
