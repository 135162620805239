import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewContainerRef
  } from '@angular/core';
  import { ThemePalette } from '@angular/material/core';
  import { MatProgressSpinner } from '@angular/material/progress-spinner';
  import { MatSlideToggle } from '@angular/material';
  
  @Directive({
    selector: `mat-slide-toggle[loading]`,
  })
  export class MatToggleLoadingDirective implements OnChanges {
    private spinnerFactory: ComponentFactory<MatProgressSpinner>;
    private spinner: ComponentRef<MatProgressSpinner>;
  
    @Input()
    loading: boolean;
  
    @Input()
    disabled: boolean;
  
    @Input()
    color: ThemePalette;
  
    constructor(
      private matToggle: MatSlideToggle,
      private componentFactoryResolver: ComponentFactoryResolver,
      private viewContainerRef: ViewContainerRef,
      private renderer: Renderer2
    ) {
      this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    }
  
    ngOnChanges(changes: SimpleChanges): void {
      if (!changes.loading) {
        return;
      }
  
      if (changes.loading.currentValue) {
        this.matToggle._elementRef.nativeElement.classList.add('mat-loading');
        this.matToggle.disabled = true;
        this.createSpinner();
      } else if (!changes.loading.firstChange) {
        this.matToggle._elementRef.nativeElement.classList.remove('mat-loading');
        this.matToggle.disabled = this.disabled;
        this.destroySpinner();
      }
    }
  
    private createSpinner(): void {
      if (!this.spinner) {
        this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
        this.spinner.instance.color = this.color;
        this.spinner.instance.diameter = 16;
        this.spinner.instance.mode = 'indeterminate';
        this.renderer.appendChild(this.matToggle._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);
      }
    }
  
    private destroySpinner(): void {
      if (this.spinner) {
        this.spinner.destroy();
        this.spinner = null;
      }
    }
  }
  