import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FirmwareSelectUpdateDialogComponent, FirmwareSelectUpdateDialogComponentContract } from '../firmware-select-update-dialog/firmware-select-update-dialog.component';
import { ReadDevicesQuery as Device } from '../../API.service';

interface firmware{
  id_firmware: number,
  link: string,
  version: string
}

export class SetUpdateDataContract {
  firmwares: firmware[];
  devices: Device[];

  constructor(firmwares: firmware[], devices: Device[]){
    this.firmwares = firmwares;
    this.devices = devices;
  }

}

@Component({
  selector: 'app-set-update',
  templateUrl: './set-update.component.html',
  styleUrls: ['./set-update.component.less']
})
export class SetUpdateComponent implements OnInit {

  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnsToDisplay = ['version', 'link', 'select'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: SetUpdateDataContract,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FirmwareSelectUpdateDialogComponent>) {
    this.dataSource = new MatTableDataSource<firmware>(this.data.firmwares);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  select(fw){
    console.log(fw);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = window.innerWidth + 'px';
    dialogConfig.data = new FirmwareSelectUpdateDialogComponentContract(fw.id_firmware, fw.version, this.data.devices);
    this.dialogRef.close(null);
    this.dialog.open(FirmwareSelectUpdateDialogComponent, dialogConfig);
  }

}
