import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService, GetManagerSaleSummaryQuery, ReadFactoriesQuery } from '../API.service';
import * as moment from "moment";
import { ManagerService } from '../services/manager.service';
import { LoadingService } from '../services/loading.service';
import { ManagerPaymentsService } from '../services/manager-payments.service';

type FactoryWithSummary = ReadFactoriesQuery & {
  summary?: {
    day: GetManagerSaleSummaryQuery,
    week: GetManagerSaleSummaryQuery,   // la week diventerà il "da sempre"
    month: GetManagerSaleSummaryQuery
  }
}

@Component({
  selector: 'app-manager-summary',
  templateUrl: './manager-summary.component.html',
  styleUrls: ['./manager-summary.component.less']
})
export class ManagerSummaryComponent implements OnInit, OnDestroy {

  factories: FactoryWithSummary[];
  listener;

  constructor(private apiService: APIService,
    public managerService: ManagerService,
    private loadingService: LoadingService,
    private managerPaymentsService: ManagerPaymentsService) { }

  ngOnDestroy() {
    if (this.listener != null) {
      this.listener.unsubscribe();
    }
  }

  async ngOnInit() {
    this.loadingService.startLoading();
    this.factories = await this.apiService.ReadFactories();
    const waitFor = [];
    for (let i = 0; i < this.factories.length; i++) {
      if (!this.factories[i].is_used) {
        this.factories.splice(i, 1);
        i--;
      } else if (this.factories[i].subfactories != null) {
        // cerco subfactories
        for (const s of this.factories[i].subfactories) {
          if (s.is_used) {
            this.factories.push(s);
          }
        }
      }
    }

    await Promise.all(this.factories.map(async (factory: FactoryWithSummary, i: number) => {
      const dayPromise = this.apiService.GetManagerSaleSummary(factory.id_factory, moment().startOf('day').toJSON(), moment().endOf('day').toJSON());
      const weekPromise = this.apiService.GetManagerSaleSummary(factory.id_factory, moment('2020-01-01').toJSON(), moment().endOf('day').toJSON()); //factory.id_factory, moment().startOf('isoWeek').toJSON(), moment().endOf('isoWeek').toJSON()
      const monthPromise = this.apiService.GetManagerSaleSummary(factory.id_factory, moment().startOf('month').toJSON(), moment().endOf('month').toJSON());
      waitFor.push(dayPromise, weekPromise, monthPromise);
      factory.summary = {
        day: await dayPromise,
        week: await weekPromise,
        month: await monthPromise
      };
    }));

    await Promise.all(waitFor);

    this.listener = this.managerPaymentsService.OnUpdateManagerPaymentListener(this.managerService.manager.id_manager).subscribe(
      next => {
        let payment = (next as any).value.data.onUpdateManagerPayment;
        if (payment.status == 4) {
          const factory = this.factories.find(function (f) {
            return f.id_factory == payment.factory.id_factory;
          });
          factory.summary.day.sale_value += payment.amount;
          factory.summary.week.sale_value += payment.amount;
          factory.summary.month.sale_value += payment.amount;

          const factoryTot = this.factories.find(function (f) { return f.id_factory == -1; });

          factoryTot.summary.day.sale_value += payment.amount;
          factoryTot.summary.week.sale_value += payment.amount;
          factoryTot.summary.month.sale_value += payment.amount;

          this.orderFactory();
        }
      });

    let daySum = 0;
    let weekSum = 0;
    let monthSum = 0;
    for (let i = 0; i < this.factories.length; i++) {
      if (this.factories[i].summary != null) {
        daySum += this.factories[i].summary.day.sale_value;
        weekSum += this.factories[i].summary.week.sale_value;
        monthSum += this.factories[i].summary.month.sale_value;
      }
    }

    const totals: FactoryWithSummary = {
      name: 'totali',
      __typename: 'Factory',
      id_factory: -1,
      summary: {
        day: {
          __typename: 'QuerySummaryResult',
          sale_value: daySum,
          start_date: moment().startOf('day').toJSON(),
          end_date: moment().endOf('day').toJSON()
        },
        month: {
          __typename: 'QuerySummaryResult',
          sale_value: monthSum,
          start_date: moment().startOf('month').toJSON(),
          end_date: moment().endOf('month').toJSON()
        },
        week: {
          __typename: 'QuerySummaryResult',
          sale_value: weekSum,
          start_date: moment().startOf('isoWeek').toJSON(),
          end_date: moment().endOf('isoWeek').toJSON()
        }
      },
      url_logo_brand: 'https://s3-eu-west-1.amazonaws.com/public.mcf88.cloud/images/sakurtra/sakurtra_brand.svg'
    }
    this.factories.unshift(totals);
    this.orderFactory();
    this.loadingService.stopLoading();
  }

  orderFactory() {
    this.factories.sort((a: FactoryWithSummary, b: FactoryWithSummary) => {
      return (a.id_factory != -1) ? (b.summary.month.sale_value - a.summary.month.sale_value) : Number.MAX_SAFE_INTEGER; //Number.MAX_SAFE_INTEGER
    });
  }

}
