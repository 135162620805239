import { Injectable } from '@angular/core';
import { APIService, ReadManagerQuery as Manager } from '../API.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  manager: Manager = null;

  constructor(
    private authService: AuthService,
    private apiService: APIService) {

    if (this.authService.hasGroup('gestori')) {
      this.readManager();
    }
  }

  async readManager() {
    if (this.manager == null) {
      this.manager = await this.apiService.ReadManager();
    }
  }

}
