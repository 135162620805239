import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComunicazioniService {

  public inizio = new Date();
  constructor() { }

  iniziaCronometro() {
    this.inizio = new Date();
  }

  getTempoPassato(): number {
    return (new Date()).getTime() - this.inizio.getTime();
  }

}
