import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogConfig, MatIconRegistry } from '@angular/material';
import { DevicesService, DeviceWithErrors } from '../services/devices.service';
import { ReadDevicesQuery as Device } from '../API.service';
import { DialogModifyDeviceComponent } from './dialog-modify-device/dialog-modify-device.component';
import { ManagerService } from '../services/manager.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-dashboard-devices',
  templateUrl: './dashboard-devices.component.html',
  styleUrls: ['./dashboard-devices.component.less']
})
export class DashboardDevicesComponent implements OnInit, OnDestroy {

  listener;

  constructor(public devicesService: DevicesService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private managerService: ManagerService,
    private translate: TranslateService) {

  }
  searchDevice: string;

  async ngOnInit() {
    this.loadingService.startLoading();
    await this.devicesService.readDevices();
    this.loadingService.stopLoading();

    this.listener = this.devicesService.OnUpdateDeviceManagerListener(this.managerService.manager.id_manager).subscribe(
      next => {
        const device = (next as any).value.data.onUpdateDeviceManager;
        if (device.last_polling_details != null && device.last_polling_details.errors_status != null) {
          device.errors = JSON.parse(device.last_polling_details.errors_status);
        }
        const index = this.devicesService.devices.findIndex((d) => d.id_device === device.id_device);
        if (index >= 0) {
          this.devicesService.devices[index] = device;
        } else {
          this.devicesService.devices.push(device);
        }
      }
    );

  }

  ngOnDestroy() {
    if (this.listener != null) {
      this.listener.unsubscribe();
    }
  }

  modifyDevice(device: Device) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = JSON.parse(JSON.stringify(device)); // for modify a copy of the device
    const dialogRef = this.dialog.open(DialogModifyDeviceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data !== undefined && data !== null) {
        // update device data
        var d = JSON.parse(JSON.stringify(device));
        for (const key of Object.keys(device)) {
          if (data.hasOwnProperty(key)) {
            device[key] = data[key];
          }
        }
      }
    });
  }

  getComMachineErrorDescription(device: DeviceWithErrors): string {
    if (device.errors != null) {
      let bodyError = '';
      if (device.errors.error_inhibition) {
        bodyError += '· ' + this.translate.instant('ERROR_INHIBITION');
      }
      if (device.errors.error_machine_communication) {
        bodyError += ' \n  ' + '· ' + this.translate.instant('ERROR_MACHINE_COMMUNICATION');
      }
      return bodyError;
    } return '';
  }

  getChangeGiverMachineErrorDescription(device: DeviceWithErrors): string {
    if (device.errors != null) {
      let bodyError = '';
      if (device.errors.coin_box_error_acceptor) {
        bodyError += '· ' + this.translate.instant('COIN_BOX_ERROR_ACCEPTOR');
      }
      if (device.errors.coin_box_error_box) {
        bodyError += ' \n  ' + '· ' + this.translate.instant('COIN_BOX_ERROR_BOX');
      }
      if (device.errors.coin_box_error_discriminator) {
        bodyError += ' \n  ' + '· ' + this.translate.instant('COIN_BOX_ERROR_DISCRIMINATOR');
      }
      if (device.errors.coin_box_error_separator) {
        bodyError += ' \n  ' + '· ' + this.translate.instant('COIN_BOX_ERROR_SEPARATOR');
      }
      if (device.errors.coin_box_error_supply) {
        bodyError += ' \n  ' + '· ' + this.translate.instant('COIN_BOX_ERROR_SUPPLY');
      }
      if (device.errors.coin_box_generic_error) {
        bodyError += ' \n  ' + '· ' + this.translate.instant('COIN_BOX_GENERIC_ERROR');
      }
      return bodyError;
    } return '';
  }
}

@Pipe({
  name: 'deviceFilter',
  pure: false
})
export class DeviceFilterPipe implements PipeTransform {
  transform(items: Device[], filter: string): Device[] {
    if (!items || !filter) {
      return items;
    }
    const trovati = [];
    for (let i = 0; i < items.length; i++) {
      let trovato = false;
      if (items[i].alias != null) {
        trovato = items[i].alias.toUpperCase().includes(filter.toUpperCase());
      }
      if (items[i].serial_number != null) {
        trovato = trovato || items[i].serial_number.toUpperCase().includes(filter.toUpperCase());
      }
      if (items[i].address != null) {
        trovato = trovato || items[i].address.toUpperCase().includes(filter.toUpperCase());
      }
      if (items[i].qrcode != null) {
        trovato = trovato || items[i].qrcode.identifier.toUpperCase().includes(filter.toUpperCase());
      }
      if (trovato) {
        trovati.push(items[i]);
      }
    }
    return trovati;
  }
}
