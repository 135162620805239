import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  public loading = false;
  public hidden = true;

  public startLoading(){
    this.loading = true;
    this.hidden = false;
  }

  public stopLoading(){
    setTimeout(()=>{                        
          this.loading = false;
    }, 1000)
    this.hidden = true;

  }

}
