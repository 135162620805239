import { Component, OnInit } from '@angular/core';
import { APIService } from '../API.service';
import { AuthService } from '../services/auth.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogConfirm } from '../dialog-confirm/DialogConfirm';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../services/loading.service';
import { API, Auth } from 'aws-amplify';

@Component({
  selector: 'app-manager-activation',
  templateUrl: './manager-activation.component.html',
  styleUrls: ['./manager-activation.component.less']
})
export class ManagerActivationComponent implements OnInit {

  token: String;

  constructor(public authService: AuthService,
    private apiService: APIService,
    private loadingService: LoadingService,
    private confirmDialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,) { }

  async ngOnInit() {
    const userInfo = await Auth.currentUserInfo();
  }

  async activateManager() {
    if (this.token != undefined && this.token.length > 0) {
      this.loadingService.startLoading();
      const result = await this.apiService.ActivateManager(this.token as string);
      this.loadingService.stopLoading();
      if (result) {
        const confirm = new DialogConfirm(this.confirmDialog, 'activation_ok_title', 'activation_ok');
        confirm.confirm(async () => {
          this.authService.logout();
        });
      } else {
        this.snackBar.open(this.translate.instant('activation_error'), null, {
          duration: 5000
        });
      }
    }
  }

  async deleteUser() {
    //await API.del('restApi', '/user', {});
  }

}
