
import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import html2canvas from 'html2canvas';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { QRCode } from "./QRCode";
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-manager-qrcode',
  templateUrl: './manager-qrcode.component.html',
  styleUrls: ['./manager-qrcode.component.less']
})
export class ManagerQrcodeComponent implements OnInit {

  manager: any;

  constructor(public authService: AuthService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: LoadingService) { }

  async ngOnInit() {

    var m = null;
    this.route.queryParams.subscribe(params => {
      m = params.m;
    });

    var cognito_sub, type;
    if (this.authService.isLoggedIn()) {
      cognito_sub = this.authService.user.attributes.sub;
      type = "sub";
    } else if (m != null) {
      cognito_sub = m;
      type = "sak";
    } else {
      window.location.href = "https://sakurtra.info";
    }

    this.loadingService.startLoading();
    this.manager = await API.get('restApi', '/manager-qrcode', {
      queryStringParameters: {
        manager_sub: cognito_sub,
        type: type
      }
    });
    await this.generateQrcode(this.manager.qrcode);
    this.loadingService.stopLoading();
  }

  async generateQrcode(url: string) {
    const canvas = document.getElementById("manager-qr");
    const qrcode = new QRCode(canvas);
    qrcode.drawQrcode(url);
  }

  exportImage() {
    if (this.authService.isLoggedIn()) {
      const name = "SAKURTRA-" + this.manager.business_name.toUpperCase().replace(/ /g, "-") + ".png";
      const managerQrcode = document.getElementById("managerQrcode");
      html2canvas(managerQrcode, {}).then(function (canvas) {
        const anchorTag = document.createElement("a");
        anchorTag.download = name;
        anchorTag.href = canvas.toDataURL();
        anchorTag.target = '_blank';
        anchorTag.click();
      });
    }
  }

  copyPath() {
    if (this.authService.isLoggedIn()) {

      const snackBar = this.snackBar;
      const translate = this.translate;

      const url = window.location.href + "?m=" + this.manager.page_id;
      const copy = function (e) {
        e.clipboardData.setData('text/plain', url);
        e.preventDefault();
        if (document.removeEventListener) {
          document.removeEventListener('copy', copy);
        }
        snackBar.open(translate.instant('data_copied'), null, {
          duration: 2000
        });
      };
      document.addEventListener('copy', copy);
      document.execCommand('copy');
    }
  }

}