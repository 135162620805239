import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { APIService } from 'src/app/API.service';
import { DeviceWithErrors } from 'src/app/services/devices.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ErrorsDetailsComponent } from './errors-details/errors-details.component';

@Component({
  selector: 'app-current-errors',
  templateUrl: './current-errors.component.html',
  styleUrls: ['./current-errors.component.less']
})
export class CurrentErrorsComponent implements OnInit {

  @Input()
  device: DeviceWithErrors;

  constructor(
    private translate: TranslateService,
    private icons: MatIconRegistry,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    public apiService: APIService,
    private loadingService: LoadingService) { 
      this.icons.addSvgIcon('monetization_off', this.domSanitizer.bypassSecurityTrustResourceUrl('https://s3-eu-west-1.amazonaws.com/public.mcf88.cloud/images/custom-material-icons/monetization_off.svg'));
    this.icons.addSvgIcon('coin_box', this.domSanitizer.bypassSecurityTrustResourceUrl('https://s3-eu-west-1.amazonaws.com/public.mcf88.cloud/images/custom-material-icons/coin_box.svg'));
    this.icons.addSvgIcon('coin_box_error', this.domSanitizer.bypassSecurityTrustResourceUrl('https://s3-eu-west-1.amazonaws.com/public.mcf88.cloud/images/custom-material-icons/coin_box_error.svg'));
    }

  async ngOnInit() { }

  async getErrors(){
    this.loadingService.startLoading();
    const errors = await this.apiService.ReadActiveErrors(this.device.id_device);
    this.loadingService.stopLoading();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.data = { errors, device: this.device }
    const dialogRef = this.dialog.open(ErrorsDetailsComponent, dialogConfig);
  }

  getComMachineErrorDescription(device: DeviceWithErrors): string {
    if (device.errors != null) {
      let bodyError = '';
      if(device.errors.error_inhibition){
        bodyError += '· '+this.translate.instant('ERROR_INHIBITION');
      }
      if(device.errors.error_machine_communication){
        bodyError += ' \n  ' + '· '+ this.translate.instant('ERROR_MACHINE_COMMUNICATION');
      }
      return bodyError;
    } return '';
  }

  getChangeGiverMachineErrorDescription(device: DeviceWithErrors): string {
    if (device.errors != null) {
      let bodyError = '';
      if(device.errors.coin_box_error_acceptor){
        bodyError +=  '· '+this.translate.instant('COIN_BOX_ERROR_ACCEPTOR');
      }
      if(device.errors.coin_box_error_box){
        bodyError += ' \n  ' +  '· '+this.translate.instant('COIN_BOX_ERROR_BOX');
      }
      if(device.errors.coin_box_error_discriminator){
        bodyError += ' \n  ' +  '· '+this.translate.instant('COIN_BOX_ERROR_DISCRIMINATOR');
      }
      if(device.errors.coin_box_error_separator){
        bodyError += ' \n  ' +  '· '+this.translate.instant('COIN_BOX_ERROR_SEPARATOR');
      }
      if(device.errors.coin_box_error_supply){
        bodyError += ' \n  ' +  '· '+this.translate.instant('COIN_BOX_ERROR_SUPPLY');
      }
      if(device.errors.coin_box_generic_error){
        bodyError += ' \n  ' +  '· '+this.translate.instant('COIN_BOX_GENERIC_ERROR');
      }
      return bodyError;
    } return '';
  }

}
