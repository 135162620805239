import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

export class Language {
  id_language: number;
  iso_code: string;
  description: string;
  s3_file_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class SupportedLanguagesService {

  languages: Language[];
  public languageLoading: Promise<void>;

  constructor() { }

  async readSupportedLanguages() {
    const result = await API.get('restApi', '/supported-languages', {
      headers: { 'Content-Type': 'text/plain' }
    });
    this.languages = result;
  }

  getLanguage(iso_code) {
    if (this.languages !== undefined && this.languages.length > 0) {
      return this.languages.find(language => {
        return language.iso_code === iso_code;
      });
    } else {
      return { description: 'N/D' };
    }
  }

}
