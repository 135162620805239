import { Injectable } from '@angular/core';
import Amplify, { Auth, Hub, API } from 'aws-amplify';
//import awsconfig from '../../aws-exports';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

@Injectable({
  providedIn: 'root'
})
export class IotService {

  idSmartphone: string;

  public getFirebaseToken(): string {
    return this.idSmartphone;
  }

  private statoIoTProvider: Promise<boolean>;

  public identityId: string;

  constructor() {
  }

  private async inizializzaIoTProviderInterno(): Promise<boolean> {
    let inizializzato = false;
    try {
      const credentials = await Auth.currentCredentials();
      inizializzato = credentials.authenticated;
      if (inizializzato) {
        /* Amplify.configure({
           API: {
             endpoints: [
               {
                 name: "restApi",
                 endpoint: "https://ridj9envsa.execute-api.eu-west-1.amazonaws.com/dev",
                 custom_header: async () => {
                   return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                 }
               }
             ]
           }
         });*/
        const respAct = await API.post('restApi', '/activation', {
          queryStringParameters: {
            firebase_token: 'WEB_TEST_SMARTPHONE'
          },
          headers: { 'Content-Type': 'text/plain' }
        });
        this.idSmartphone = respAct.id_smartphone;
        // console.log(credentials.identityId);
        this.identityId = credentials.identityId;
        Amplify.addPluggable(new AWSIoTProvider({
          aws_pubsub_region: 'eu-west-1',
          aws_pubsub_endpoint: `wss://a1kuyoz9z9t14o-ats.iot.eu-west-1.amazonaws.com/mqtt`,
          clientId: this.identityId
        }));
      }
    } catch (t) {
      console.error(t);
    }
    return inizializzato;
  }

  public async inizializzaIoTProvider(): Promise<boolean> {
    if (this.statoIoTProvider == null) {
      this.statoIoTProvider = this.inizializzaIoTProviderInterno();
    }
    return this.statoIoTProvider;
  }

}
