import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import Amplify, { API, Auth } from 'aws-amplify';
import { ComunicazioniService } from '../comunicazioni.service';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { IotService } from '../services/iot.service';
import { APIService } from '../API.service_custom';

class Marte {
  public nome?: string;
  public id: number;
  public serial_number: string;
}

@Component({
  selector: 'app-enginko-app',
  templateUrl: './enginko-app.component.html',
  styleUrls: ['./enginko-app.component.less']
})
export class EnginkoAppComponent implements OnInit {

  // private static idSmartphone = 'WEB_TEST_SMARTPHONE'; // firebase_token

  public passaggio1 = 1;
  public passaggio2 = 2;
  public passaggio3 = 3;
  public attesa = 4;
  public passaggio4 = 5;
  public inizioCreazionePagamento = 6;
  public inizioVerificaPagamento = 7;
  public prodottoConfermato = 8;
  public erogazioneFallita = 9;
  public pagamentoKo = 10;

  public passaggioAttuale = this.passaggio1;

  public credito = 5.00;

  public sessionId = '';
  public urlPagamento = '';

  public statoConnessione = false;

  public idMetodoPagamento = 4;
  public metodiPagamento = [
    { nome: 'Satispay', id: 1, abilitato: false },
    { nome: 'Axepta', id: 15, abilitato: false },
    { nome: 'Sumup', id: 4, abilitato: false },
    { nome: 'Axerve', id: 14, abilitato: false },
    { nome: 'Test collaudo', id: 16, abilitato: true },
  ];

  public cartaCaricata = false;
  public axerveCaricata = false;

  public martePersonalizzato: Marte = {
    nome: 'personalizzato', id: 25, serial_number: '000000004838'
  };
  public dispositivi: Marte[] = [
    { nome: 'Marte 2327', id: 1, serial_number: '101_000000002327' },
    { nome: 'Simulatore web', id: 5, serial_number: '1_TESTENGINKOWEB' },
    { nome: 'Simulatore Stefano', id: 10, serial_number: '101_000000004264' },
    { nome: 'Altro: ', id: -1, serial_number: null }
  ];
  public marteSelezionato = this.dispositivi[1];

  public conSelezione = false;
  public selezione = 11;

  public id_credit_card = null;
  public id_axerve_card = null;

  //private sub = 'eu-west-1:d99900e3-40a4-47e9-81fb-44ced85df4b7';

  constructor(private comunicazioniService: ComunicazioniService,
    private iotService: IotService,
    private apiAppSync: APIService) { }

  public getFirebaseToken(): string {
    return this.iotService.getFirebaseToken();
  }

  async ngOnInit() {
    API.get('restApi', '/credit-cards', {
      queryStringParameters: {
        id_device: 5,
        id_factory: 4
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(async response => {
        if (response != null && response.length > 0) {
          this.id_credit_card = response[0].id_credit_card;
          if (response.length > 1) {
            if (response[1].id_credit_card != 4) {
              this.id_credit_card = response[1].id_credit_card;
            }
          }
          this.cartaCaricata = true;
          this.metodiPagamento[2].abilitato = true;
        }
        /*const respDel = await API.del('restApi', '/credit-cards', {
          queryStringParameters: {
            id_credit_card: 4
          }
        });
        console.log(respDel);*/
      })
      .catch(error => {
        console.error(error);
      });
    API.get('restApi', '/credit-cards', {
      queryStringParameters: {
        id_device: 5,
        id_factory: 14
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        if (response.length > 0) {
          this.id_axerve_card = response[0].id_credit_card;
          this.axerveCaricata = true;
          this.cartaCaricata = true;
          this.metodiPagamento[3].abilitato = true;
        }
      });
    this.statoConnessione = await this.iotService.inizializzaIoTProvider();
    if (this.statoConnessione) {
      //console.log(this.iotService.identityId);
      /*Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/payment_details').subscribe({
        next: data => {
          console.log(data);
          if (this.idMetodoPagamento == 1) {
            // console.log(data.value.payment_id);
            //this.urlPagamento = 'https://staging.online.satispay.com/pay/' + data.value.payment_id;
            window.open('https://staging.online.satispay.com/pay/' + data.value.payment_id, '_blank');
            this.passaggioAttuale = this.passaggio3;
            // mostrare pagina https://staging.online.satispay.com/pay/
          } else {
            console.log(data);
          }
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/start_create_payment').subscribe({
        next: data => {
          this.passaggioAttuale = this.inizioCreazionePagamento;
          console.time('creazione pagamento');
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/start_verify_payment').subscribe({
        next: data => {
          console.log('start verify payment arrivato');
          this.passaggioAttuale = this.inizioVerificaPagamento;
          console.timeEnd('creazione pagamento');
          console.time('verifica pagamento');
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/payment_ok').subscribe({
        next: data => {
          console.log('dovrei passare a passaggio 4, ricevuto payment ok');
          this.passaggioAttuale = this.passaggio4;
          console.timeEnd('verifica pagamento');
          setTimeout(() => {
            this.passaggioAttuale = this.passaggio1;
            this.sessionId = '';
          }, 30000);
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/payment_ko').subscribe({
        next: data => {
          console.log('pagamento fallito');
          this.passaggioAttuale = this.pagamentoKo;
          console.timeEnd('verifica pagamento');
          this.passaggioAttuale = this.passaggio1;
          this.sessionId = '';
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/selling_ok').subscribe({
        next: data => {
          this.passaggioAttuale = this.prodottoConfermato;
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/selling_ko').subscribe({
        next: data => {
          this.passaggioAttuale = this.erogazioneFallita;
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });
      Amplify.PubSub.subscribe('enginko_payments/smartphone/' + this.iotService.identityId + '/' + this.getFirebaseToken() + '/end_session').subscribe({
        next: data => {
          console.log('Sessione terminata');
          this.passaggioAttuale = this.passaggio1;
          this.sessionId = '';
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      });*/
    }

    /*API.get('restApi', '/how-to', {})
      .then(response => {
        console.log(response);
      });*/

    API.get('restApi', '/virtual-credit-details', {
      queryStringParameters: {
        id_manager: 1
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(async response => console.log)
      .catch(error => console.error);


    this.apiAppSync.OnUpdateUserPaymentListener('000007').subscribe({
      next: data => {
        switch ((data.value.data as any).onUpdateUserPayment.status) {
          case 1:
            console.log('creato');
            this.passaggioAttuale = this.inizioCreazionePagamento;
            break;
          case 3:
            console.log('pagamento confermato');
            this.passaggioAttuale = this.passaggio4;
            break;
          case 4:
            console.log('vendita confermata');
            this.passaggioAttuale = this.prodottoConfermato;
            break;
          case 5:
            console.log('vendita fallita da stornare');
            this.passaggioAttuale = this.erogazioneFallita;
            break;
          case 6:
            console.log('vendita fallita stornata');
            break;
          case 8:
            this.passaggioAttuale = this.pagamentoKo;
            this.passaggioAttuale = this.passaggio1;
            break;
        }
      },
      error: error => console.error
    });


    this.apiAppSync.OnNewSmartphoneMessageListener(this.iotService.identityId, 4).subscribe({
      next: data => {
        switch ((data.value.data as any).onNewSmartphoneMessage.functionName) {
          case 'end_session':
            this.passaggioAttuale = this.passaggio1;
            this.sessionId = '';
            break;
        }
      },
      error: error => console.error(error)
    });

    //this.ricaricaSakurtrapay();

  }

  async inviaAMarte() {
    /*Amplify.configure({
      API: {
        endpoints: [
          {
            name: "restApi",
            endpoint: "https://ridj9envsa.execute-api.eu-west-1.amazonaws.com/dev",
            custom_header: async () => { 
              return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            }
          }
        ]
      }
    });*/
    /*try {
      const respAct = await API.post('restApi', '/activation', {headers: { 'Content-Type': 'text/plain' }});
      console.log(respAct);
    } catch (e) {
      console.error(e);
    }*/
    this.comunicazioniService.iniziaCronometro();
    this.passaggioAttuale = this.attesa;
    const corpoCredit = {
      credit: this.credito * 100,
      //id_factory: this.idMetodoPagamento,
      id_factory: 11,
      firebase_token: 'WEB_TEST_SMARTPHONE',
      id_device: this.marteSelezionato.id != -1 ? this.marteSelezionato.id : this.martePersonalizzato.id,
      //id_credit_card: this.idMetodoPagamento == 4 ? this.id_credit_card : this.id_axerve_card, //4,  // 4 è per dev, 6 per stefano
      id_credit_card: 75,
      selection: null
    };
    if (this.conSelezione) {
      corpoCredit.selection = this.selezione;
    }
    const response = await API.post('restApi', '/credit', {
      queryStringParameters: corpoCredit,
      headers: { 'Content-Type': 'text/plain' }
    });
    this.sessionId = response.id_session;
    this.passaggioAttuale = this.passaggio2;
  }

  async startStressTest() {
    this.marteSelezionato.id = 10;
    await this.inviaAMarte();
    this.marteSelezionato.id = 41;
    await this.inviaAMarte();
  }

  SignIn() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }

  async SignOut() {
    await Auth.signOut();
  }

  async GetUserDetails() {
    const user = await Auth.currentAuthenticatedUser();
    console.log(user);
  }
  //https://pay.sakurpay.com/?id=000000000000000
  async scanQRCode() {
    API.get('restApi', '/qrcode', {
      queryStringParameters: {
        identifier: '000000000000000'
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async requestPolling() {
    API.get('restApi', '/request-polling', {
      //'101_000000004838'
      queryStringParameters: {
        serial_number: this.marteSelezionato.id != -1 ? this.marteSelezionato.serial_number : this.martePersonalizzato.serial_number
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async requestLastPaymentDetail() {
    if (this.sessionId != '') {
      API.get('restApi', '/last-payment-details', {
        queryStringParameters: {
          id_session: this.sessionId
        }
      }).then(response => {
        console.log(response);
      })
        .catch(error => {
          console.error(error);
        });
    }
  }

  async endSession() {
    if (this.sessionId != '') {
      API.post('restApi', '/app-end-session', {
        queryStringParameters: {
          id_session: this.sessionId
        },
        headers: { 'Content-Type': 'text/plain' }
      }).then(response => {
        console.log(response);
        this.passaggioAttuale = this.passaggio1;
        this.sessionId = '';
      })
        .catch(error => {
          console.error(error);
        });
    }
  }

  async ricaricaSakurtrapay() {
    API.put('restApi', '/recharge-virtual-credit', {
      queryStringParameters: {
        amount: 100,
        id_factory: 11,
        id_manager: 1,
        id_smartphone: 4,
        idempotenza: new Date().getTime() + '',
        id_credit_card: 75
      },
      headers: { 'Content-Type': 'text/plain' }
    }).then(response => console.log)
      .catch(error => console.error);
  }

  /*public async testCensus(){
    API.post('restApi', '/census', {
      queryStringParameters: {
        token: 'tokenteststefano',
        id_dev_type: 1,
        serial_number: '001122334456'
      },
      headers: { 'Content-Type': 'text/plain' }
    }).then(response => {
      console.log(response);
    })
      .catch(error => {
        console.error(error);
      });
  }*/


}
