import { Component } from "@angular/core";

@Component({
    selector: 'app-manager-details',
    template: `<h3>{{'LOGO_POLICY' | translate}}</h3>
    <ul>
        <li>{{'LOGO_POLICY_MAX_SIZE' | translate}}</li>
        <li>{{'LOGO_POLICY_CONTROL' | translate}}</li>
        <li>{{'LOGO_POLICY_ONE_UPLOAD' | translate}}</li>
        <li>{{'LOGO_POLICY_SQUARE' | translate}}</li>
    </ul>
    `
})
export class LogoRulesComponent { }