import { BrowserModule } from '@angular/platform-browser';
import { NgModule, PipeTransform, Pipe, CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSnackBarModule, MatChipsModule, MatMenuModule, MatRippleModule, MatTableModule, MatPaginatorModule, MatDatepickerModule, MatNativeDateModule, MatSortModule, MatToolbarModule, MatPaginatorIntl, MatSelectModule, MatCardModule, MatExpansionModule, MatDividerModule, MatSlideToggleModule, MatRadioModule, MatProgressSpinner, MatIconModule, MatSpinner, MatProgressBar, MatTooltipModule } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppComponent } from './app.component';
import { MarteComponent } from './marte/marte.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';

import Amplify, { API } from 'aws-amplify';

import awsconfig from '../aws-exports.js';
import { EnginkoAppComponent } from './enginko-app/enginko-app.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { SimulationComponent } from './simulation/simulation.component';
import { HomeComponent } from './home/home.component';
import { DashboardDevicesComponent, DeviceFilterPipe } from './dashboard-devices/dashboard-devices.component';
import { FactoriesComponent } from './factories/factories.component';
import { TestDialogComponent } from './test-dialog/test-dialog.component';
import { DialogModifyFactoryComponent } from './factories/dialog-modify-factory/dialog-modify-factory.component';
import { FilterAttrPipe } from './pipes/filter-attr.pipe';
import { DialogModifyDeviceComponent } from './dashboard-devices/dialog-modify-device/dialog-modify-device.component';
import { ManagerPaymentsComponent } from './manager-payments/manager-payments.component';
import { MomentDateFormatPipe } from './pipes/moment-date-format.pipe';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PaginatorI18n } from './PaginatorI18n';
import { MoneyPipe } from './pipes/money.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { ClipboardModule } from 'ngx-clipboard';
import 'hammerjs';
import { AdminDashboardDeviceComponent, RicercaDispositiviPipe } from './admin-dashboard-device/admin-dashboard-device.component';
import { SimDialogComponent } from './admin-dashboard-device/sim-dialog/sim-dialog.component';
import { MatButtonLoadingDirective } from './mat-button-loading.directive';
import { MatCheckboxLoadingDirective } from './mat-checkbox-loading.directive';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { PollingComponent } from './admin-dashboard-device/polling-chart/polling.component';
import { SniffingDialogComponent } from './admin-dashboard-device/sniffing-dialog/sniffing-dialog.component';
import { ManagerActivationComponent } from './manager-activation/manager-activation.component';
import { FirmwareSelectUpdateDialogComponent } from './admin-dashboard-device/firmware-select-update-dialog/firmware-select-update-dialog.component';
import { GetFirmwareUpdateDialogComponent } from './admin-dashboard-device/get-firmware-update-dialog/get-firmware-update-dialog.component';
import { SetUpdateComponent } from './admin-dashboard-device/set-update/set-update.component';// console.log(awsconfig);
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CurrentErrorsComponent } from './dashboard-devices/current-errors/current-errors.component';
import { ErrorsDetailsComponent } from './dashboard-devices/current-errors/errors-details/errors-details.component';
import { MatToggleLoadingDirective } from './mat-toggle-loading.directive';
import { LogsComponent } from './logs/logs.component';
import { ManagersComponent } from './managers/managers.component';
import { ManagerSummaryComponent } from './manager-summary/manager-summary.component';
import { SortByPipe } from './services/sort-by.pipe';
import { ManagerDetailsComponent } from './manager-details/manager-details.component';
import { LogoRulesComponent } from './manager-details/logo-rules-modals.component';
import { NewLogoModalComponent } from './managers/new-logo-modal/new-logo-modal.component';
import { FactoryModalComponent } from './managers/factory-modal/factory-modal.component';
import { FactoriesConditionsComponent } from './privacy/factories-conditions/factories-conditions.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ManagerQrcodeComponent } from './manager-qrcode/manager-qrcode.component';
import { ExpirationDialogComponent } from './services/ExpirationDialogComponent';
import { MoneyInputComponent } from './custom-components/money-input/money-input.component';
import { NewCensusTokenModalComponent } from './managers/new-census-token-modal/new-census-token-modal.component';

(awsconfig.aws_cloud_logic_custom as any).endpointLocale = 'http://localhost:3000';
//awsconfig.oauth.domain = "auth.sakurtra.com";
awsconfig.oauth.redirectSignIn = `${window.location.origin}/login/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/login/`;

// (window as any).LOG_LEVEL = 'DEBUG';
/*Amplify.configure(awsconfig);
Amplify.addPluggable(new AWSIoTProvider({
  // aws_pubsub_endpoint: 'wss://a1kuyoz9z9t14o.iot.eu-west-1.amazonaws.com/mqtt',
  aws_pubsub_region: 'EU-WEST-1',
  aws_pubsub_endpoint: 'wss://a1kuyoz9z9t14o-ats.iot.eu-west-1.amazonaws.com/mqtt',
  clientId: 'TESTENGINKOWEB'
}));
PubSub.configure({
  clientId: 'TESTENGINKOWEB'
});
PubSub.subscribe('enginko_payments/devices/TESTENGINKOWEB/#').subscribe({
  next: (value: any) => {
    console.log(value);
  },
  error: (errorValue: any) => {
    console.error(errorValue);
  },
  complete: () => {
    console.log('complete');
  }
});*/

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

/*Amplify.configure({
  Auth: {
    identityPoolId: 'eu-west-1:2fa1447a-5792-4676-bcc3-51faec1ed1cb',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_0Iputafgk',
    userPoolWebClientId: '3d6cba5gnl2911q7177nmsriil'
  }
});*/


API.configure(awsconfig);

const oauth = {
  "domain": "enginko-payments-dev.auth.eu-west-1.amazoncognito.com-fd",
  "scope": [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": "http://localhost:4200/login/, https://pay.mcf88.cloud/login/",
  "redirectSignOut": "http://localhost:4200/",
  "responseType": "code"
};

const configUpdate = awsconfig;

//configUpdate.oauth = oauth;
Amplify.configure(configUpdate);

/*Auth.currentCredentials().then(credentials => {
  const identityId = (credentials as any).data.IdentityId;
  console.log('ottenute credenziali', identityId, credentials);
  Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'eu-west-1',
    aws_pubsub_endpoint: `wss://a1kuyoz9z9t14o-ats.iot.eu-west-1.amazonaws.com/mqtt`,
    clientId: identityId
  }));
  console.log(Amplify.PubSub._pluggables.length);
  Amplify.PubSub.subscribe('enginko_payments/manager/mcf88/devices/1_TESTENGINKOWEB/credit').subscribe({
    next: data => {
      console.log(data);
    }
  });
});*/

/*Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'eu-west-1',
  aws_pubsub_endpoint: `wss://a1kuyoz9z9t14o-ats.iot.eu-west-1.amazonaws.com/mqtt`,
  clientId: 'client-connesso'  // 'TESTENGINKOWEB-client'
  // clientId: 'MARTE_TESTENGINKOWEB'
}));*/

@NgModule({
  declarations: [
    AppComponent,
    MarteComponent,
    EnginkoAppComponent,
    SafePipe,
    LoginComponent,
    SimulationComponent,
    HomeComponent,
    DashboardDevicesComponent,
    FactoriesComponent,
    TestDialogComponent,
    DialogModifyFactoryComponent,
    FilterAttrPipe,
    DialogModifyDeviceComponent,
    ManagerPaymentsComponent,
    MomentDateFormatPipe,
    MoneyPipe,
    SortByPipe,
    MatSpinner,
    DialogConfirmComponent,
    AdminDashboardDeviceComponent,
    MatButtonLoadingDirective,
    MatCheckboxLoadingDirective,
    MatProgressSpinner,
    MatToggleLoadingDirective,
    MatProgressBar,
    SimDialogComponent,
    PollingComponent,
    DeviceFilterPipe,
    SniffingDialogComponent,
    RicercaDispositiviPipe,
    ManagerActivationComponent,
    FirmwareSelectUpdateDialogComponent,
    GetFirmwareUpdateDialogComponent,
    SetUpdateComponent,
    CurrentErrorsComponent,
    ErrorsDetailsComponent,
    LogsComponent,
    ManagersComponent,
    ManagerSummaryComponent,
    LogoRulesComponent,
    ManagerDetailsComponent,
    NewLogoModalComponent,
    FactoryModalComponent,
    FactoriesConditionsComponent,
    CookiesComponent,
    FaqsComponent,
    ManagerQrcodeComponent,
    ExpirationDialogComponent,
    MoneyInputComponent,
    NewCensusTokenModalComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatSnackBarModule,
    MatChipsModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTooltipModule,
    MatRadioModule,
    MatToolbarModule,
    ScrollingModule,
    MatGridListModule,
    MatIconModule,
    HttpClientModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatSlideToggleModule,
    MatCardModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatDividerModule,
    MatToolbarModule,
    ChartsModule,
    MatCheckboxModule,
    ClipboardModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService)
    },
    ThemeService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    TestDialogComponent,
    DialogModifyFactoryComponent,
    DialogModifyDeviceComponent,
    DialogConfirmComponent,
    SimDialogComponent,
    MatProgressBar,
    MatProgressSpinner,
    PollingComponent,
    SniffingDialogComponent,
    GetFirmwareUpdateDialogComponent,
    SetUpdateComponent,
    FirmwareSelectUpdateDialogComponent,
    ErrorsDetailsComponent,
    LogoRulesComponent,
    NewLogoModalComponent,
    FactoryModalComponent,
    ExpirationDialogComponent,
    NewCensusTokenModalComponent
  ]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  //return new CustomTranslateLoader(http);
}
