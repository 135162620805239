import { Component, OnInit } from '@angular/core';
import { FactoriesService } from '../services/factories.service';
import { ReadFactoriesQuery as Factory } from '../API.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogModifyFactoryComponent } from './dialog-modify-factory/dialog-modify-factory.component';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-factories',
  templateUrl: './factories.component.html',
  styleUrls: ['./factories.component.less']
})
export class FactoriesComponent implements OnInit {

  constructor(public factoriesService: FactoriesService,
              private loadingService: LoadingService,
              private dialog: MatDialog) { }

  async ngOnInit() {
    this.loadingService.startLoading();
    await this.factoriesService.readFactories();
    this.loadingService.stopLoading();
  }

  modifyFactory(factory: Factory) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = factory;
    this.dialog.open(DialogModifyFactoryComponent, dialogConfig);
  }

}
