import { Injectable } from '@angular/core';
import {
  APIService, ManagerPaymentFilterInput, OnUpdateManagerPaymentSubscription, PaymentSelectionDescription, ReadManagerPaymentsQuery as ManagerPayment,
  ReadManagerPaymentStatusQuery as ManagerPaymentStatus
} from '../API.service_custom';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import API, { graphqlOperation } from '@aws-amplify/api';


@Injectable({
  providedIn: 'root'
})
export class ManagerPaymentsService {

  payments: ManagerPayment[] = [];
  ranges: { name: string, range: ManagerPaymentFilterInput }[];
  payment_status: ManagerPaymentStatus[] = null;
  payent_status_filter: { id_status: number, selection: string, description: string }[];
  payment_selection_descriptions: PaymentSelectionDescription[] = null;

  constructor(private apiService: APIService) {
    this.ranges = [{
      name: "today",
      range: {
        start_date: moment().startOf('day').format("YYYY-MM-DDTHH:mm"),
        end_date: moment().endOf('day').format("YYYY-MM-DDTHH:mm")
      }
    },
    {
      name: "this_week",
      range: {
        start_date: moment().startOf('week').startOf('day').format("YYYY-MM-DDTHH:mm"),
        end_date: moment().endOf('week').endOf('day').format("YYYY-MM-DDTHH:mm")
      }
    },
    {
      name: "this_month",
      range: {
        start_date: moment().startOf('month').startOf('day').format("YYYY-MM-DDTHH:mm"),
        end_date: moment().endOf('month').endOf('day').format("YYYY-MM-DDTHH:mm")
      }
    },
    {
      name: "this_year",
      range: {
        start_date: moment().startOf('year').startOf('day').format("YYYY-MM-DDTHH:mm"),
        end_date: moment().endOf('year').endOf('day').format("YYYY-MM-DDTHH:mm")
      }
    }];
  }

  async readManagerPaymentStatus() {
    this.payment_status = await this.apiService.ReadManagerPaymentStatus();
    this.payent_status_filter = [];
    if (this.payment_status != null) {
      for (var i = 0; i < this.payment_status.length; i++) {
        for (const selection in this.payment_status[i].localized_descriptions) {
          const description = this.payment_status[i].localized_descriptions[selection].description;
          if (this.payent_status_filter.findIndex(s => s.description == description) == -1) {
            this.payent_status_filter.push({
              id_status: this.payment_status[i].id_status,
              selection: selection,
              description: description
            });
          }
        }
      }
    }
  }

  async readSelectionDescriptions() {
    this.payment_selection_descriptions = await this.apiService.ReadPaymentSelectionsDescriptions();
  }

  async readManagerPayments(filter: ManagerPaymentFilterInput) {
    if (this.payment_status == null) {
      await Promise.all([
        this.readManagerPaymentStatus(),
        this.readSelectionDescriptions()
      ]);
    }
    const offset = moment().utcOffset() / 60;
    var zone = "+00";
    if (offset >= 0) {
      zone = "+" + String(offset).padStart(2, '0');
    } else {
      zone = "-" + String(-offset).padStart(2, '0');
    }
    this.payments = await this.apiService.ReadManagerPayments({
      start_date: filter.start_date + zone,
      end_date: filter.end_date + zone
    });
  }

  getStatusObject(id_status: number) {
    return this.payment_status.find(status => {
      return status.id_status === id_status;
    });
  }

  getStatusDescription(payment: ManagerPayment) {
    const status = this.getStatusObject(payment.status);
    var selection = "default";
    if (payment.selection != null && status.localized_descriptions[payment.selection] != null) {
      selection = "" + payment.selection;
    }
    return status.localized_descriptions[selection].description;
  }

  getStatusLongDescription(payment: ManagerPayment) {
    const status = this.getStatusObject(payment.status);
    var selection = "default";
    if (payment.selection != null && status.localized_descriptions[payment.selection] != null) {
      selection = "" + payment.selection;
    }
    return status.localized_descriptions[selection].long_description;
  }

  getStatusClass(payment: ManagerPayment) {
    return this.getStatusObject(payment.status).css;
  }

  getSelectionDescription(payment: ManagerPayment) {
    var selection: any = payment.selection;
    const description = this.payment_selection_descriptions.find(selection => selection.selection == payment.selection);
    if (description != undefined) {
      selection = description.localized_description;
    }
    return selection;
  }

  OnUpdateManagerPaymentListener(id_manager: number): Observable<OnUpdateManagerPaymentSubscription> {
    return API.graphql(
      graphqlOperation(
        `subscription OnUpdateManagerPayment($id_manager: Int!) {
        onUpdateManagerPayment(id_manager: $id_manager) {
          __typename
          unique_payment_id
          factory {
            __typename
            id_factory
            name
            url_logo
            is_activated
            is_demo
            id_flow
            fields
          }
          device {
            __typename
            id_device
            id_manager
            serial_number
            id_type
            id_device_sale_type
            alias
            address
            latitude
            longitude
            timezone
            qrcode {
              __typename
              id_qrcode
              identifier
              qrcode
              id_version
            }
            factories {
              __typename
              id_factory
              name
              url_logo
              is_activated
              is_demo
              id_flow
              fields
            }
            is_remote_selection_enabled
            is_device_remote_selection
            last_polling
            id_status
            now_server
            machine_model
          }
          id_manager
          payment_id
          date
          amount
          status
          user_id
          user_view_id
          selection
        }
      }`, {
        id_manager
      }
      )
    ) as any as Observable<OnUpdateManagerPaymentSubscription>
  }

}
