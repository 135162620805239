import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  transform(money: number, currency: any): any {

    return currency.symbol + ' ' +
      (money / Math.pow(10, currency.decimal)).toFixed(currency.decimal);

  }

}
