import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  id: string;

  constructor(private activatedRoute: ActivatedRoute, public router: Router, private auth: AuthService) { }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'];
    });

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = "https://play.google.com/store/apps/details?id=mcf88.sakurtra";
    } else if ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    //|| navigator.userAgent.includes("Mac")
    ) {
      window.location.href = "https://apps.apple.com/us/app/sakurtra/id1554438581";
    }  else {
      window.location.href = "https://sakurtra.info";
      await this.auth.inizializzato;
      if (!this.auth.isLoggedIn()) {
        //this.router.navigate(['login']);
        window.location.href = "https://sakurtra.info";
      }else if(this.auth.hasGroup('amministratori')){
        this.router.navigate(['admin_devices']);
      }else if(this.auth.hasGroup('gestori')){
        this.router.navigate(['devices']);
      }
    }

  }

}
