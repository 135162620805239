import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API } from 'aws-amplify';
import { LoadingService } from '../services/loading.service';
import { SupportedLanguagesService } from '../services/supported-languages.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.less']
})
export class FaqsComponent implements OnInit {

  faqs = [];
  public FAQ_Q_PREFIX = 'FAQ_Q_N_';
  public FAQ_A_PREFIX = 'FAQ_A_N_';

  constructor(private loadingService: LoadingService,
    private translate: TranslateService,
    private supportedLanguagesService: SupportedLanguagesService) { }

  async ngOnInit() {
    this.loadingService.startLoading();
    await this.supportedLanguagesService.languageLoading;
    this.faqs = await API.get('restApi', '/frasqu', {});
    for(const f of this.faqs){
      for(const l of ['it', 'en', 'de', 'fr', 'es']){
        const langName = this.FAQ_Q_PREFIX + f.id_how_to;
        const lang = {};
        lang[langName] = f.question[l]
        this.translate.setTranslation(l, lang, true);
        let resp = '';
        for(const r of f.answer){
          resp += r.text[l] + '\n';
        }
        const respLan = {};
        respLan[this.FAQ_A_PREFIX + f.id_how_to] = resp;
        this.translate.setTranslation(l, respLan, true);
      }
    }
    this.loadingService.stopLoading();
  }

}
