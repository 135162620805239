import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { API } from 'aws-amplify';
import { APIService, ReadDevicesQuery as Device, ReadSimStatusQuery as SIMInfo, ReadSniffingFilesQuery } from '../../API.service';
import { SniffingService } from '../sniffing.service';
const moment = require('moment');

@Component({
  selector: 'app-sniffing-dialog',
  templateUrl: './sniffing-dialog.component.html',
  styleUrls: ['./sniffing-dialog.component.less'],
  providers: [DatePipe]
})
export class SniffingDialogComponent implements OnInit {

  startSniffingLoading: boolean = false;
  stopSniffingLoading: boolean = false;
  files: Array<ReadSniffingFilesQuery>;

  loadingFiles = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {device: Device},
    public sniffingService: SniffingService,
    public apiService: APIService,
    private datePipe: DatePipe) { }

  async ngOnInit() {
    this.loadingFiles = true;
    this.files = await this.apiService.ReadSniffingFiles(this.data.device.id_device,  new moment().startOf('day').subtract(1, 'week').toJSON(), new moment().endOf('day').toJSON());
    this.loadingFiles = false;
  }

  startSniffing(){
    this.startSniffingLoading = true;
    API.post('restApi', '/start-sniffing', {
      queryStringParameters: {
        id_device: this.data.device.id_device
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        this.sniffingService.text = '';
        this.startSniffingLoading = false;
      })
      .catch(error => {
        console.error(error);
        this.startSniffingLoading = false;
      });    
  }

  stopSniffing(){
    this.stopSniffingLoading = true;
    API.put('restApi', '/stop-sniffing', {
      queryStringParameters: {
        id_device: this.data.device.id_device
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        this.stopSniffingLoading = false;
      })
      .catch(error => {
        console.error(error);
        this.stopSniffingLoading = false;
      });    
  }

  getNomeFile(url: string){
    const urlA = url.split('/');
    return urlA[urlA.length-1].split('?')[0];
  }

  getDataFine(file: ReadSniffingFilesQuery): String{
    if(file.end_date != null){
      const inizio = this.datePipe.transform(file.creation_date, 'dd/MM/yyyy');
      const fine = this.datePipe.transform(file.end_date, 'dd/MM/yyyy');
      if(inizio == fine){
        return ' - ' + this.datePipe.transform(file.end_date, 'HH:mm');
      }
      return ' - ' + this.datePipe.transform(file.end_date, 'dd/MM/yy HH:mm');
    }
    return '';
  }

}
