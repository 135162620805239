import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAttr'
})
export class FilterAttrPipe implements PipeTransform {

  transform(values: any, filter: any[]): any {
    if (!values || !filter) {
      return values;
    }
    const key = Object.keys(filter)[0];
    return values.filter(item => item[key] === filter[key] );
  }

}
