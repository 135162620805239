import { MatDialog } from '@angular/material';
import { DialogConfirmComponent, DialogConfirmModel } from './dialog-confirm.component';

export class DialogConfirm {

    dialog: MatDialog;
    title: string;
    message: string;

    constructor(dialog: MatDialog, title: string, message: string) {
        this.dialog = dialog;
        this.title = title;
        this.message = message;
    }

    confirm(callback: () => void) {
        const dialogData = new DialogConfirmModel(this.title, this.message);
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            maxWidth: '400px',
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                if (callback !== null) {
                    callback();
                }
            }
        });
    }

}
