import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-money-input',
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.less']
})
export class MoneyInputComponent implements OnInit {

  @Input() label: string;
  @Input() currency: any = { symbol: "€", decimal: 2 };
  @Input() money: any;
  @Output() moneyChange: EventEmitter<number> = new EventEmitter<number>();

  value: any;
  step: any;

  constructor() {
  }

  ngOnInit() {
    this.step = 1 / Math.pow(10, this.currency.decimal);
    this.value = (this.money / Math.pow(10, this.currency.decimal)).toFixed(this.currency.decimal);
  }

  onChange() {
    if (this.value != undefined) {
      if (typeof this.value != "number") {
        this.value = parseFloat(this.value);
      }
      this.money = Math.round(parseFloat((this.value).toFixed(this.currency.decimal)) * Math.pow(10, this.currency.decimal));
    } else {
      this.money = null;
    }
    this.moneyChange.emit(this.money);
  }

}
