import { Injectable } from '@angular/core';
import { APIService, ReadFactoriesQuery as Factory } from '../API.service';

@Injectable({
  providedIn: 'root'
})
export class FactoriesService {

  factories: Factory[];

  constructor(private apiService: APIService) { }

  async readFactories() {
    const factories = await this.apiService.ReadFactories();
    for (const factory of factories) {
      factory.fields = JSON.parse(factory.fields);

      for (const subfactory of factory.subfactories) {
        subfactory.fields = JSON.parse(subfactory.fields);

        const keys = Object.keys(subfactory.fields);
        for (var i = 0; i < keys.length; i++) {

          if ((subfactory.fields as any)[keys[i]].type == "boolean" &&
            (subfactory.fields as any)[keys[i]].value == null) {
            (subfactory.fields as any)[keys[i]].value = false;
          }
        }

      }
    }
    this.factories = factories;
  }

  async saveFactory(factory: Factory): Promise<Factory> {
    const f = JSON.parse(JSON.stringify(factory));
    delete f.__typename;
    f.fields = JSON.stringify(f.fields);

    for (var i = 0; i < f.subfactories.length; i++) {
      delete f.subfactories[i].__typename;

      for (const key of Object.keys(f.subfactories[i].fields)) {

        if ((f.subfactories[i].fields as any)[key].type == "boolean" &&
          (f.subfactories[i].fields as any)[key].value == null) {

          (f.subfactories[i].fields as any)[key].value = (f.subfactories[i] as any).is_activated;
        }
      }
      f.subfactories[i].fields = JSON.stringify(f.subfactories[i].fields);
    }
    return await this.apiService.UpdateFactory(f);
  }

  async activateFactoryForAllDevices(factory: Factory): Promise<boolean> {
    const f = JSON.parse(JSON.stringify(factory));
    delete f.__typename;
    f.fields = JSON.stringify(f.fields);
    return this.apiService.ActivateFactoryForAllDevices(f);
  }


}
