
import { MultiFormatWriter, BarcodeFormat, BitMatrix, EncodeHintType } from '@zxing/library';

export class QRCode {

    static Types = class {
        static readonly CIRCLE = 1;
        static readonly SQUARE = 2;
        static readonly CLOVE = 3;
        static readonly HALF_MOON = 4;
    }

    static Orientations = class {
        static readonly TOP = 0;
        static readonly BOTTOM = 1;
        static readonly LEFT = 3;
        static readonly RIGHT = 4;
        static readonly TOP_RIGHT = 5;
        static readonly BOTTOM_RIGHT = 6;
        static readonly TOP_LEFT = 7;
        static readonly BOTTOM_LEFT = 8;
    }

    max_width: number;
    context: any;

    constructor(canvas: any) {
        this.max_width = canvas.width;
        this.context = canvas.getContext("2d");
    }

    drawQrcode(data: string) {
        const hints = new Map();
        hints.set(EncodeHintType.CHARACTER_SET, "UTF-8");
        hints.set(EncodeHintType.MARGIN, 0);

        const encoder = new MultiFormatWriter();
        const matrix: BitMatrix = encoder.encode(data, BarcodeFormat.QR_CODE, this.max_width, this.max_width, hints);

        var qrcode_start_x = null;
        var qrcode_start_y = null;
        var qrcode_end_x = null;
        var qrcode_end_y = null;
        var indicator_dimension = 0;

        //find indicator dimension, start x and start y
        for (var y = 0; y < matrix.getHeight() && qrcode_start_y == null; y++) {
            for (var x = 0; x < matrix.getWidth(); x++) {

                if (matrix.get(x, y)) {
                    if (qrcode_start_x == null) {
                        qrcode_start_x = x;
                    }
                    if (qrcode_start_y == null) {
                        qrcode_start_y = y;
                    }
                    if (y == qrcode_start_y) {
                        indicator_dimension++;
                    }
                } else if (qrcode_start_x != null) {
                    break;
                }
            }
        }

        //find end x
        for (var x = this.max_width; x >= 0 && qrcode_end_x == null; x--) {
            if (matrix.get(x, qrcode_start_y)) {
                qrcode_end_x = x;
            }
        }

        //find end y
        for (var y = this.max_width; y >= 0 && qrcode_end_y == null; y--) {
            if (matrix.get(qrcode_start_x, y)) {
                qrcode_end_y = y;
            }
        }

        const dimension = indicator_dimension / 7;

        var x: number = qrcode_start_x;
        var y: number = qrcode_start_y;
        while (y < qrcode_end_y) {
            x = qrcode_start_x;
            while (x < qrcode_end_x) {

                if (x <= indicator_dimension + qrcode_start_x && y <= indicator_dimension + qrcode_start_y
                    || x >= qrcode_end_x - indicator_dimension && y <= indicator_dimension + qrcode_start_y
                    || x <= indicator_dimension + qrcode_start_x && y >= qrcode_end_y - indicator_dimension) {
                    matrix.unset(x, y);
                }
                if (matrix.get(x, y)) {
                    const dot = this.getDotType(matrix, x, y, dimension);
                    this.drawDot(this.context, x, y, dimension, dot);
                }
                x += dimension;
            }
            y += dimension;
        }

        //draw indicator
        this.drawIndicatorDot(this.context, qrcode_start_x, qrcode_start_y, indicator_dimension);
        this.drawIndicatorDot(this.context, qrcode_end_x - indicator_dimension, qrcode_start_y, indicator_dimension);
        this.drawIndicatorDot(this.context, qrcode_start_x, qrcode_end_y - indicator_dimension, indicator_dimension);
    }

    private drawDot(context, x, y, dim, dot) {

        context.beginPath();
        context.fillStyle = "#FFFFFF";
        switch (dot.type) {
            case QRCode.Types.CIRCLE:
                context.arc(x + dim / 2, y + dim / 2, dim / 2, 0, 2 * Math.PI);
                break;
            case QRCode.Types.SQUARE:
                context.fillRect(x, y, dim, dim);
                break;
            case QRCode.Types.CLOVE:
                if (dot.orientation == QRCode.Orientations.BOTTOM_RIGHT) {
                    this.drawQuarterCircle(context, x + dim, y + dim, dim, Math.PI, Math.PI + Math.PI / 2);
                } else if (dot.orientation == QRCode.Orientations.TOP_RIGHT) {
                    this.drawQuarterCircle(context, x + dim, y, dim, Math.PI / 2, Math.PI);
                } else if (dot.orientation == QRCode.Orientations.BOTTOM_LEFT) {
                    this.drawQuarterCircle(context, x, y + dim, dim, 3 * Math.PI / 2, 3 * Math.PI / 2 + Math.PI / 2);
                } else { //TOP RIGHT
                    this.drawQuarterCircle(context, x, y, dim, 0, Math.PI / 2);
                }
                break;
            case QRCode.Types.HALF_MOON:

                const d1 = dim / 2;
                const d2 = x + d1;
                const d3 = y + d1;
                const d4 = d1 + 1.5;
                const a1 = 0.5 * Math.PI;
                const a2 = 1.5 * Math.PI;

                if (dot.orientation == QRCode.Orientations.BOTTOM) {
                    context.fillRect(x, d3 - 0.5, dim, d4);
                    context.arc(d2, d3, d1, Math.PI, 2 * Math.PI);
                } else if (dot.orientation == QRCode.Orientations.TOP) {
                    context.fillRect(x, y, dim, d4);
                    context.arc(d2, d3, d1, 0, Math.PI);
                } else if (dot.orientation == QRCode.Orientations.LEFT) {
                    context.fillRect(x, y, d4, dim);
                    context.arc(d2, d3, d1, a2, a1);
                } else {
                    context.fillRect(d2 - 0.5, y, d4, dim);
                    context.arc(d2, d3, d1, a1, a2);
                }
                break;
        }
        context.fill();
        context.closePath();
    }

    private getDotType(input, x, y, dim) {
        /* standardizzo i puntini cornice
         *   A B C
         *   D 1 E
         *   F G H
         */

        const lastX = input.getWidth() - dim;
        const lastY = input.getHeight() - dim;

        const xB = x;
        const xD = x == 0 ? null : (x - dim);
        const xE = x == lastX ? null : (x + dim);
        const xG = x;
        const yB = y == 0 ? null : (y - dim);
        const yD = y;
        const yE = y;
        const yG = y == lastY ? null : (y + dim);
        const B = xB != null && yB != null ? input.get(xB, yB) : null;
        const D = xD != null && yD != null ? input.get(xD, yD) : null;
        const E = xE != null && yE != null ? input.get(xE, yE) : null;
        const G = xG != null && yG != null ? input.get(xG, yG) : null;

        var dot = null;
        if (x == 0) { //bordo SX centrale
            dot = this.calculateLeftDots(B, E, G);
        } else if (y == 0) {//bordo TOP centrale
            dot = this.calculateTopDots(D, E, G);
        } else if (y == lastY && x < lastX) { //bordo inferiore no RIGHT
            dot = this.calculateBottomDots(B, D, E);
        } else if (x == lastX && y < lastY) { //bordo RIGHT no inferiore
            dot = this.calculateRightDots(B, D, G);
        } else if (x == lastX && y == lastY) { //angolo BOTTOM RIGHT
            dot = this.calculateBottomRightDot(B, D);
        } else { //puntini centrali
            dot = this.calculateMiddleDots(B, D, E, G);
        }
        return dot;
    }

    private calculateMiddleDots(B, D, E, G) {
        var dot = null;
        if (B == 0 && D == 0 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.CIRCLE };
        } else if ((B == 1 && G == 1) || (D == 1 && E == 1)) {
            dot = { type: QRCode.Types.SQUARE };
        } else if (B == 1 && D == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_LEFT };
        } else if (B == 1 && E == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_RIGHT };
        } else if (D == 1 && G == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.BOTTOM_LEFT };
        } else if (E == 1 && G == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.BOTTOM_RIGHT };
        } else if (B == 1 && D == 0 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.TOP };
        } else if (B == 0 && D == 1 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.LEFT };
        } else if (B == 0 && D == 0 && E == 1 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.RIGHT };
        } else if (B == 0 && D == 0 && E == 0 && G == 1) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.BOTTOM };
        }
        return dot;
    }

    private calculateLeftDots(B, E, G) {
        var dot = null;
        if (B == 0 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.CIRCLE };
        } else if (B == 1 && G == 1) {
            dot = { type: QRCode.Types.SQUARE };
        } else if (B == 1 && E == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_RIGHT };
        } else if (E == 1 && G == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.BOTTOM_RIGHT };
        } else if (B == 1 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.TOP };
        } else if (B == 0 && E == 1 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.RIGHT };
        } else if (B == 0 && E == 0 && G == 1) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.BOTTOM };
        }
        return dot;
    }

    private calculateTopDots(D, E, G) {
        var dot = null;
        if (D == 0 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.CIRCLE };
        } else if (D == 1 && E == 1) {
            dot = { type: QRCode.Types.SQUARE };
        } else if (D == 1 && G == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.BOTTOM_LEFT };
        } else if (E == 1 && G == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.BOTTOM_RIGHT };
        } else if (D == 1 && E == 0 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.LEFT };
        } else if (D == 0 && E == 1 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.RIGHT };
        } else if (D == 0 && E == 0 && G == 1) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.BOTTOM };
        }
        return dot;
    }

    private calculateBottomDots(B, D, E) {
        var dot = null;
        if (B == 0 && D == 0 && E == 0) {
            dot = { type: QRCode.Types.CIRCLE };
        } else if (D == 1 && E == 1) {
            dot = { type: QRCode.Types.SQUARE };
        } else if (B == 1 && D == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_LEFT };
        } else if (B == 1 && E == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_RIGHT };
        } else if (B == 1 && D == 0 && E == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.TOP };
        } else if (B == 0 && D == 1 && E == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.LEFT };
        } else if (B == 0 && D == 0 && E == 1) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.RIGHT };
        }
        return dot;
    }

    private calculateRightDots(B, D, G) {
        var dot = null;
        if (B == 0 && D == 0 && G == 0) {
            dot = { type: QRCode.Types.CIRCLE };
        } else if (B == 1 && G == 1) {
            dot = { type: QRCode.Types.SQUARE };
        } else if (B == 1 && D == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_LEFT };
        } else if (D == 1 && G == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.BOTTOM_LEFT };
        } else if (B == 1 && D == 0 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.TOP };
        } else if (B == 0 && D == 1 && G == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.LEFT };
        } else if (B == 0 && D == 0 && G == 1) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.BOTTOM };
        }
        return dot;
    }

    private calculateBottomRightDot(B, D) {
        var dot = null;
        if (B == 0 && D == 0) {
            dot = { type: QRCode.Types.CIRCLE };
        } else if (B == 1 && D == 1) {
            dot = { type: QRCode.Types.CLOVE, orientation: QRCode.Orientations.TOP_LEFT };
        } else if (B == 1 && D == 0) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.TOP };
        } else if (B == 0 && D == 1) {
            dot = { type: QRCode.Types.HALF_MOON, orientation: QRCode.Orientations.LEFT };
        }
        return dot;
    }

    private drawIndicatorDot(context, x, y, dim) {
        const s = dim / 7;
        const r = 10;
        const r2 = r - (r * 3 / r);
        const r3 = r / 2;
        this.drawRoundRect(context, x, y, dim, dim, r, "#ffffff");
        this.drawRoundRect(context, x + s, y + s, dim - 2 * s, dim - 2 * s, r2, "#0b4354");
        this.drawRoundRect(context, x + 2 * s, y + 2 * s, dim - 4 * s, dim - 4 * s, r3, "#ffffff");
    }

    private drawRoundRect(context, x, y, w, h, r, color) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        context.beginPath();
        context.fillStyle = color;
        context.moveTo(x + r, y);
        context.arcTo(x + w, y, x + w, y + h, r);
        context.arcTo(x + w, y + h, x, y + h, r);
        context.arcTo(x, y + h, x, y, r);
        context.arcTo(x, y, x + w, y, r);
        context.fill();
        context.closePath();
    }

    private drawQuarterCircle(context, x, y, dim, start, end) {
        context.beginPath();
        context.moveTo(x, y);
        context.arc(x, y, dim, start, end);
        context.closePath();
        context.fill();
    }

}