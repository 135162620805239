import { Injectable } from '@angular/core';
import { OnInsertPollingPieceSubscription, APIService } from '../API.service';
import API, { graphqlOperation } from "@aws-amplify/api";
import { Observable } from "zen-observable-ts";

@Injectable({
  providedIn: 'root'
})
export class SniffingService {

  sniffing;

  public text: string = '';

  constructor(private apiService: APIService) { }

  async startSniffing(id_device: number) {
    const altro = (await API.graphql(
      graphqlOperation(
        `subscription OnInsertPollingPiece($id_device: Int) {
          onInsertPollingPiece(id_device: $id_device) {
            __typename
            id_device
            id_manager
            id_sniffing_session
            body
            arrival_date
          }
        }`, { id_device }
      )) as any as Observable<OnInsertPollingPieceSubscription>);
      this.sniffing = altro.subscribe(val => {
        const next = val as any;
        this.text = `[${next.value.data.onInsertPollingPiece.arrival_date}] ${next.value.data.onInsertPollingPiece.body}\n${this.text}`;
      });
  }

  stopSniffing() {
    this.sniffing.unsubscribe();
  }
}
