import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { APIService, ListManagersQuery } from 'src/app/API.service';
import { ManagerLoading } from '../managers.component';

@Component({
  selector: 'app-factory-modal',
  templateUrl: './factory-modal.component.html',
  styleUrls: ['./factory-modal.component.less']
})
export class FactoryModalComponent implements OnInit {

  manager: ManagerLoading;
  colonne = ['nome', 'visibile', 'attivato'];
  loadingSave = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {manager: ListManagersQuery},
    public dialogRef: MatDialogRef<FactoryModalComponent>,
    private apiService: APIService
  ) { 
    this.manager = data.manager;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close({save: false});
  }

  async save(){
    /*this.loadingSave = true;
    delete this.manager.__typename;
    delete this.manager.currency.__typename;
    for(let f of this.manager.factories){
      delete f.__typename;
    }
    delete this.manager.loading;
    const res = await this.apiService.UpdateManagerAdmin(this.manager);
    console.log(res);
    this.loadingSave = false;
    this.close();*/
    this.dialogRef.close({save: true});
  }

}
