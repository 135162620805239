import { Component, OnInit } from '@angular/core';
import { IotService } from '../services/iot.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.less']
})
export class SimulationComponent implements OnInit {

  constructor(private iotService: IotService, private loadingService: LoadingService) {
  }

  async ngOnInit() {
    this.loadingService.startLoading();
    await this.iotService.inizializzaIoTProvider();
    this.loadingService.stopLoading();
  }

}
