import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) { }
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const expectedRole = route.data.expectedRole;
    await this.auth.inizializzato;
    if (
      !this.auth.isLoggedIn() || !(expectedRole != null && expectedRole != '' && this.auth.hasGroup(expectedRole))
    ) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  
}
