import { Component, Inject, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DateAdapter, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { APIService, ReadDevicePollingQuery, ReadDevicesQuery as Device, ReadPaymentTimesQuery } from '../../API.service';
import { ManagerPaymentsService } from 'src/app/services/manager-payments.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-polling',
  templateUrl: './polling.component.html',
  styleUrls: ['./polling.component.less'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: (window.navigator.language || 'en-EN') },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class PollingComponent implements OnInit {

  device: Device;
  filter: { id_device: number, start_date: any, end_date: any } = { id_device: null, start_date: null, end_date: null };
  guiFilter: any;
  loadingPolling = false;

  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'segnale', yAxisID: 'A', },
    { data: [], label: 'ritardo', yAxisID: 'B', },
    { data: [], label: 't. retroazione', yAxisID: 'B', },
    { data: [], label: 't. pag. diretto', yAxisID: 'B', },
  ];
  public lineChartLabels: Label[] = [];//['January', 'February', 'March'];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          tooltipFormat: 'YYYY-MM-DD HH:mm:ss',
          displayFormats: {
            millisecond: 'YYYY-MM-DD HH:mm:ss.SSS',
            second: 'YYYY-MM-DD HH:mm:ss',
            minute: 'YYYY-MM-DD HH:mm',
            hour: 'YYYY-MM-DD HH',
            day: 'YYYY-MM-DD',
            week: 'YYYY-MM-DD',
            month: 'YYYY-MM',
            quarter: 'Q[/4] - YYYY',
            year: 'YYYY',
          }
        }
      }],
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
      }, {
        id: 'B',
        type: 'linear',
        position: 'right',
      }]
    }, tooltips: {
      callbacks: {
        label: function (tooltipItems, data) {
          //return(moment(new Date(tooltipItems.xLabel)).format("DD/MM/YYYY HH:mm") + ": " + tooltipItems.yLabel) + '%';
          return (data.datasets[tooltipItems.datasetIndex].label + ": " +
            (tooltipItems.datasetIndex == 0 ? tooltipItems.yLabel : (tooltipItems.yLabel as number).toFixed(1))
          ) + (tooltipItems.datasetIndex == 0 ? '%' : ' s');
        },
        afterBody: function (data, point) {
          //console.log(point.datasets[data[0].datasetIndex].data[data[0].index], data);
          var multistringText = ["operatore: " + (point.datasets[data[0].datasetIndex].data[data[0].index] as any).telephone_operator];
          // do some stuff
          //multistringText.push('another string');

          return multistringText;
        }
      },
      mode: 'x'
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  clearFilter() {
    this.guiFilter = {};
  }


  async selectRange(range) {
    this.clearFilter();
    const dates = JSON.parse(JSON.stringify(range.range));
    this.filter.start_date = dates.start_date;
    this.filter.end_date = dates.end_date;
    await this.readPollings();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: 
      { device: Device,
        pollings: Array<ReadDevicePollingQuery>,
        paymentTimes: Array<ReadPaymentTimesQuery>        
      },
    private dialog: MatDialogRef<PollingComponent>,
    public managerPaymentsService: ManagerPaymentsService,
    private apiService: APIService
  ) {
    this.device = this.data.device;
    this.filter.id_device = this.device.id_device;
    this.drawChart(this.data.pollings, this.data.paymentTimes);
  }

  ngOnInit() {
    this.clearFilter();
    this.selectRange(this.managerPaymentsService.ranges[0]);
  }

  drawChart(pollings, times: Array<ReadPaymentTimesQuery>) {
    this.lineChartData[0].data = [];
    this.lineChartData[1].data = [];
    this.lineChartData[2].data = [];
    this.lineChartData[3].data = [];
    for (let i = 0; i < pollings.length; i++) {
      //@ts-ignore  
      this.lineChartData[0].data.push(
        {
          x: new Date(pollings[i].response_date).getTime(),
          y: pollings[i].rssi,
          //@ts-ignore
          telephone_operator: pollings[i].telephone_operator
        });
      //@ts-ignore  
      this.lineChartData[1].data.push(
        {
          x: new Date(pollings[i].response_date).getTime(),
          y: pollings[i].response_delay,
          //@ts-ignore
          telephone_operator: pollings[i].telephone_operator
        });
    }
    for(let i=0; i<times.length; i++){
      const idLabel = times[i].factory_id_flow == 1 ? 2 : 3;
      //@ts-ignore  
      this.lineChartData[idLabel].data.push(
        {
          x: new Date(times[i].creation_date).getTime(),
          y: times[i].payment_total_time,
          //@ts-ignore
          factory_name: times[i].factory_name
        });
        
    }
  }

  async readPollings() {
    this.loadingPolling=true;
    const promisePolling = this.apiService.ReadDevicePolling(this.filter);
    const promisePaymentTimes = this.apiService.ReadPaymentTimes(this.filter);
    await Promise.all([promisePolling, promisePaymentTimes]);
    const res = await promisePolling;
    const times = await promisePaymentTimes;
    this.drawChart(res, times);
    this.loadingPolling=false;
  }

}
