
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatTable } from '@angular/material';
import { ManagerPaymentFilterInput, ReadManagerPaymentsQuery as ManagerPayment } from '../API.service';
import { ManagerPaymentsService } from '../services/manager-payments.service';
import { FactoriesService } from '../services/factories.service';
import { FormControl } from '@angular/forms';
import { DevicesService } from '../services/devices.service';
import { ManagerService } from '../services/manager.service';
import { LoadingService } from '../services/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manager-payments',
  templateUrl: './manager-payments.component.html',
  styleUrls: ['./manager-payments.component.less'],
  providers: []
})
export class ManagerPaymentsComponent implements OnInit, OnDestroy {

  filter: ManagerPaymentFilterInput;
  guiFilter: any;

  displayedColumns: string[] = ['factory', 'device', 'selection', 'user', 'payment_id', 'date', 'amount', 'status'];
  paymentDataSource: MatTableDataSource<ManagerPayment>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<ManagerPayment>;
  factoriesFormControl = new FormControl();

  listener;

  constructor(public managerPaymentsService: ManagerPaymentsService,
    private loadingService: LoadingService,
    public factoriesService: FactoriesService,
    public devicesService: DevicesService,
    public managerService: ManagerService,
    private readonly translate: TranslateService) { }

  async ngOnInit() {
    this.paymentDataSource = new MatTableDataSource<ManagerPayment>();

    this.guiFilter = {};
    this.selectRange(this.managerPaymentsService.ranges[0]);
    await Promise.all([
      this.factoriesService.readFactories(),
      this.devicesService.readDevices(),
      this.readManagerPayments()
    ]);
    this.listener = this.managerPaymentsService.OnUpdateManagerPaymentListener(this.managerService.manager.id_manager).subscribe(
      next => {
        const payment: ManagerPayment = (next as any).value.data.onUpdateManagerPayment;

        const oldPayment = this.managerPaymentsService.payments.find((e: ManagerPayment) => e.unique_payment_id == payment.unique_payment_id);
        if (oldPayment == null) {
          // se viene cambiato ordinamento viene seguito quello, ma di default non vengono ordinati, perciò metto il più vecchio all'inizio
          this.managerPaymentsService.payments.unshift(payment);
        } else {
          oldPayment.status = payment.status;
          oldPayment.user_view_id = payment.user_view_id;
        }
        this.paymentDataSource.data = this.managerPaymentsService.payments;
        this.table.renderRows();
      }
    );
  }

  ngOnDestroy() {
    if (this.listener != null) {
      this.listener.unsubscribe();
    }
  }

  async readManagerPayments() {
    this.loadingService.startLoading();
    await this.managerPaymentsService.readManagerPayments(this.filter);

    this.paymentDataSource = new MatTableDataSource<ManagerPayment>(this.managerPaymentsService.payments);
    this.paymentDataSource.paginator = this.paginator;
    this.paymentDataSource.sort = this.sort;

    this.paymentDataSource.filterPredicate = (data: ManagerPayment, filter: string): boolean => {

      let visible = true;
      // filter for gui filter in order: factory, device, status
      if (this.guiFilter.factory != null) {
        visible = data.factory.id_factory === this.guiFilter.factory.id_factory;
      }
      if (visible && this.guiFilter.device != null && data.device != null) {
        visible = data.device.id_device === this.guiFilter.device.id_device;
      }
      if (visible && this.guiFilter.status != null) {
        visible = data.status === this.guiFilter.status.id_status;
        if (visible && this.guiFilter.status.selection != "default") {
          visible = data.selection == this.guiFilter.status.selection;
        }
      }

      if (visible && this.guiFilter.device != null && data.device == null) {
        visible = false;
      }

      // filter for filter query
      if (visible && filter !== undefined && filter !== 'guifilter') {
        visible = JSON.stringify(data).toLowerCase().indexOf(filter.toLowerCase()) >= 0;

        if (!visible) {
          visible = this.searchInTranslation(this.managerPaymentsService.getSelectionDescription(data), filter);
          if (!visible) {
            visible = this.searchInTranslation(this.managerPaymentsService.getStatusDescription(data), filter);
          }
        }

        let amount = Number(filter);
        if (!isNaN(amount)) {
          // amount if filter is parsable to int
          amount = amount * Math.pow(10, this.managerService.manager.currency.decimal);
          visible = visible || data.amount === amount;
        }
      }
      return visible;
    };
    if (Object.keys(this.guiFilter).length > 0) {
      this.applyGuiFilter();
    }
    this.loadingService.stopLoading();
  }

  async selectRange(range) {
    this.filter = JSON.parse(JSON.stringify(range.range));
    await this.readManagerPayments();
  }

  applyGuiFilter() {
    this.applyFilter("guifilter");
    if (this.guiFilter.querySearch != undefined && this.guiFilter.querySearch.length > 0) {
      this.applyFilter(this.guiFilter.querySearch);
    }
  }

  applyFilter(filter: string) {
    if (this.paymentDataSource != null) {
      if (filter === undefined) {
        filter = 'guifilter';
      }
      this.paymentDataSource.filter = filter.trim().toLowerCase();
    }
  }

  clearFilter() {
    this.guiFilter = {};
    this.applyFilter('guifilter');
  }

  clearQuerySearch() {
    if(this.guiFilter != undefined){
      this.guiFilter.querySearch = null;
    }
    this.applyFilter('guifilter');
  }

  searchInTranslation(key: string, filter: string) {
    var visible = false;
    try {
      visible = this.translate.instant(key).toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    } catch (error) {
      //do nothing, key not found
    }
    return visible;
  }

}
