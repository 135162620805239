import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.less']
})
export class DialogConfirmComponent implements OnInit {

  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogConfirmModel) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }

}

export class DialogConfirmModel {
  constructor(public title: string, public message: string) { }
}
