import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, Sort } from '@angular/material';
import { ReadActiveErrorsQuery } from 'src/app/API.service';

@Component({
  selector: 'app-errors-details',
  templateUrl: './errors-details.component.html',
  styleUrls: ['./errors-details.component.less']
})
export class ErrorsDetailsComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: {errors: Array<ReadActiveErrorsQuery>, device}) { }

  columnsToDisplay = ['error', 'start_date'];

  ngOnInit() {
  }

  sortData(sort: Sort) {
    const data = this.data.errors.slice();
    if (!sort.active || sort.direction === '') {
      this.data.errors = data;
      return;
    }
    this.data.errors = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'start_date': return compare(a.last_start_date, b.last_start_date, isAsc);
        default: return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}