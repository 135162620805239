import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { APIService, ReadDevicesQuery as Device, ReadSimStatusQuery as SIMInfo } from '../../API.service';
import * as moment from 'moment';
import { API } from 'aws-amplify';

export class FirmwareSelectUpdateDialogComponentContract {
  id_firmware: number;
  firmware_version: string;
  devices: Device[];
  constructor(id_firmware: number, firmware_version: string, devices: Device[]) {
    this.id_firmware = id_firmware;
    this.firmware_version = firmware_version;
    this.devices = devices;
  }
}

@Component({
  selector: 'app-firmware-select-update-dialog',
  templateUrl: './firmware-select-update-dialog.component.html',
  styleUrls: ['./firmware-select-update-dialog.component.less']
})
export class FirmwareSelectUpdateDialogComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnsToDisplay = ['select', 'serial_number', 'alias', 'fw_version', 'updateDate'];
  deviceDataSource: MatTableDataSource<Device>;
  selection = new SelectionModel<Device>(true, []);
  loadingSendUpdate = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FirmwareSelectUpdateDialogComponentContract,
    public dialogRef: MatDialogRef<FirmwareSelectUpdateDialogComponent>,
    private snackBar: MatSnackBar) {
    for (let i = 0; i < this.data.devices.length; i++) {
      (this.data.devices[i] as any).updateDate = moment().format("YYYY-MM-DDTHH:mm");
    }
    this.deviceDataSource = new MatTableDataSource<Device>(this.data.devices);
  }

  ngOnInit() {
    this.deviceDataSource.paginator = this.paginator;
    this.deviceDataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.deviceDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.deviceDataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Device): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.serial_number + 1}`;
  }

  change(dev: Device) {
    console.log(dev);
  }

  async sendUpdate() {
    this.loadingSendUpdate = true;
    const bodyUpdate = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      bodyUpdate.push({
        id_device: this.selection.selected[i].id_device,
        update_datetime: (this.selection.selected[i] as any).updateDate
      })
    }

    try {
      await API.put('restApi', '/upload-firmware', {
        queryStringParameters: {
          id_firmware: this.data.id_firmware,
          version: this.data.firmware_version
        },
        headers: { 'Content-Type': 'application/json' },
        body: bodyUpdate
      });
    } catch (e) {
      console.error(e);
      this.snackBar.open(`Errore nel settaggio dell' aggiornamento`, null, {duration: 3000, panelClass: ['warning-panel']});
    }
    
    
    this.snackBar.open('Caricamento Completato!', null, {duration: 3000, panelClass: 'success-panel'});
    this.loadingSendUpdate = false;
    this.dialogRef.close(null);
  }

}
