import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogConfig, MatIconRegistry, MatSnackBar } from '@angular/material';
import { API } from 'aws-amplify';
import { APIService, ListManagersQuery, ReadAdminDevicesQuery } from '../API.service';
import { addErrorOnDevices, DevicesService, DeviceWithErrors } from '../services/devices.service';
import { PollingComponent } from './polling-chart/polling.component';
import { SimDialogComponent } from './sim-dialog/sim-dialog.component';
import * as moment from 'moment';
import { SniffingDialogComponent } from './sniffing-dialog/sniffing-dialog.component';
import { SniffingService } from './sniffing.service';
import { FirmwareSelectUpdateDialogComponent, FirmwareSelectUpdateDialogComponentContract } from './firmware-select-update-dialog/firmware-select-update-dialog.component';
import { GetFirmwareUpdateDialogComponent } from './get-firmware-update-dialog/get-firmware-update-dialog.component';
import { SetUpdateComponent, SetUpdateDataContract } from './set-update/set-update.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LoadingService } from '../services/loading.service';


@Component({
  selector: 'app-admin-dashboard-device',
  templateUrl: './admin-dashboard-device.component.html',
  styleUrls: ['../dashboard-devices/dashboard-devices.component.less', './admin-dashboard-device.component.less']
})
export class AdminDashboardDeviceComponent implements OnInit, OnDestroy {

  devices: Array<DeviceWithErrors>;
  pollingLoading = false;
  firmwareInput: HTMLElement;
  uploadFirmware = false;
  getFirmwareDeviceLoading = false;
  getFirmwaresLoading = false;
  managers: Array<ListManagersQuery>;
  listener;

  ricercaDispositivi: string = '';

  //dataSource = [{position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},{position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'}];
  displayedColumns: string[] = ['label', 'value'];

  constructor(private apiService: APIService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    public sniffingService: SniffingService,
    public icons: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar) {
    this.icons.addSvgIcon('distributore', this.domSanitizer.bypassSecurityTrustResourceUrl('https://s3-eu-west-1.amazonaws.com/public.mcf88.cloud/sakurtra/device_sale_types/snack.svg'));
  }

  async ngOnInit() {
    this.loadingService.startLoading();

    const getDevice = this.apiService.ReadAdminDevices();
    const getManagers = this.apiService.ListManagers();
    this.devices = await getDevice;
    addErrorOnDevices(this.devices);
    this.managers = await getManagers;
    this.listener = this.apiService.OnUpdateDeviceAdminListener.subscribe(next => {
      const device = ((next as any).value.data.onUpdateDeviceAdmin) as DeviceWithErrors;
      device.errors = device.last_polling_details != null ? JSON.parse(device.last_polling_details.errors_status) : null;
      const index = this.devices.findIndex((d) => d.id_device === device.id_device);
      if (index >= 0) {
        Object.keys(device).forEach(key => {
          this.devices[index][key] = device[key];
        })
      } else {
        this.devices.push(device);
      }
    });
    this.loadingService.stopLoading();
  }

  ngOnDestroy() {
    if (this.listener != null) {
      this.listener.unsubscribe();
    }
  }

  async getSIMInfo(device: ReadAdminDevicesQuery) {
    this.loadingService.startLoading();
    const SIMInfo = await this.apiService.ReadSimStatus(device.id_device);
    this.loadingService.stopLoading();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.data = { device, SIMInfo }
    const dialogRef = this.dialog.open(SimDialogComponent, dialogConfig);
  }

  async requestPolling(device: ReadAdminDevicesQuery) {
    (device as any).pollingLoading = true;
    API.get('restApi', '/request-polling', {
      queryStringParameters: {
        serial_number: device.id_type + '_' + device.serial_number
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        (device as any).pollingLoading = false;
      })
      .catch(error => {
        console.error(error);
        (device as any).pollingLoading = false;
      });
  }

  async getPolling(device: ReadAdminDevicesQuery) {
    (device as any).pollingRequestLoading = true;
    const promisePolling = this.apiService.ReadDevicePolling({ id_device: device.id_device });
    const promisePaymentTimes = this.apiService.ReadPaymentTimes({ id_device: device.id_device });
    await Promise.all([promisePolling, promisePaymentTimes]);
    const res = await promisePolling;
    const times = await promisePaymentTimes;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = window.innerWidth + 'px';
    dialogConfig.data = { device, pollings: res, paymentTimes: times };
    const dialogRef = this.dialog.open(PollingComponent, dialogConfig);
    (device as any).pollingRequestLoading = false;
  }

  async startSniffing(device) {
    /*(device as any).startSniffing = true;
    API.post('restApi', '/start-sniffing', {
      queryStringParameters: {
        id_device: device.id_device
      },
      headers: { 'Content-Type': 'text/plain' }
    })
      .then(response => {
        (device as any).startSniffing = false;
      })
      .catch(error => {
        console.error(error);
        (device as any).startSniffing = false;
      });*/
    this.sniffingService.startSniffing(device.id_device);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = window.innerWidth + 'px';
    dialogConfig.data = { device };
    const dialogRef = this.dialog.open(SniffingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.sniffingService.stopSniffing();
    });
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  onSubmit(form) {
    console.log(form);
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = res => {
        resolve(new Uint8Array((res.target as any).result));
      };
      reader.onerror = err => reject(err);

      reader.readAsArrayBuffer(file);
    });
  }

  modifiesOnFileInput(fileInput) {
    return new Promise((resolve, reject) => {

      fileInput.onchange = res => {
        resolve(res.target.files[0]);
      };
      fileInput.onerror = err => reject(err);

    });
  }

  focusOnWindow = () =>
    new Promise((resolve) => {
      window.addEventListener(
        'focus', () => resolve(true), { once: true });
    });


  async startFirmwareUpload() {

    this.firmwareInput = document.getElementById('input-firmware');
    this.firmwareInput.click();
    const fileName = await this.modifiesOnFileInput(this.firmwareInput);
    if (fileName['name'].toUpperCase().endsWith('.FW')) {
      this.uploadFirmware = true;
      const file = await this.readFile(fileName);
      const ver = (file[4] & 0xFF) + ((file[5] << 8) & 0xFF00);
      const build = file[20] & 0xFF;
      const SOFT_VER = ver + '.' + build;
      const respServer = await API.post('restApi', '/upload-firmware', {
        queryStringParameters: {
          version: SOFT_VER
        },
        headers: { 'Content-Type': 'text/plain' }
      });

      const idFirmware = respServer.id_firmware;
      await fetch(respServer.url_presigned, {
        method: 'PUT',
        body: file as any
      });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = window.innerWidth + 'px';
      dialogConfig.data = new FirmwareSelectUpdateDialogComponentContract(idFirmware, SOFT_VER, this.devices);
      const dialogRef = this.dialog.open(FirmwareSelectUpdateDialogComponent, dialogConfig);
      this.uploadFirmware = false;
    } else {
      this._snackBar.open(`Il file deve essere .FW!`, null, {
        duration: 3000,
        panelClass: ['warning-snackbar']
      });
    }
  }

  async getFirmwareDevice() {
    this.getFirmwareDeviceLoading = true;
    const firmwareDevices = await API.get('restApi', '/upload-firmware', {
      queryStringParameters: {},
      headers: { 'Content-Type': 'text/plain' }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = window.innerWidth + 'px';
    dialogConfig.data = firmwareDevices;
    const dialogRef = this.dialog.open(GetFirmwareUpdateDialogComponent, dialogConfig);
    this.getFirmwareDeviceLoading = false;
  }

  async getFirmwares() {
    this.getFirmwaresLoading = true;
    const firmwares = await API.get('restApi', '/firmware', {
      queryStringParameters: {},
      headers: { 'Content-Type': 'text/plain' }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = window.innerWidth + 'px';
    dialogConfig.data = new SetUpdateDataContract(firmwares, this.devices);
    const dialogRef = this.dialog.open(SetUpdateComponent, dialogConfig);
    this.getFirmwaresLoading = false;
  }

  getManager(id_manager: number): ListManagersQuery {
    if (this.managers != null) {
      return this.managers.find(function (value: ListManagersQuery) {
        return value.id_manager == id_manager;
      });
    }
    return {
      id_manager: id_manager,
      business_name: 'Non trovato'
    } as any;
  }

}

@Pipe({ name: 'ricercaDispositivi' })
export class RicercaDispositiviPipe implements PipeTransform {

  constructor(public dashboard: AdminDashboardDeviceComponent) { }

  transform(items: any[], filter: string): any {
    const dashboard = this.dashboard;
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(function (item: ReadAdminDevicesQuery) {
      const manager = dashboard.getManager(item.id_manager);
      return item.serial_number.toLowerCase().includes(filter.toLowerCase()) ||
        (item.alias != null ? item.alias.toLowerCase().includes(filter.toLowerCase()) : false) ||
        (manager != null ? manager.business_name.toLowerCase().includes(filter.toLowerCase()) : false)
    });
  }
}
