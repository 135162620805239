import { Component, OnDestroy, OnInit } from '@angular/core';
import Amplify, { API, Auth } from 'aws-amplify';
import * as moment from 'moment';
//import { statoIoTProvider } from '../app.component';
import { ComunicazioniService } from '../comunicazioni.service';
import { IotService } from '../services/iot.service';
import * as crypto from 'crypto-browserify';
import { APIService } from '../API.service';

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-marte',
  templateUrl: './marte.component.html',
  styleUrls: ['./marte.component.less']
})
export class MarteComponent implements OnInit, OnDestroy {
  public preCredito = 1;
  public postCredito = 2;
  public inVerifica = 3;
  public pagamentoKo = 10;

  public inFunzione = 'In Funzione';

  public now: string;
  public info = this.inFunzione;
  public messaggi = [];


  public statoAttuale = this.preCredito;

  public sessionId = '';

  prezzi = [0.10, 0.40, 1];

  public credito = 0;

  public statoConnessione = false;

  public venditaConfermata = true;

  private ultimoIdempotenza: string;

  private timerSniffing = null;

  private ENV = 'dev';

  public errori = {
    error_inhibition: false,    //MCF_INH
    coin_box_error_box: false,  //EA1A
    error_exact_change: false,
    coin_box_error_supply: false,
    coin_box_generic_error: false,  //EA
    coin_box_error_acceptor: false,
    coin_box_error_separator: false,  //EAM
    error_machine_communication: false, //MCF_COM
    coin_box_error_discriminator: false
  }

  public erroriMacchina = {
    porta_aperta: {
      valore: false,
      caricamento: false,
      id: 'EGS'
    },
    vuoto_bicchieri: {
      valore: false,
      caricamento: false,
      id: 'EBJ'
    }
  }

  loadingEndSession = false;

  public loadingErroriMacchine = {
    porta_aperta: false,
    vuoto_bicchieri: false
  }

  listeners = [];

  public getDiag() {
    let generic = (this.errori.error_machine_communication ? 0x01 : 0)
      + (this.errori.error_inhibition ? 0x02 : 0)
      + (this.errori.error_exact_change ? 0x80 : 0)
    let coinBox = (this.errori.coin_box_generic_error ? 0x01 : 0)
      + (this.errori.coin_box_error_discriminator ? 0x02 : 0)
      + (this.errori.coin_box_error_acceptor ? 0x04 : 0)
      + (this.errori.coin_box_error_separator ? 0x08 : 0)
      + (this.errori.coin_box_error_supply ? 0x10 : 0)
      + (this.errori.coin_box_error_box ? 0x20 : 0)
    return generic.toString(16).padStart(2, '0') + coinBox.toString(16).padStart(2, '0') + '00';
  }

  private privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEpQIBAAKCAQEAu6Bn+lQmABw4Q+S5qwdSkD7N55nFYITCGNZaYbb7Jnn3YMrt
nXApzOkaf3jfLH1BQdauCkfrD3QvN1H69nACmrBfAnnEZvvJ0gSYKRQNivyJxuBI
m7yBV5qVcPkR0PcWyay7+Ab3Our/GBLdw0+NmsR+IxgpKODemHf5rd3jk4WamUs8
p2CzJtueOXI4lXUuDwNxVacaSqIS/tDU9/QyTdGxufec33X8kyaDznxV4+pU2qFL
VUzrrCpdaSdTFpvZbAhFDo8YzDH537I7vdjQYYYd++dv04jDQuC5W3UW88qdNCTR
LAmeJGN9lNXIORjaple2azNJ3tTzcjfbLx5MvwIDAQABAoIBAQCP3fNck2F//CN4
i82CQt8gE8g2qkYaODHMcbBj9LanTiZNZczFR9gFc0gKLliR+IktPru8zmCDPQdn
V8kjZcfZbRus/kmG3dJm43b5KJMp23KZuCh0ohpbXgDw20q5/HzQr06CZHT2DHMF
tpGszTc7USeXKVOGMMb+CiC9HML/vcW6dpekgSnCmH5+WZdqhHossRcgrr9lmbNN
YY0Ynfv8gB8ot7oog9yYx4IVpjjQHeAPQjjME7gfimaQssbGe+DaHiGPuaWQeYVn
HOEH/hkGy4RmfeMZqvKEuUiw7/1PmBwjZdOqkAebq0D/H49+zbyLn82cZKm+wz5w
AffTxpZhAoGBAOblHJCVDoHj4dXd2KjKBCO91LdUCqQzNeD4LhJua1Ybr2e1S3A0
E1pybZqF1NY4Cb+kTFJwavXFGycobyBC0DNMnl0WFZZ/exBuP56aYa83rAXU7HVG
/bd03gD6PrfPJRGPw7qoazyfIXbGKhihzeU7QbK2Uq5vGJTXo7JIVj7lAoGBANAG
7tHA+IVRt6cbopRwrn6lKT76wT3uDRcH2qRoltX9DO96Ro8CUy6A9ritlNx/mqWm
o9UEsGGuj4mUhkbfRlgRRuuBvXsoJFQTBy8YwFcJWO0/cR5AliaaQNfpmBwveurj
Wv/5kGQcKFkJuKHtGGfpUlMyiFeadkP5eUhvyT7TAoGAC1SS4kVuQNF985C/YQkI
yvg1yAlHvGcG7dM/74ebnM4e/WfYG9lyWa3j0wFeQGTflZfqZuE9XnIdsv449cxR
izLnUooS5R+8WkIOvSm2QXLp7h9UuyyQpsAFZObizQJNP19eq3o4SPFGIx32Ut8d
y3Hs92gbBpRNEIDEd6s1EEECgYEArIezdn+HHntBx/ShiF9UOXEdnkA2GPjgHmPD
MGwV/XAcVFmjP2R8PKt09IHyXlXumsh0RG3ZI4q1PTPTkeAH5W6e1SALWKNYTepi
IOt0SjtUZ6AgXyjN30I72VbBc07lsP3TMU14Na8sv3vSh2muvi/CuYHFlnJi98lm
j283B0UCgYEAglvLhzlx1EetW99eXOtSMBDBHPjZkWrTRshC6XouUGqfPBTubnJX
KOQWDLDiZy2G6ok2agImFyf6yE9iDC8FNu+ZEQtD4281bDOKvtzCyOkfhpzMwWyS
vPOqPm8ER+aN1TYDOwO1Tzy4rXZ6Nzs11vKKcEyf4jR8nuyRtwT6rKc=
-----END RSA PRIVATE KEY-----`;




  constructor(private comunicazioniService: ComunicazioniService, private iotService: IotService, public apiService: APIService) {
    setInterval(() => {
      this.now = moment().format('DD/MM  HH:mm:ss');
    }, 1000);
    /*console.log(crypto.createSign('sha256WithRSAEncryption')
      .update('1607095894616')
      .sign(this.privateKey)
      .toString('base64'));*/
  }
  async ngOnDestroy() {
    await Promise.all(this.listeners.map(async (listener) => {
      listener.unsubscribe();
    }));
  }

  public pressioneProdotto(prodotto) {
    if (this.statoAttuale == this.preCredito) {
      setTimeout(() => {
        this.info = this.inFunzione;
      }, 1000);
      this.info = this.prezzi[prodotto].toFixed(2);
    } else if (this.statoAttuale == this.postCredito) {
      console.log('inizio creazione pagamento');
      this.info = 'Creazione pagamento';
      this.comunicazioniService.iniziaCronometro();
      /*const xhttp = new XMLHttpRequest();
      xhttp.open('POST', 'https://ridj9envsa.execute-api.eu-west-1.amazonaws.com/dev/create-payment?credit=' +
        (this.prezzi[prodotto] * 100) +
        '&serial_number=TESTENGINKOWEB');
      xhttp.onloadend = (e) => {
        console.log((e.target as any).responseText);
        this.messaggi.push('Impiegato per creazione pagamento: ' + this.comunicazioniService.getTempoPassato() + ' ms');
      };
      xhttp.send();*/
      this.credito -= this.prezzi[prodotto];
      this.ultimoIdempotenza = new Date().getTime().toString();
      API.post('restApi', '/create-payment', {
        queryStringParameters: {
          amount: this.prezzi[prodotto] * 100,
          serial_number: 'TESTENGINKOWEB',
          id_session: this.sessionId,
          id_dev_type: 1,
          idempotenza: this.ultimoIdempotenza,
          selection: prodotto
        }
      })
        .then(async response => {
          console.log(response);
          this.info = 'Attesa pagamento';
          this.messaggi.push('Impiegato per creazione pagamento: ' + this.comunicazioniService.getTempoPassato() + ' ms');
          if (response.unique_payment_id != null) {
            this.messaggi.push('Pagamento confermato');
            console.log('Ciclo breve');
            await this.confermaVendita({
              value: {
                unique_payment_id: response.unique_payment_id
              }
            })
          }
        })
        .catch(error => {
          if (error.response.status == 403) {
            this.statoAttuale = this.pagamentoKo;
          } else {
            console.error(error);
          }
        });
    }
  }

  async ngOnInit() {

    this.ENV = (await API.endpoint('restApi')).split('/')[3];

    const userInfo = await Auth.currentUserInfo();
    console.log(userInfo);

    //last-payments
    /*API.get('restApi', '/last-payments', {})
      .then(async response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      });*/

    this.statoConnessione = await this.iotService.inizializzaIoTProvider();
    if (this.statoConnessione) {

      this.listeners.push(Amplify.PubSub.subscribe(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/down/environment_change`).subscribe({
        next: data => {
          console.log(JSON.stringify(data.value));
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      }));

      this.listeners.push(Amplify.PubSub.subscribe(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/down/credit`).subscribe({
        next: data => {
          console.log(data.value, this.statoAttuale);
          if ((data.value.credit as any) as number != 0) {
            this.credito = ((data.value.credit as any) as number) / 100;
            this.sessionId = data.value.id_session;
            this.info = this.credito.toFixed(2);
            this.messaggi.push('Impiegato per risveglio con credito'
              + ((data.value.selection == 11 || data.value.selection == 12) ? ' con selezione ' + data.value.selection : '')
              + ': '
              + this.comunicazioniService.getTempoPassato() + ' ms');
            this.statoAttuale = this.postCredito;

            if (data.value.selection == 11) {
              this.pressioneProdotto(0);
            } else if (data.value.selection == 12) {
              this.pressioneProdotto(1);
            }
          } else {
            console.log('termino la sessione');
            this.statoAttuale = this.preCredito;
            this.info = this.inFunzione;
          }
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      }));
      this.listeners.push(Amplify.PubSub.subscribe(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/down/confirm_payment`).subscribe({
        next: async data => {
          console.log(data);
          if (this.statoAttuale == this.postCredito) {
            this.statoAttuale = this.inVerifica;
            this.info = 'Verifica pagamento';
            this.comunicazioniService.iniziaCronometro();
            //this.spinners.show('marteLoading');
            await API.get('restApi', '/payment-status', { queryStringParameters: { unique_payment_id: data.value.unique_payment_id } })
              .then(async response => {
                if (response.status == 'OK') {
                  this.messaggi.push('prodotto erogato');
                } else {
                  this.info = 'Pagamento rifiutato';
                }
                this.messaggi.push('Impiegato per confermare pagamento: ' + this.comunicazioniService.getTempoPassato() + ' ms');
                await this.confermaVendita(data);
                /*
                await API.post('restApi', '/confirm-sale', {
                  queryStringParameters:
                  {
                    unique_payment_id: data.value.unique_payment_id,
                    status: this.venditaConfermata ? 1 : 2
                  },
                  headers: { 'Content-Type': 'text/plain' },
                  body: firma
                });
                this.statoAttuale = this.postCredito;*/
              })
              .catch(error => {
                console.error(error);
              });
            //this.spinners.hide('marteLoading');
          }
        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      }));
      this.listeners.push(Amplify.PubSub.subscribe(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/down/polling`).subscribe({
        next: async data => {
          console.log('ricevuta richiesta polling');
          //this.spinners.show('marteLoading');
          const date = new Date();
          const secret = data.value.secret;
          /*const result = await API.post('restApi', '/polling', {
            queryStringParameters: {
              serial: '1_TESTENGINKOWEB',
              secret,
              local_timestamp: date.toJSON().split('T')[0] + ' ' + date.toLocaleTimeString(),
              keyboard_virtualization: 'false',
              fw_version: '1.0.0',
              connection_type: 2,
              rssi: 80,
              ber: 3,
              telephone_operator: 404,
              machine_model: 'mcf88 web simulator'
            },
            headers: { 'Content-Type': 'text/plain' }
          });
          console.log(result);*/
          await Amplify.PubSub.publish(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/up/polling_response`, {
            serial: '1_TESTENGINKOWEB',
            secret,
            local_timestamp: date.toJSON().split('T')[0] + ' ' + date.toLocaleTimeString(),
            keyboard_virtualization: 'false',
            fw_version: '1.0.0',
            connection_type: 2,
            rssi: 80,
            ber: 3,
            telephone_operator: 404,
            machine_model: 'mcf88 web simulator'
          })
          //this.spinners.hide('marteLoading');
        },
        error: error => console.error(error),
        close: () => console.log('Done')
      }));
      this.listeners.push(Amplify.PubSub.subscribe(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/down/start_sniffing`).subscribe({
        next: async data => {
          this.timerSniffing = setInterval(async function () {
            await Amplify.PubSub.publish(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/up/sniffing`, {
              id_sniffing_session: data.value.id_sniffing_session,
              body: new Date().getTime() + ''
            })
          }, 1000 * 10);
        }
      }));

      this.listeners.push(Amplify.PubSub.subscribe(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/down/stop_sniffing`).subscribe({
        next: async data => {
          console.log(data);
          clearInterval(this.timerSniffing);
        }
      }));
    }
  }

  public async confermaVendita(data) {
    setTimeout(() => {
      this.info = this.credito.toFixed(2);
    }, 1000);
    if (this.venditaConfermata) {
      this.info = 'Ritira prodotto';
    } else {
      this.info = 'Vendita fallita';
    }
    const firma = crypto.createSign('sha256WithRSAEncryption')
      .update(this.ultimoIdempotenza)
      .sign(this.privateKey)
      .toString('base64');
    console.log(this.ultimoIdempotenza, firma);
    await API.post('restApi', '/confirm-sale', {
      queryStringParameters:
      {
        unique_payment_id: data.value.unique_payment_id,
        status: this.venditaConfermata ? 1 : 2
      },
      headers: { 'Content-Type': 'text/plain' },
      body: firma
    });
    this.statoAttuale = this.postCredito;
  }

  public async endSession() {
    if (this.sessionId != '') {
      this.loadingEndSession = true;
      await API.post('restApi', '/end-session', {
        queryStringParameters: {
          id_session: this.sessionId
        },
        headers: { 'Content-Type': 'text/plain' }
      });
      this.statoAttuale = this.preCredito;
      this.info = this.inFunzione;
      this.loadingEndSession = false;
    }
  }

  public async postPolling() {
    const date = new Date();
    await Amplify.PubSub.publish(`enginko_payments/${this.ENV}/mcf88/1_TESTENGINKOWEB/up/polling_response`, {
      serial: '1_TESTENGINKOWEB',
      local_timestamp: date.toJSON().split('T')[0] + ' ' + date.toLocaleTimeString(),
      keyboard_virtualization: 'false',
      fw_version: '1.0.0',
      connection_type: 2,
      rssi: 80,
      ber: 3,
      telephone_operator: 404,
      machine_model: 'mcf88 web simulator',
      diag: this.getDiag()
    })
  }

  public async sendEvadtsError() {
    //var d = new Date();
    const errore = 'ID1*1_TESTENGINKOWEB*ENGINKO*WEB_SIMULATOR***\r\nID4*2\r\nEA2*EGS*1*32**0*'/*EA1*EGS*'+
      d.getFullYear() + '' + ((d.getMonth()+1)+'').padStart(2, '0') + (d.getDate()+'').padStart(2, '0') +
      '*'+(d.getHours()+'').padStart(2, '0') + (d.getMinutes()+'').padStart(2, '0')+'*\r\nEA2*EGS*1*32**1*';*/
    const respErrore = await this.apiService.SendMachineErrorEvadts({ id_device: 1, evadts_error: errore });
    console.log(respErrore);
  }

  private async inviaErrore(err: { valore: boolean, caricamento: boolean, id: string }) {
    err.caricamento = true;
    const errore = 'ID1*1_TESTENGINKOWEB*ENGINKO*WEB_SIMULATOR***\r\nID4*2\r\nEA2*' + err.id + '*1*32**' + (err.valore ? '1' : '0') + '*'
    const respErrore = await this.apiService.SendMachineErrorEvadts({ id_device: 1, evadts_error: errore });
    err.caricamento = false;
  }

  public async cambioPortaAperta() {
    await this.inviaErrore(this.erroriMacchina.porta_aperta);
  }

  public async cambioVuotoBicchieri() {
    await this.inviaErrore(this.erroriMacchina.vuoto_bicchieri);
  }
}
