import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { APIService } from 'src/app/API.service';
import { ManagerLoading } from '../managers.component';

@Component({
  selector: 'app-new-census-token-modal',
  templateUrl: './new-census-token-modal.component.html',
  styleUrls: ['./new-census-token-modal.component.less']
})
export class NewCensusTokenModalComponent implements OnInit {

  manager: ManagerLoading;
  numberOfTokens: number = 1;

  tokens: string[] = [];
  loading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: ManagerLoading,
    private apiService: APIService,
    private snackBar: MatSnackBar,
    private clipboardApi: ClipboardService) {

    this.manager = data;
  }

  ngOnInit() {
  }

  async generateNewCensusToken() {
    try {
      this.loading = true;
      let manager = JSON.parse(JSON.stringify(this.manager));
      manager.service_expiration_date = manager.service_expiration_date + ':00.000Z';
      manager.ko_payments_expiration_date = manager.ko_payments_expiration_date + ':00.000Z';
      delete manager.loading;
      this.tokens = await this.apiService.GenerateNewCensusTokensForManager(manager, this.numberOfTokens);
      //this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  copyToken(token: string) {
    this.clipboardApi.copyFromContent(token);
    this.snackBar.open(`Token ${token} copiato`, null, {
      duration: 2000
    });
  }

  copyAllTokens() {
    this.clipboardApi.copyFromContent(this.tokens.join("\r\n"));
    this.snackBar.open(`Token copiati`, null, {
      duration: 2000
    });
  }

  exportTxtFile() {
    const element = document.createElement("a");
    const file = new Blob([this.tokens.join("\r\n")], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "token.txt";
    document.body.appendChild(element);
    element.click();
  }

}
